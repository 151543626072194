import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import RouteService from 'services/RouteService';
import dayjs from 'dayjs';
import { TableDateTimeFormat } from 'constants/dateFormats';
import { actions } from './slice';
import { selectRoutesHistory } from './selectors';

function* fetchRoutesHistory() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      paquerId,
      search,
    } = yield select(selectRoutesHistory);

    const { content: routes, totalElements } = yield call(
      RouteService.getAllHistoricalRoutes,
      {
        page: pageNumber,
        size: pageSize,
        search,
        paquerId,
      },
    );
    const mappedRoutes = routes.map((route) => ({
      ...route,
      _paquerName: `${route?.drive?.name} ${route?.drive?.lastName}`,
      _distributionDate: route.date
        ? dayjs(route.date).tz().format(TableDateTimeFormat)
        : 'Sin fecha',
    }));
    const items = mappedRoutes;
    const total = totalElements;
    yield put({
      type: actions.fetchRoutesSuccess.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    yield put({
      type: actions.fetchRoutesFailure.type,
      payload: error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.fetchRoutes,
        actions.setRoutesPageable,
        actions.updatePaquer,
        actions.updateSearch,
        actions.resetFilters,
      ],
      fetchRoutesHistory,
    ),
  ]);
}
