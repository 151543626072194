import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import RouteService from 'services/RouteService';
import { actions } from './slice';
import { selectRoutesTracking } from './selectors';

function* fetchRoutesTracking() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      paquerId,
      search,
    } = yield select(selectRoutesTracking);
    const { content: routes, totalElements } = yield call(
      RouteService.getAllTrackingRoutes,
      {
        page: pageNumber,
        size: pageSize,
        paquerId,
        search,
      },
    );
    const mappedRoutes = routes.map((route) => ({
      ...route,
      _paquerName: `${route?.drive?.name} ${route?.drive?.lastName}`,
      _packagesAmount: route?.packagesList?.length,
    }));
    const items = mappedRoutes;
    const total = totalElements;
    yield put({
      type: actions.fetchRoutesSuccess.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    yield put({
      type: actions.fetchRoutesFailure.type,
      payload: error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.fetchRoutes,
        actions.setRoutesPageable,
        actions.updatePaquer,
        actions.updateSearch,
        actions.resetFilters,
      ],
      fetchRoutesTracking,
    ),
  ]);
}
