import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spin, notification, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/userView/slice';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import InputField from 'components/Forms/input';
import UploadField from 'components/Forms/upload';
import SelectField from 'components/Forms/select';
import useStyles from 'components/Forms/styles';
import SITELINKS from 'constants/sitelinks';
import { useHistory } from 'react-router-dom';
import ChangePassword from 'components/changePassword';
import Card from 'components/card';

const User = ({
  loaded,
  avatar,
  validationSchema,
  onSubmit,
  currentCity,
  cities,
  roles,
  setCurrentCountry,
  initialValues,
  stores,
  countries,
  isProfile,
  isUserModify,
  isAdd,
  disabledInputs,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const formik = useRef();
  const optionGenerator = (arrayData) =>
    arrayData.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  const [currentRole, setCurrentRole] = useState(initialValues.userRoleID);
  useEffect(() => {
    formik.current.setFieldValue('cityID', currentCity);
  }, [currentCity]);
  const statusList = [
    { name: 'Activo', id: 1 },
    { name: 'Dado de baja', id: 6 },
  ];
  const storeRoles = [11, 12];
  const optionStores = stores
    ? stores.map((store) => ({
        label: `CódigoÑ ${store.storeCode} - ${store.name}`,
        value: store.id,
      }))
    : '';
  const optionsCountries = optionGenerator(countries);
  const optionsCities = optionGenerator(cities);
  const optionsStatus = optionGenerator(statusList);
  const optionsRoles = roles?.map((role) => ({
    label: role.name,
    value: role.value,
  }));
  const handleChange = (role) => {
    setCurrentRole(role);
    if (storeRoles.includes(role)) {
      if (stores.length === 0) {
        notification.info({
          message:
            'Es necesario que cargues al menos una tienda para asignarla a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(SITELINKS.stores.add);
        }, 2000);
      }
    }
  };
  const handleImageChange = (image) => dispatch(actions.setAvatar(image));
  return (
    <Spin spinning={!loaded}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        <Form>
          <Row type="flex" justify="space-between">
            <Col xs={24} md={16}>
              <Card
                title="Información básica"
                style={{ margin: '8px', height: '96.5%' }}
              >
                <Col span={24} className={classes.cardContent}>
                  <Row>
                    <Col xs={24} lg={8}>
                      <UploadField
                        image={avatar}
                        onChangeImage={handleImageChange}
                      />
                    </Col>
                    <Col xs={24} lg={16}>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <InputField label="ID" fieldName="id" disabled />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Nombre" fieldName="name" />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Apellido" fieldName="lastName" />
                        </Col>
                        <Col span={24}>
                          <SelectField
                            label="Rol"
                            onChange={(role) => handleChange(role)}
                            fieldName="userRoleID"
                            disabled={disabledInputs.userRoleID}
                            options={optionsRoles}
                          />
                          {storeRoles.includes(currentRole) && (
                            <Col span={24}>
                              <SelectField
                                label="Tienda"
                                fieldName="store"
                                options={optionStores}
                              />
                            </Col>
                          )}
                        </Col>
                        {(isProfile || isUserModify) && (
                          <Col span={24}>
                            <ChangePassword email={initialValues.email} />
                          </Col>
                        )}
                        {isUserModify && (
                          <Col span={24}>
                            <SelectField
                              label="Estado"
                              fieldName="statusID"
                              options={optionsStatus}
                            />
                          </Col>
                        )}
                        {isAdd && (
                          <>
                            <Col span={12}>
                              <InputField
                                label="Contraseña"
                                type="password"
                                fieldName="pwd"
                              />
                            </Col>
                            <Col span={12}>
                              <InputField
                                label="Confirmar contraseña"
                                type="password"
                                fieldName="confirmpwd"
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card title="Información de contacto" style={{ margin: '8px' }}>
                <Row>
                  <Col span={24} className={classes.cardContent}>
                    <Row gutter={[8, 8]}>
                      <Col xs={24}>
                        <InputField
                          label="Móvil"
                          fieldName="phone"
                          type="number"
                        />
                      </Col>
                      <Col xs={24}>
                        <InputField label="Email" fieldName="email" />
                      </Col>
                      <Col xs={24}>
                        <SelectField
                          label="País"
                          fieldName="countryID"
                          onChange={(country) => setCurrentCountry(country)}
                          options={optionsCountries}
                        />
                      </Col>
                      <Col xs={24}>
                        <SelectField
                          label="Ciudad"
                          fieldName="cityID"
                          options={optionsCities}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={classes.saveButton}
                size="large"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Spin>
  );
};

User.propTypes = {
  loaded: PropTypes.bool.isRequired,
  avatar: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isProfile: PropTypes.bool,
  isUserModify: PropTypes.bool,
  isAdd: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentCity: PropTypes.number,
  cities: PropTypes.arrayOf(PropTypes.object),
  setCurrentCountry: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    lastname: PropTypes.string,
    pwd: PropTypes.string,
    phone: PropTypes.number,
    confirmpwd: PropTypes.string,
    cityID: PropTypes.number,
    countryID: PropTypes.number,
    userRoleID: PropTypes.number,
    store: PropTypes.arrayOf(PropTypes.object),
    email: PropTypes.string,
  }),
  disabledInputs: PropTypes.shape({
    userRoleID: PropTypes.bool,
  }),
};

User.defaultProps = {
  isProfile: false,
  isUserModify: false,
  isAdd: false,
  stores: [],
  currentCity: 1,
  cities: [],
  setCurrentCountry: 1,
  initialValues: {
    id: null,
    name: '',
    lastname: '',
    pwd: '',
    confirmpwd: '',
    phone: null,
    confirmpassword: '',
    cityID: null,
    countryID: 1,
    store: '',
    email: '',
  },
  disabledInputs: {
    userRoleID: false,
  },
};

export default User;
