import React, { ReactNode } from 'react';
import { Button, Card, Result as ResultAntd } from 'antd';

interface ResultProps {
  result: any;
  children: ReactNode[];
  onRetry?: () => void;
}

const Result = ({ result, onRetry, children }: ResultProps) => {
  if (!result) return children;

  let extraButtons: ReactNode[] = [];
  if (result) {
    if (result.status === 'error' || result.status === 'warning') {
      extraButtons = [
        <Button
          type="primary"
          key="buy"
          onClick={() => {
            if (onRetry) onRetry();
          }}
        >
          Volver
        </Button>,
      ];
    }
  }

  return (
    <Card>
      <ResultAntd
        status={result.status}
        title={result.title}
        subTitle={result.subTitle}
        extra={
          result.extraButtons && result.extraButtons.length > 0
            ? result.extraButtons
            : extraButtons
        }
      />
    </Card>
  );
};

Result.defaultProps = {
  onRetry: null,
};

export default Result;
