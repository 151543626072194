import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const marketplacesSelector = (state) => state[name] || initialState;

export const marketplacesObjectItemSelector = (state) => state[name].items;

export const marketplacesItemsSelector = createSelector(
  marketplacesObjectItemSelector,
  (items) => Object.values(items),
);

export const marketplacesLoadedSelector = (state) => state[name].loaded;

const makeMarketplaceSelector = () =>
  createSelector(marketplacesSelector, (substate) => substate);

export default makeMarketplaceSelector;
