import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Modal as AntdModal, theme } from 'antd';

export default function Modal({ title, children, ...props }) {
  const { token } = theme.useToken();
  return (
    <AntdModal
      closable={false}
      title={
        <Divider
          orientation="left"
          style={{ borderTop: token.colorPrimary, marginTop: 0 }}
        >
          {title}
        </Divider>
      }
      {...props}
    >
      {children}
    </AntdModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.shape(),
};

Modal.defaultProps = {
  children: <></>,
};
