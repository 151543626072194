/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Flex, Switch, ConfigProvider, Tooltip, Popconfirm } from 'antd';
import { CompassOutlined, DeleteOutlined } from '@ant-design/icons';
import PaqueryTable from '@paquery-team/lib-table';
import SizeIndicator from 'components/sizeIndicator';
import { useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';

interface RoutePackageProps {
  packages: any[];
  loading: boolean;
  editAddressCallback?: (record: any, value: any) => void;
  recenterCallback?: (center: any) => void;
  removePackageCallback?: (packageId: number) => void;
}

const RoutePackages = ({
  packages,
  loading,
  editAddressCallback,
  recenterCallback,
  removePackageCallback,
}: RoutePackageProps) => {
  const globals = useSelector(selectGlobals);
  const [filterGeolocated, setFilterGeolocated] = useState(false);
  const [search, setSearch] = useState<string>('');

  const externalCodeColumn = {
    title: 'Externo',
    dataIndex: 'externalCode',
    width: 200,
  };

  const sizeColumn = {
    title: 'Tamaño',
    dataIndex: 'packageSize',
    align: 'center',
    render: (sizeId: number) => (
      <SizeIndicator
        text={
          globals.packages.size.find(
            (packSize: any) => packSize.value === sizeId,
          ).name[0]
        }
      />
    ),
    width: 90,
  };

  const addressColumn = {
    title: 'Dirección',
    render: (record: any) => {
      if (record.geoLat || record.geoLng) {
        return record.geoAddress;
      }
      return (
        <Input
          style={{ margin: '-5px' }}
          placeholder={record.geoAddress}
          onPressEnter={(e) => {
            if (editAddressCallback) console.log(e);
            // editAddressCallback(record, e.target.value);
          }}
          onBlur={(e) => {
            if (editAddressCallback)
              editAddressCallback(record, e.target.value);
          }}
        />
      );
    },
  };

  const locateColumn = {
    align: 'center',
    render: (record: any) => {
      if (record.geoLat && record.geoLng)
        return (
          <Tooltip title="Buscar en mapa">
            <CompassOutlined
              style={{ fontSize: 18 }}
              onClick={() => {
                if (recenterCallback)
                  recenterCallback({ lat: record.geoLat, lng: record.geoLng });
              }}
            />
          </Tooltip>
        );
      return null;
    },
    width: 60,
  };

  const deleteColumn = {
    align: 'center',
    render: (record: any) => (
      <Popconfirm
        title="Remover"
        description="¿Desea remover el paquete de la ruta?"
        onConfirm={() => {
          if (removePackageCallback) removePackageCallback(record.id);
        }}
        okText="Remover"
        cancelText="Cancelar"
        placement="topRight"
        icon={<DeleteOutlined style={{ color: 'red' }} />}
      >
        <Tooltip title="Remover">
          <DeleteOutlined style={{ color: 'red', fontSize: 18 }} />
        </Tooltip>
      </Popconfirm>
    ),
    width: 60,
  };

  const dataColumns = [
    externalCodeColumn,
    sizeColumn,
    addressColumn,
    locateColumn,
    deleteColumn,
  ];

  const searcher = {
    key: 'searchCodeOrDestinationName',
    onSearching: (value: string) => {
      setSearch(value);
    },
    placeholder: 'Código externo o dirección',
    allowEmptySearch: true,
  };

  let filteredPackages = packages;
  if (search)
    filteredPackages = packages.filter((p) => p.externalCode.includes(search));

  const ungeoPackages = filteredPackages.filter(
    (packet) => !packet.geoLat || !packet.geoLng,
  );

  return (
    <div id="route-table" style={{ height: '100%' }}>
      <PaqueryTable
        virtual
        tableHeight={340}
        loading={loading}
        header={{
          refresh: () => null,
          searcher,
          extraMainAction: (
            <ConfigProvider theme={{ token: { colorPrimary: '#FF9900' } }}>
              <Flex align="center" style={{ height: '100%' }}>
                <Switch
                  checkedChildren="Sin geolocalizar"
                  unCheckedChildren="Todos"
                  onChange={() => setFilterGeolocated((last) => !last)}
                />
              </Flex>
            </ConfigProvider>
          ),
        }}
        dataSource={filterGeolocated ? ungeoPackages : filteredPackages}
        dataColumns={dataColumns}
        colsForSmallDevice={[externalCodeColumn]}
        colsForLargeDevice={dataColumns}
        usePackageRowColors
      />
    </div>
  );
};

RoutePackages.defaultProps = {
  editAddressCallback: null,
  recenterCallback: null,
  removePackageCallback: null,
};

export default RoutePackages;
