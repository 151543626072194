import { createUseStyles } from 'react-jss';

export const useStylesGenerateRoute = createUseStyles((theme) => {
  return {
    registerPacketInput: {
      boxShadow: `0px 0px 3px ${theme.colorPrimary}`,
      border: `1px solid ${theme.colorPrimary}`,
      borderRadius: '8px',
      outline: '1px solid transparent',
      transition: '100ms all linear',
      '&:focus': {
        boxShadow: `0px 0px 3px ${theme.colorPrimary}`,
        border: `1px solid ${theme.colorPrimary}`,
        outline: `1px solid ${theme.colorPrimary}`,
      },
    },
  };
});

export default useStylesGenerateRoute;
