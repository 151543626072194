/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Col, Card, Input, Flex, Button, theme } from 'antd';
import {
  DropboxOutlined,
  LoadingOutlined,
  RightOutlined,
  ScanOutlined,
} from '@ant-design/icons';
import useCheckDesktop from 'hooks/useIsDesktop';
import { useDebounce } from 'util/Hooks';
import QRScanner from 'components/QRScanner';
import { useStylesGenerateRoute } from '../styles';

const WAIT_INPUT_EXTERNAL_CODE = 1500;

const PackageInput = () => {
  const isDesktop = useCheckDesktop();
  const { token } = theme.useToken();
  const classes = useStylesGenerateRoute({ theme: token });
  const [externalCode, setExternalCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const debouncedExternalCode = useDebounce(
    externalCode,
    WAIT_INPUT_EXTERNAL_CODE,
  );

  useEffect(() => {
    // do something
    if (!debouncedExternalCode || debouncedExternalCode === '') return;
    setLoading(true);
    setExternalCode('');
    console.log(
      'POST assignPackageToRoute externalCode: ',
      debouncedExternalCode,
    );
    setTimeout(() => setLoading(false), 2000);
  }, [debouncedExternalCode]);

  const handleScanQR = (data: any) => {
    if (data) {
      setExternalCode(data);
      setShowScanner(false);
    }
  };

  return (
    <>
      <Col xs={24} md={11}>
        {isDesktop ? (
          <Card
            bodyStyle={{
              padding: '10px',
            }}
          >
            <Flex gap={8} justify="space-between">
              <ScanOutlined style={{ fontSize: 32 }} />
              <Input
                placeholder="Ingresá el código externo"
                autoFocus
                className={classes.registerPacketInput}
                value={externalCode}
                onChange={(e) => {
                  setExternalCode(e.target.value);
                }}
              />
            </Flex>
          </Card>
        ) : (
          <Col span={24}>
            <QRScanner
              showCamera={showScanner}
              setShowCamera={setShowScanner}
              onScan={handleScanQR}
            />
            <Button
              type="primary"
              style={{ minWidth: '100%' }}
              icon={<ScanOutlined />}
              onClick={() => setShowScanner(true)}
            >
              Haz click para abrir el scanner
            </Button>
          </Col>
        )}
      </Col>
      {isDesktop && (
        <Col span={2}>
          <Flex style={{ minHeight: '100%' }} justify="center" align="center">
            <RightOutlined style={{ fontSize: 24 }} />
          </Flex>
        </Col>
      )}
      <Col xs={24} md={11}>
        <Card bodyStyle={{ padding: '10px' }}>
          <Flex justify="center">
            {loading ? (
              <LoadingOutlined style={{ fontSize: 32 }} />
            ) : (
              <DropboxOutlined style={{ color: 'grey', fontSize: 32 }} />
            )}
          </Flex>
        </Card>
      </Col>
    </>
  );
};

export default PackageInput;
