import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  paquerId: null,
  packages: {
    items: {},
    pageable: {
      pageSize: 10,
      pageNumber: 0,
      totalElements: 0,
    },
    search: '',
    loaded: true,
  },
  loaded: false,
};

const paquersSlice = createSlice({
  name: 'paquers',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload;
    },
    failure: (state) => {
      state.loaded = true;
    },
    fetchPackages: (state) => {
      state.packages.loaded = false;
    },
    fetchPackagesSucess: (state, action) => {
      state.packages.loaded = true;
      state.packages.pageable.totalElements = action.payload.total;
      /* action.payload.items.forEach((packet) => {
        state.packages.items[packet.id] = packet;
      }); */
      state.packages.items = action.payload.items;
    },
    fetchPackagesFailure: (state) => {
      state.packages.loaded = true;
    },
    updatePaquerId: (state, action) => {
      state.paquerId = action.payload;

      // Reset the packages after changing the paquer
      state.packages.search = initialState.packages.search;
      state.packages.pageable = initialState.packages.pageable;
    },
    removePacket: (state, action) => {
      state.packages.items.forEach((packet) => {
        if (packet.id === action.payload) {
          state.packages.items.splice(state.packages.items.indexOf(packet), 1);
        }
      });
    },
    addPacket: (state, action) => {
      const packet = state.packages.items.filter(
        (p) => p.id === action.payload.id,
      );
      if (packet && packet.length > 0) {
        if (packet[0].status === action.payload.status) return;
        state.packages.items.splice(state.packages.items.indexOf(packet[0]), 1);
      }
      state.packages.items.unshift(action.payload);
    },
    updatePackagesSearch: (state, action) => {
      state.packages.pageable.pageNumber = 0;
      state.packages.search = action.payload;
    },
    updatePagination: (state, action) => {
      state.packages.pageable.pageNumber = action.payload.pageNumber;
      state.packages.pageable.pageSize = action.payload.pageSize;
    },
    resetFilters: (state) => {
      state.packages.search = initialState.packages.search;
      state.packages.pageable = initialState.packages.pageable;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = paquersSlice;
