import { createUseStyles } from 'react-jss';

export const useStylesLoadingPaquers = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loadingIcon: {
    fontSize: '7vw',
  },
  loadingText: {
    fontSize: '3vw',
    textTransform: 'uppercase',
    display: 'block',
    width: '35vw',
    textAlign: 'center',
    marginTop: '60px',
    letterSpacing: '-0.07cm',
    fontWeight: '700',
  },
});

export const useStylesStatusPackage = createUseStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 5,
    marginTop: '20px',
  },
  icon: {
    color: (props) => (props && props.iconColor ? props.iconColor : 'black'),
    fontSize: '11vh',
  },
  description: {
    marginTop: 10,
    textTransform: 'uppercase',
    letterSpacing: '-0.02cm',
    lineHeight: 2,
    fontWeight: '700',
  },
  fadeEnter: {
    opacity: 0,
  },
  fadeEnterActive: {
    opacity: 1,
    transition: '500ms all ease-in',
  },
  fadeExit: {
    opacity: 1,
  },
  fadeExitActive: {
    opacity: 0,
    transition: '500ms all ease-in',
  },
});

export const useStylesSelectPaquer = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  paqueryLogo: {
    width: '20%',
    heigth: 'auto',
  },
  selectQuote: {
    display: 'block',
    fontWeight: '800',
    fontSize: '2vw',
    textAlign: 'center',
  },
  '@media (max-width: 992px)': {
    paqueryLogo: {
      width: '40%',
    },
    selectQuote: {
      fontSize: '4vw',
    },
  },
});

export const useStylesDeparturePacket = createUseStyles((theme) => {
  return {
    pageContainer: {
      padding: 15,
      height: 'calc(100% - 95px)',
      '& > *': {
        boxSizing: 'border-box',
      },
    },
    pageTitle: {
      letterSpacing: '-0.03cm',
      alignSelf: 'flex-start',
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '2em',
      boxShadow: '0px 0px 2px #c3c3c3',
      margin: '15px 15px 0 15px',
    },
    totalizersContainer: {
      paddingTop: 10,
      paddingBottom: 8,
      fontSize: 16,
      textAlign: 'start',
    },
    totalizerCount: {
      fontSize: 14,
      fontWeight: 'bold',
      margin: '0px 8px',
    },
    highValueNotificationContainer: {
      backgroundColor: '#ffcccb',
      fontWeight: 700,
    },
    sidebarContainer: {
      height: '100%',
      textAlign: 'center',
      alignContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      '& > * > *': {
        width: '100%',
        textAlign: 'center',
      },
    },
    selectPaquerContainer: {
      flex: '0 0 50px',
      display: 'flex',
      flexDirection: 'column',
    },
    registerPacketContainer: {
      flex: '0 0 50px',
    },
    registerPacketSpan: {
      '& > *': {
        padding: '0 5px',
      },
    },
    registerPacketInput: {
      boxShadow: `0px 0px 3px ${theme.colorPrimary}`,
      border: `1px solid ${theme.colorPrimary}`,
      borderRadius: '8px',
      outline: '1px solid transparent',
      transition: '100ms all linear',
      '&:focus': {
        boxShadow: `0px 0px 3px ${theme.colorPrimary}`,
        border: `1px solid ${theme.colorPrimary}`,
        outline: `1px solid ${theme.colorPrimary}`,
      },
    },
    statusRegisterContainer: {
      flex: '1 1 80%',
    },
    deliverablePackage: {
      background: '#9ccc65',
    },
    onPaquerHands: {
      background: '#c6ff00',
    },
    firstDeliveryTry: {
      background: '#ffb74d',
    },
    deliveryExpired: {
      position: 'relative',
      transform: 'scale(1)',
      '&::after': {
        position: 'absolute',
        top: 6,
        left: 5,
        height: 8,
        width: 8,
        borderRadius: '50%',
        backgroundColor: '#ff1212',
        boxShadow: '0px 0px 5px #fff',
        content: '""',
        border: '1px solid #900',
        animationName: '$callAtentionExpired',
        animationIterationCount: 'infinite',
        animationDuration: '1s',
      },
    },
    '@media (min-width:992px)': {
      sidebarContainer: {
        margin: '0 15px 0 0',
      },
      pageTitle: {
        fontSize: '2em',
      },
    },
    '@media (width: 1024px) and (max-width: 1239px)': {
      totalizersContainer: {
        fontSize: 12,
      },
    },
    '@media (min-width:2560px)': {
      totalizersContainer: {
        fontSize: 26,
      },
      totalizerCount: {
        fontSize: 26,
        margin: '0px 20px',
      },
    },
  };
});

export default useStylesDeparturePacket;
