import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { call, put, select, all, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { selectPackagesWithoutZone } from './selectors';

const URL = API.packages.history;

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      search,
      status,
      type,
      date,
      marketplaceId,
    } = yield select(selectPackagesWithoutZone);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      packageType: type,
      marketplaceId: marketplaceId || '',
      search,
      status,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(getData, `${URL}?${searchParams.toString()}`);
    const items = packages.content;
    const total = packages.totalElements;
    yield put({
      type: actions.success.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* updateBasefilters() {
  const { status, date } = yield select(selectPackagesWithoutZone);
  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

// Esto es porque PaquerSettingsButton puede (o no) modificar el estado del paquete.
// En caso que lo modifique queremos volver a fetchear.
function* addPacketSagas({ payload }) {
  const { items } = yield select(selectPackagesWithoutZone);
  const index = items.findIndex((packet) => packet.id === payload.id);
  if (index !== -1 && items[index].status === payload.status) {
    return;
  }
  yield call(updateBasefilters);
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updatePagination,
        actions.updateSearch,
        actions.updatePackageType,
        actions.updateStatus,
        actions.refreshPage,
        actions.resetFilters,
        actions.updateDate,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.addPacket, addPacketSagas),
    takeLatest(actions.fetch, fetchSaga),
  ]);
}
