import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import API from 'constants/api';

export function useMediaQuery(
  query,
  defaultMatches = window.matchMedia(query).matches,
) {
  const [matches, setMatches] = useState(defaultMatches);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) setMatches(media.matches);

    const listener = () => setMatches(media.matches);

    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [query, matches]);

  return matches;
}

export default function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);
  return debouncedValue;
};

const getFullAddress = ({
  street,
  houseNumber,
  district,
  postalCode,
  city,
  country,
}) =>
  `${street}${houseNumber ? ` ${houseNumber}` : ''}${
    district ? `, ${district}` : ''
  }, CP:${postalCode}, ${city}, ${country}`;

export const useAdressSelector = (searchAddress, parameters) => {
  const [locationSuggestion, setLocationSuggestion] = useState([]);
  useEffect(() => {
    const getSuggestions = async () => {
      try {
        const geoCodeResponse = await axios.get(
          `${API.geocode.get}&${new URLSearchParams({
            ...parameters,
            query: searchAddress,
          })}`,
        );
        if (geoCodeResponse.status === 200) {
          const geoLocations = geoCodeResponse.data.suggestions.map(
            (location) => location.address,
          );
          setLocationSuggestion(geoLocations);
        }
      } catch (error) {
        // No actions on error situations
      }
    };
    if (searchAddress) {
      getSuggestions();
    }
  }, [searchAddress, parameters]);
  let locationList;
  if (locationSuggestion && locationSuggestion.length > 0) {
    locationList = locationSuggestion.map((location) => ({
      value: getFullAddress(location),
    }));
  }
  return {
    getFullAddress,
    setLocationSuggestion,
    locationSuggestion,
    locationList,
  };
};
