import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { authProfile } from 'redux/auth/selectors';
import { name, actions, reducer } from './slice';
import saga from './saga';
import makeGlobals from './selectors';

export default function useGlobals() {
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  const dispatch = useDispatch();
  const globals = useSelector(makeGlobals(), shallowEqual);
  const profile = useSelector(authProfile);
  useEffect(() => {
    if (profile && !globals.loaded) {
      dispatch(actions.fetch(profile.countryID));
    }
  }, [dispatch, profile, globals.loaded]);
  return null;
}
