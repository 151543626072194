import React, { useCallback, useEffect, useState } from 'react';
import { Select, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/packages/slice';
import { actions as withPaquersActions } from 'redux/packagesWithPaquers/slice';
import { actions as withoutZoneActions } from 'redux/packagesWithoutZones/slice';
import { actions as bySearchCodeActions } from 'redux/packagesBySearchCode/slice';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const { Option } = Select;
const useStyles = createUseStyles((_theme) => {
  return {
    selector: {
      border: `1px solid ${_theme.colorPrimary}`,
      outline: 'none',
      boxShadow: `0px 0px 4px ${_theme.colorPrimary}`,
      animationName: '$heartBeat',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
  };
});

const PaquerSelector = ({
  defaultValue,
  isWithPaquerListing,
  isWithoutZone,
  isBySearchCode,
  options,
  disabled,
  packageId,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const dispatch = useDispatch();
  const [paquerList, setPaquerList] = useState([]);

  useEffect(() => {
    if (options.length > 0) {
      setPaquerList(
        options.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [options]);

  const searchPaquerCallback = useCallback(
    (selectedPaquerId) => {
      const selectedPaquer = options.find(
        (paquer) => paquer.id === selectedPaquerId,
      );
      const payload = {
        packageId,
        selectedPaquer,
      };
      if (isWithPaquerListing) {
        dispatch(withPaquersActions.updatePackagePaquer(payload));
        return;
      }
      if (isWithoutZone) {
        dispatch(withoutZoneActions.updatePackagePaquer(payload));
        return;
      }
      if (isBySearchCode) {
        dispatch(bySearchCodeActions.updatePackagePaquer(payload));
        return;
      }
      dispatch(actions.updatePackagePaquer(payload));
    },
    [dispatch],
  );

  return (
    <Select
      defaultValue={defaultValue}
      disabled={disabled}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: 160 }}
      placeholder="Paquer seleccionado"
      onChange={searchPaquerCallback}
      className={classes.selector}
    >
      {paquerList.map((paquer) => (
        <Option key={paquer.value} value={paquer.value}>
          {paquer.name}
        </Option>
      ))}
    </Select>
  );
};

PaquerSelector.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  packageId: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isWithPaquerListing: PropTypes.bool,
  isWithoutZone: PropTypes.bool,
  isBySearchCode: PropTypes.bool,
  disabled: PropTypes.bool,
};

PaquerSelector.defaultProps = {
  isWithPaquerListing: null,
  isWithoutZone: null,
  isBySearchCode: null,
  disabled: false,
};

export default PaquerSelector;
