import { connectRouter } from 'connected-react-router';
import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';

export const history = createBrowserHistory();

const createReducer = (injectedReducer = {}) => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...injectedReducer,
  });
  return rootReducer;
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const { run: runSaga } = sagaMiddleware;
const enhancers = [
  createInjectorsEnhancer({
    createReducer,
    runSaga,
  }),
];

const store = configureStore({
  reducer: createReducer(),
  enhancers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: false,
    }),
    ...middlewares,
  ],
});

export default store;
