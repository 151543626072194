import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'constants/paquery-logo.png';
import { authIsLoginSelector } from 'redux/auth/selectors';
import { actions } from 'redux/auth/slice';
import API from 'constants/api';
import { ConfigProvider } from 'antd';
import LoginForm from '@paquery-team/lib-loginform';

const URL = {
  recover: API.auth.recover,
  confirm: API.auth.confirm,
};

const Login = () => {
  const dispatch = useDispatch();
  const isLoginIn = useSelector(authIsLoginSelector);
  const submitLogin = useCallback(
    (values) => {
      dispatch(actions.fetch(values));
    },
    [dispatch],
  );
  const submit = {
    login: submitLogin,
  };

  const PAQUERY_BLUE = '#254370';
  const PAQUERY_GREEN = '#0cb8b1';

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PAQUERY_GREEN,
          colorBgBase: PAQUERY_BLUE,
        },
      }}
    >
      <LoginForm
        applicationName="Paquery Points"
        submit={submit}
        isLogginIn={isLoginIn}
        loginFailure={false}
        tenantLogo={Logo}
        URL={URL}
      />
    </ConfigProvider>
  );
};

export default Login;
