import { all, put, select, takeEvery } from 'redux-saga/effects';
import { packageRefundDepartureItemsSelector } from './selectors';
import { name, actions } from './slice';

function* initialSaga() {
  const items = yield select(packageRefundDepartureItemsSelector);
  if (!items || items.length === 0) {
    const storageItems = localStorage.getItem(name);
    if (storageItems) {
      yield put({
        type: actions.success.type,
        payload: JSON.parse(storageItems),
      });
    }
  }
}

function* updateStoragePackagesSaga() {
  const items = yield select(packageRefundDepartureItemsSelector);
  localStorage.setItem(name, JSON.stringify(items));
}

function* dispatchSuccessSaga({ payload }) {
  const packageIds = payload;
  const items = yield select(packageRefundDepartureItemsSelector);
  const filteredPackages = items.filter(
    (packet) => !packageIds.includes(packet.id),
  );
  localStorage.removeItem(name);
  if (filteredPackages.length) {
    yield put({ type: actions.updatePackets.type, payload: filteredPackages });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.dispatchPackagesSuccess, dispatchSuccessSaga),
    takeEvery(actions.removePacketSuccess, updateStoragePackagesSaga),
    takeEvery(actions.updatePackets, updateStoragePackagesSaga),
    takeEvery(actions.addPackage, updateStoragePackagesSaga),
  ]);
}
