import Api from 'util/Api';
import API from 'constants/api';
import store from 'redux/store';
import { RoutePackage } from 'interfaces/route.package';
import { Route } from 'interfaces/route';
import { PACKAGE } from 'constants/packages';

// const getToken = () => {
//   const CREDENTIAL_USER_PQ = process.env.REACT_APP_PQROUTER_USER;
//   const CREDENTIAL_PASSWORD_PQ = process.env.REACT_APP_PQROUTER_PASSWORD;

//   const usernamePasswordBuffer = Buffer.from(
//     `${CREDENTIAL_USER_PQ}:${CREDENTIAL_PASSWORD_PQ}`,
//   );
//   const base64data = usernamePasswordBuffer.toString('base64');

//   return `Basic ${base64data}`;
// };

export const getAmountOfDeliveredPackages = (
  packages: RoutePackage[] | null | undefined,
) => {
  if (!packages || packages.length === 0 || !Array.isArray(packages)) return 0;
  return packages.filter(
    (package_) => package_.packageStatus === PACKAGE.STATUSES.DELIVERED,
  ).length;
};

export const getAmountOfPackagesInPaquersHands = (
  packages: RoutePackage[] | null | undefined,
) => {
  if (!packages || packages.length === 0 || !Array.isArray(packages)) return 0;
  return packages.filter(
    (package_) => package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER,
  ).length;
};

export const getAmountOfPackagesNotDelivered = (
  packages: RoutePackage[] | null | undefined,
) => {
  if (!packages || packages.length === 0 || !Array.isArray(packages)) return 0;
  let amount = 0;
  packages.forEach((package_: RoutePackage) => {
    if (package_.packageStatus === PACKAGE.STATUSES.DELIVERED) return;
    if (package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER) return;
    amount += 1;
  });
  return amount;
};

export const getAllTrackingRoutes = async ({
  page,
  size,
  paquerId,
  search,
}: {
  page: string;
  size: string;
  paquerId: string;
  search: string;
}) => {
  const state: any = store.getState();

  const searchParams = new URLSearchParams({
    page,
    size,
    paquerId,
    search,
  });

  const response = await Api.apiAxios.get(
    `${API.routes.getAllStarted}/${
      state.auth.profile.paquerypoint.id
    }?${searchParams.toString()}`,
  );

  return response.data.data;
};

export const getAllHistoricalRoutes = async ({
  page,
  size,
  paquerId,
  search,
}: {
  page: string;
  size: string;
  paquerId: string;
  search: string;
}) => {
  const state: any = store.getState();

  const searchParams = new URLSearchParams({
    page,
    size,
    paquerId,
    search,
  });
  const response = await Api.apiAxios.get(
    `${API.routes.history}/${
      state.auth.profile.paquerypoint.id
    }?${searchParams.toString()}`,
  );

  return response.data.data;
};

export const endRouteByID = async (id: string | undefined) => {
  const response = await Api.apiAxios.put(`${API.routes.endRouteById(id)}`, {});

  if (
    response.status === 400 ||
    response.status === 500 ||
    response.status === 422
  ) {
    throw new Error(`${response.data.error} - ${response.status}`);
  }

  const route: Route = response.data.data;
  return route;
};

export const recreateRoute = async (id: number, packagesId: number[]) => {
  const response = await Api.apiAxios.post(
    `${API.routes.recreateRouteById(id)}`,
    { packagesId },
  );

  if (
    response.status === 400 ||
    response.status === 500 ||
    response.status === 422
  ) {
    throw new Error(`${response.data.error} - ${response.status}`);
  }

  return response.data.data;
};

export const getRouteDetailByID = async (id: string | undefined) => {
  const response = await Api.apiAxios.get(`${API.routes.getById(id)}`);

  if (
    response.status === 400 ||
    response.status === 500 ||
    response.status === 422
  ) {
    throw new Error(`${response.data.error} - ${response.status}`);
  }
  const route: Route = response.data.data;

  return route;
};

export const getPaquerActiveRoute = async (paquerId: string) => {
  const searchParams = new URLSearchParams({
    driverId: paquerId,
  });
  const response = await Api.apiAxios.get(
    `${API.routes.getActiveByPaquerId}?${searchParams.toString()}`,
  );

  if (
    response.status === 400 ||
    response.status === 500 ||
    response.status === 422
  ) {
    throw new Error(`${response.data.error} - ${response.status}`);
  }
  const route = response.data.data;

  return route;
};

export interface GenerateRouteParams {
  paquerId: number;
  type: 'last_package' | 'paquery_point' | 'other';
  address?: string;
  lat?: number;
  lng?: number;
}

interface RouteCreationBody {
  driver: {
    id: number;
  };
  starting_waypoint: {
    type: 'paquery_point';
    entity_id: string;
  };
  ending_waypoint?: {
    type: 'paquery_point' | 'other';
    entity_id?: string;
    lat?: number;
    lng?: number;
    address?: string;
  };
}

export const generateRoute = async ({
  paquerId,
  type,
  address,
  lat,
  lng,
}: GenerateRouteParams) => {
  const state: any = store.getState();
  const paquery_point_id = state.auth.profile.paquerypoint.id;

  const values: RouteCreationBody = {
    driver: {
      id: paquerId,
    },
    starting_waypoint: {
      type: 'paquery_point',
      entity_id: paquery_point_id,
    },
  };

  if (type === 'other') {
    values.ending_waypoint = {
      type: 'other',
      address,
      lat,
      lng,
    };
  }

  if (type === 'paquery_point') {
    values.ending_waypoint = {
      type: 'paquery_point',
      entity_id: paquery_point_id,
    };
  }

  const response = await Api.apiAxios.post(API.routes.generateByPaquer, values);
  if (response.status === 500) {
    throw new Error(`${response.data.error} - ${response.status}`);
  }
  if (response.status === 400 || response.status === 422) {
    throw new Error(`${response.data.message} - ${response.status}`);
  }
  return response;
};

const RouteService = {
  getAllTrackingRoutes,
  getRouteDetailByID,
  getPaquerActiveRoute,
  endRouteByID,
  getAmountOfDeliveredPackages,
  getAmountOfPackagesInPaquersHands,
  getAmountOfPackagesNotDelivered,
  getAllHistoricalRoutes,
  generateRoute,
  recreateRoute,
};

export default RouteService;
