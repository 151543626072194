import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackagePaquersHistory = (state) =>
  state[name] || initialState;

export const packagePaquersHistoryItemsSelector = (state) => state[name].items;

export const packagePaquersHistoryPaginationSelector = (state) =>
  state[name].pageable;

export const packagePaquersHistoryLoadedSelector = (state) =>
  state[name].loaded;

export const packagePaquersHistoryDateSelector = (state) => state[name].date;

export const packagePaquersHistoryTypeSelector = (state) => state[name].type;

export const packagePaquersHistoryStatusSelector = (state) =>
  state[name].status;

export const packagePaquersHistoryPaquerSelector = (state) =>
  state[name].paquerId;

export const packagePaquersHistorySearchSelector = (state) =>
  state[name].search;

const makeSelectPackagePaquersHistory = () =>
  createSelector(selectPackagePaquersHistory, (substate) => substate);

export default makeSelectPackagePaquersHistory;
