import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { actions } from './slice';
import { selectPackages } from './selectors';

const URL = `${API.packages.get}`;

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      search,
      status,
      type,
      term,
      date,
      marketplaceId,
      storeId,
    } = yield select(selectPackages);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      packageType: type,
      deliveryTerm: term,
      marketplaceId: marketplaceId || '',
      storeId: storeId || '',
      status,
      search,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(getData, `${URL}?${searchParams.toString()}`);
    const items = packages.content;
    const total = packages.totalElements;
    yield put({
      type: actions.fetchPackagesSuccess.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    // eslint-disable-next-line
    console.log('Fetch saga search by code error: ', error);
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

function* updateBasefilters() {
  const { status, date } = yield select(selectPackages);
  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetchPackages.type,
  });
}

// Esto es porque PaquerSettingsButton puede (o no) modificar el estado del paquete.
// En caso que lo modifique queremos volver a fetchear.
function* addPacketSagas({ payload }) {
  const { items } = yield select(selectPackages);
  const index = items.findIndex((packet) => packet.id === payload.id);
  if (index !== -1 && items[index].status === payload.status) {
    return;
  }
  yield call(updateBasefilters);
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updatePaginationPackages,
        actions.updateSearch,
        actions.updatePackageType,
        actions.updateDeliveryTerm,
        actions.updateStatus,
        actions.refreshPage,
        actions.resetFilters,
        actions.addPacket,
        actions.updateDate,
        actions.updateMarket,
        actions.updateStore,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.addPacket, addPacketSagas),
    takeLatest([actions.fetchPackages], fetchSaga),
  ]);
}
