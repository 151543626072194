export default {
  dashboard: '/dashboard',
  login: '/login',
  recover: '/recoverPassword',
  confirm: '/confirmRecovery',
  loggedHome: '/packagesBySearchCode',
  profile: '/profile',
  packet: {
    entry: '/entryPacket',
    departure: {
      paquer: '/paquerDeparture',
      paqueryPoint: '/paqueryPointDeparture',
      history: '/packages/departure/history',
    },
    deliver: '/deliverPickup',
  },
  packages: {
    bySearchCode: '/packagesBySearchCode',
    byId: '/packages/id/:id',
    byExternalCode: '/packages/:externalCode',
    history: '/packages/history',
    list: '/packages',
    view: '/packages/view/:id',
    withoutZone: '/packages/withoutZone',
    withPaquers: '/withPaquers',
    withPaquersHistorical: '/packages/withPaquers/history',
  },
  routes: {
    list: '/routes',
    create: '/routes/create',
    history: '/routes/history',
    view: '/routes/view/:id',
  },
  paquers: {
    list: '/paquers',
    view: '/paquers/view/:paquerId',
  },
  support: {
    index: '/support',
  },
};
