import React from 'react';
import { Card, Flex } from 'antd';

interface RouteSemaphoreProps {
  level: number;
}

const RouteSemaphore = ({ level }: RouteSemaphoreProps) => {
  const levels = ['Básico', 'Estándar', 'Prioritario'];
  return (
    <Card
      style={{ height: '100%' }}
      bodyStyle={{ height: '100%', paddingBottom: 15 }}
    >
      <Flex
        vertical
        align="center"
        justify="space-between"
        style={{ height: '100%' }}
      >
        <svg height="50" width="50">
          <circle
            cx="25"
            cy="25"
            r="25"
            fill={level === 2 ? '#fa541c' : 'lightgray'}
          />
        </svg>
        <svg height="50" width="50">
          <circle
            cx="25"
            cy="25"
            r="25"
            fill={level === 1 ? '#fadb14' : 'lightgray'}
          />
        </svg>
        <svg height="50" width="50">
          <circle
            cx="25"
            cy="25"
            r="25"
            fill={level === 0 ? '#7cb305' : 'lightgray'}
          />
        </svg>
        <p style={{ fontSize: 20 }}> {levels[level]} </p>
      </Flex>
    </Card>
  );
};

export default RouteSemaphore;
