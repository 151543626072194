import { createSelector } from 'reselect';
import { name, initialState } from './slice';
import {
  crossdockingDepartureDestinySelector,
  crossdockingDepartureSearchSelector,
} from '../packageCrossdockingDeparture/selectors';

export const packageRefundDepartureSelector = (state) =>
  state[name] || initialState;

export const packageRefundDepartureItemsSelector = (state) =>
  Object.values(state[name].items);

export const packageRefundDepartureSearchSelector = (state) =>
  state[name].search;

export const packageRefundDepartureLoadedSelector = (state) =>
  state[name].loaded;

export const packageRefundDeparturePageableSelector = (state) =>
  state[name].pageable;

const makePackageRefundDepartureSelector = () =>
  createSelector(packageRefundDepartureSelector, (substate) => substate);

export const packageRefundFilteredItemsSelector = createSelector(
  packageRefundDepartureItemsSelector,
  packageRefundDeparturePageableSelector,
  crossdockingDepartureSearchSelector,
  crossdockingDepartureDestinySelector,
  (items, { pageSize, pageNumber }, search, destiny) => {
    if (!destiny) {
      return [];
    }
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const externalCodeIgnoreCase = item.externalCode.toUpperCase();
        return (
          externalCodeIgnoreCase &&
          externalCodeIgnoreCase.includes(searchIgnoreCase)
        );
      });
    }
    if (destiny) {
      resultItems = resultItems.filter((item) => item.destinyID === destiny);
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makePackageRefundDepartureSelector;
