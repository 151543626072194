import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { theme } from 'antd';

const EditRecordButton = ({ record, link, queryParams }) => {
  const { token } = theme.useToken();
  return (
    <Link
      to={{
        pathname: `${link}/${record.id ? record.id : record.key}${
          queryParams ? `?${queryParams.toString()}` : ''
        }`,
        state: { record },
      }}
    >
      <EditOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
    </Link>
  );
};

// `/packages/${packageId}`

EditRecordButton.propTypes = {
  record: PropTypes.shape.isRequired,
  link: PropTypes.string.isRequired,
  queryParams: PropTypes.shape,
};

EditRecordButton.defaultProps = {
  queryParams: undefined,
};

export default EditRecordButton;
