import React from 'react';
import { theme } from 'antd';

export default function SizeIndicator({ text }: any) {
  const { token } = theme.useToken();
  let background = token.colorBgBase;
  switch (text) {
    case 'D':
      background = 'lightgrey';
      break;
    case 'C':
      background = '#597ef7';
      break;
    case 'M':
      background = '#389e0d';
      break;
    case 'G':
      background = '#eb2f96';
      break;
    default:
      break;
  }

  return (
    <span
      style={{
        background,
        padding: 3,
        color: token.colorBgBase,
        borderRadius: '10px',
        fontWeight: 'bold',
      }}
    >
      {text}
    </span>
  );
}
