import React, { useEffect, useState } from 'react';
import { Col, Progress, Row, Statistic, Tooltip } from 'antd';
import { Route } from 'interfaces/route';
import {
  getAmountOfDeliveredPackages,
  getAmountOfPackagesInPaquersHands,
  getAmountOfPackagesNotDelivered,
} from 'services/RouteService';
import Card from 'components/card';
import Driver from 'routes/app/routeGenerate/components/Driver';
import PaquerService from 'services/PaquerService';

const getPercentage = (
  amount: number | undefined,
  total: number | undefined,
) => {
  if (!total) return undefined;
  if (!amount) return 0;
  return Math.round((amount / total) * 100);
};
interface RouteDataProps {
  route: Route | null;
  refreshRoute: () => void;
}

const RouteData = ({ route, refreshRoute }: RouteDataProps) => {
  const [driver, setDriver] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (route) {
      if (route.driver) {
        try {
          PaquerService.geyById(route.driver.id).then((res) =>
            setDriver({
              ...res,
              fullname: `${res.name} ${res.lastName}`,
            }),
          );
          setDisabled(true);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [route]);

  const handleAssign = () => {
    if (route && driver) {
      console.log(
        `POST assignDriverToRoute driverId: ${driver.id} routeId: ${route.id}`,
      );
      refreshRoute();
    }
  };

  // Paquetes entregados (20)
  const packagesDelivered = getAmountOfDeliveredPackages(route?.packages);

  // Paquetes en poder del paquer (3)
  const packagesInPaquersHands = getAmountOfPackagesInPaquersHands(
    route?.packages,
  );
  // Paquetes no entregados, en estado final (en poder del paquer, intentos de entrega (1 y 2), cancelado, y siniestrado)
  const packagesNotDelivered = getAmountOfPackagesNotDelivered(route?.packages);
  // Paquetes entregados y no entregados, el total de los procesados
  const packagesProcessed = packagesDelivered + packagesNotDelivered;

  // Porcentaje de paquetes entregados y no entregados
  const percentageStatus =
    getPercentage(
      packagesNotDelivered + packagesDelivered,
      route?.packages?.length,
    ) ?? 0;

  // Porcentaje de paquetes entregados
  const percentageDelivered = getPercentage(
    packagesDelivered,
    route?.packages?.length,
  );

  // Costo declarado total, sumatoria de los costos declarados de los paquetes
  const totalEstimatedCost =
    route?.packages?.reduce((package1_, package2_) => {
      return package1_ + (package2_?.estimatedCost ?? 0);
    }, 0) ?? '-';

  return (
    <Row gutter={24}>
      <Col span={14}>
        <Card title="Datos de la ruta">
          <Row gutter={[32, 16]}>
            <Col span={8}>
              <Statistic title="Ruta" value={route?.id ?? '-'} />
            </Col>
            <Col span={16}>
              <Statistic
                title="Costo declarado"
                prefix="$"
                value={
                  typeof totalEstimatedCost === 'number'
                    ? totalEstimatedCost.toFixed(2)
                    : totalEstimatedCost
                }
              />
            </Col>
            <Col span={24}>
              <Row>
                <Col xs={24}>
                  <Row justify="space-between">
                    <Col>
                      <Statistic
                        title="Paquetes"
                        value={`${packagesProcessed}/${
                          route?.packages?.length ?? 0
                        }`}
                        valueStyle={{ fontSize: '1.25rem' }}
                      />
                    </Col>
                    <Col>
                      <Statistic
                        title="Estado"
                        valueStyle={{ fontSize: '1.25rem' }}
                        value={`${percentageStatus}%`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Tooltip
                    title={`${packagesDelivered} entregados / ${packagesNotDelivered} no entregados / ${packagesInPaquersHands} en ruta`}
                  >
                    <Progress
                      percent={percentageStatus}
                      success={{ percent: percentageDelivered }}
                      showInfo={false}
                      strokeColor="#ff4d4f"
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={10}>
        <Card
          style={{ height: '100%' }}
          bodyStyle={{ padding: 13, height: '100%' }}
        >
          <Driver
            driver={driver}
            setDriver={setDriver}
            disabled={disabled}
            handleAssign={handleAssign}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default RouteData;
