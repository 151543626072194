import React, { useCallback, useEffect, useState } from 'react';
import { Steps, Modal, notification } from 'antd';
import { PACKAGE, isVisitStatus } from 'constants/packages';
import SITELINKS from 'constants/sitelinks';
import { Route } from 'interfaces/route';
import { RoutePackage } from 'interfaces/route.package';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import RouteService from 'services/RouteService';
import { useHistory } from 'react-router-dom';
import Card from 'components/card';
import { IRouteProps } from '../..';

const { Step } = Steps;

interface PackagesDataProps {
  route: Route | null;
}

const PackagesData = ({ route, hasEnded }: PackagesDataProps & IRouteProps) => {
  const { confirm } = Modal;
  const [removeDisabled, setRemoveDisabled] = useState(true);
  const [packagesId, setPackagesId] = useState<any>({});
  const [loadingRecreateRoute, setLoadingRecreateRoute] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setPackagesId({});
  }, [route]);

  const getStepStatus = (package_: RoutePackage) => {
    if (!package_) return 'wait';
    if (
      [PACKAGE.STATUSES.DAMAGED, PACKAGE.STATUSES.CANCELLED].includes(
        package_?.packageStatus as number,
      )
    ) {
      return 'error';
    }
    if (isVisitStatus(package_?.packageStatus as number)) {
      return 'process';
    }
    if (package_.packageStatus === PACKAGE.STATUSES.DELIVERED) return 'finish';
    if (package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER)
      return 'wait';
    return 'wait';
  };

  // Esto es porque antd no tiene steps con color verde, le damos una classe que sobre escribe los estilos de ants, ver ./index.css
  const getStepClass = (package_: RoutePackage) => {
    if (!package_) return '';
    if (isVisitStatus(package_?.packageStatus as number)) {
      return 'delivery-try';
    }
    if (package_.packageStatus === PACKAGE.STATUSES.DELIVERED)
      return 'delivered';
    return '';
  };

  const getStatusDescription = (package_: RoutePackage) => {
    if (package_.packageStatus === PACKAGE.STATUSES.DELIVERED)
      return 'Entregado';
    if (isVisitStatus(package_?.packageStatus as number)) {
      return 'No entregado (Intento de entrega)';
    }
    if (package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER)
      return 'En poder del paquer';
    return 'No entregado';
  };

  const hasLocation = (package_: RoutePackage) => {
    if (!package_) return false;
    if (!package_.geoLng || !package_.geoLat) return false;
    return true;
  };

  const mappedPackages =
    route?.packages?.map((package_) => {
      return {
        ...package_,
        status: getStatusDescription(package_),
        error: !(
          package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER ||
          package_.packageStatus === PACKAGE.STATUSES.DELIVERED
        ),
      };
    }) ?? [];

  const recreateRoute = useCallback(async () => {
    setLoadingRecreateRoute(true);
    if (!route) return;
    try {
      const { id: newRouteId } = await RouteService.recreateRoute(
        route.id,
        Object.keys(packagesId).map((s) => parseInt(s, 10)),
      );
      history.push(SITELINKS.routes.view.replace(':id', newRouteId.toString()));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        notification.error({
          message: 'Error al recalcular ruta',
          description: `Se produjo un error al recalcular la ruta: ${err.message}`,
        });
      }
    } finally {
      setLoadingRecreateRoute(false);
    }
  }, [route, history, packagesId]);

  const selectPackage = (packet: any) => {
    const { packageID: id } = packet;
    if (packagesId[id]) {
      const packages = { ...packagesId };
      delete packages[id];
      setPackagesId(packages);
    } else {
      const packages = { ...packagesId };
      packages[id] = true;
      setPackagesId(packages);
    }
  };

  const removePackageRoute = () => {
    confirm({
      title: '¿Está seguro de que desea liberar los paquetes de la ruta?',
      content: (
        <div>
          <p>
            La ruta actual se eliminará y se creará una nueva con los paquetes
            restantes.
          </p>
          <p>Se removerán los paquetes:</p>
          <ul>
            {mappedPackages
              .filter((p) => packagesId[p.packageID!])
              .sort((p1, p2) => p1.packageID! - p2.packageID!)
              .map((p) => (
                <li>{p.externalCode}</li>
              ))}
          </ul>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: 'Aceptar',
      okType: 'danger',
      onOk: async () => {
        await recreateRoute();
      },
      cancelText: 'Cancelar',
    });
  };

  useEffect(() => {
    setRemoveDisabled(Object.keys(packagesId).length === 0);
  }, [packagesId]);

  const renderTitle = (packet: any) => {
    return (
      <h4>
        {' '}
        {packet.externalCode} - {packet.status}{' '}
      </h4>
    );
    // if (packet.packageStatus !== PACKAGE.STATUSES.INHANDSOFPAQUER || hasEnded)
    //   return (
    //     <h4>
    //       {' '}
    //       {packet.externalCode} - {packet.status}{' '}
    //     </h4>
    //   );
    // return (
    //   <Checkbox
    //     checked={packagesId[packet.packageID]}
    //     onChange={() => selectPackage(packet)}
    //   >
    //     <h3>
    //       {packet.externalCode} - {packet.status}
    //     </h3>
    //   </Checkbox>
    // );
  };

  return (
    <Card title="Paquetes de la ruta">
      <Steps current={2} progressDot={(dot: any) => dot} direction="vertical">
        {mappedPackages.map((packet) => (
          <Step
            className={getStepClass(packet)}
            key={packet.externalCode}
            title={renderTitle(packet)}
            description={
              <div>
                <p style={{ marginBottom: '0.25rem' }}>{packet.geoAddress}</p>
                {!hasLocation(packet) && <p>No tiene ubicación geografica</p>}
              </div>
            }
            status={getStepStatus(packet)}
          />
        ))}
      </Steps>
      {/* {!hasEnded && (
        <Button
          loading={loadingRecreateRoute}
          onClick={removePackageRoute}
          style={{ zIndex: 100 }}
          danger
          disabled={removeDisabled}
        >
          Remover paquetes
        </Button>
      )} */}
    </Card>
  );
};

export default PackagesData;
