import { EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Progress, Result, Row, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteHistory } from 'interfaces/route.history';
import { AxiosError } from 'axios';
import PaqueryTable from '@paquery-team/lib-table';
import SITELINKS from 'constants/sitelinks';
import { useDispatch, useSelector } from 'react-redux';
import {
  routesHistoryErrorSelector,
  routesHistoryLoadedSelector,
  routesHistoryPageableSelector,
  routesHistorySelector,
} from 'redux/routesHistory/selectors';
import { actions } from 'redux/routesHistory/slice';
import usePaquers from 'redux/paquers';
import useRoutes from 'redux/routesHistory';
import { actions as paquerActions } from 'redux/paquers/slice';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import { Route } from 'interfaces/route';

const getPercentage = (amount: number, total: number | null | undefined) => {
  if (!total) return undefined;
  if (!amount) return 0;
  return Math.round((amount / total) * 100);
};

interface ProgressBarProps {
  route: RouteHistory;
}

const ProgressBar = ({ route }: ProgressBarProps) => {
  const { packages, notdelivered, delivered } = route;
  // Porcentaje de paquetes entregados y no entregados
  const percentageStatus =
    getPercentage(notdelivered + delivered, route.packages) ?? 0;

  // Porcentaje de paquetes entregados
  const percentageDelivered = getPercentage(delivered, route.packages);

  return (
    <div>
      <Tooltip
        title={`${delivered} entregados / ${
          packages - delivered
        } no entregados`}
      >
        <Progress
          percent={percentageStatus}
          success={{ percent: percentageDelivered }}
          showInfo={false}
          strokeColor="#ff4d4f"
        />
      </Tooltip>
    </div>
  );
};

const idColumn = {
  title: 'Nro.',
  dataIndex: 'id',
  key: 'id',
  align: 'center',
};

const distributionTimeColumn = {
  title: 'Fecha de distribucion',
  // El guion bajo indica que es una propiedad que se genera en local, no la trae el backend
  // Revisar el sagas
  dataIndex: '_distributionDate',
  key: 'distributionDate',
  responsive: ['md'],
};

const paquerNameColumn = {
  title: 'Paquer',
  key: 'paquerName',
  // El guion bajo indica que es una propiedad que se genera en local, no la trae el backend
  // Revisar el sagas
  dataIndex: '_paquerName',
  responsive: ['sm'],
};
const packagesAmountColumn = {
  title: 'Paquetes',
  key: 'packages',
  dataIndex: 'packages',
  responsive: ['lg'],
  align: 'center',
  width: '5%',
};
const progressColumn = {
  title: 'Progreso',
  key: 'progress',
  dataIndex: 'progress',
  width: '20%',
  responsive: ['sm'],
  render: (text: string, record: RouteHistory) => (
    <ProgressBar route={record} />
  ),
};

const detailColumn = {
  key: 'detail',
  render: (record: RouteHistory) => (
    <Link to={`${SITELINKS.routes.list}/view/${record.id}?hasEnded=true`}>
      <EyeTwoTone style={{ fontSize: '16px' }} />
    </Link>
  ),
};

const dataColumns = [
  idColumn,
  distributionTimeColumn,
  paquerNameColumn,
  packagesAmountColumn,
  progressColumn,
  detailColumn,
];

const columnsSmallDevice = [...dataColumns];
const columnsMediumDevice = [...dataColumns];
const columnsLargeDevice = [...dataColumns];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const RoutesHistory = () => {
  useRoutes();
  usePaquers();
  const dispatch = useDispatch();
  const routes: Route[] = useSelector(routesHistorySelector);
  const paquers = useSelector(paquersItemsSelector);
  const error: null | AxiosError = useSelector(routesHistoryErrorSelector);
  const loaded: boolean = useSelector(routesHistoryLoadedSelector);
  const pageable: any = useSelector(routesHistoryPageableSelector);
  const [paquerList, setPaquerList] = useState([]);

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer: { id: any; name: any; lastName: any }) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const handleFetchRoutes = useCallback(() => {
    dispatch(actions.fetchRoutes());
  }, [dispatch]);

  const getResultStatus = (err: AxiosError<any>) => {
    if (!err.response?.status) return 'error';
    if (
      err.response.status === 404 ||
      err.response.status === 403 ||
      err.response.status === 500
    )
      return err.response.status;
    return 'error';
  };

  useEffect(() => {
    if (routes.length === 0) {
      handleFetchRoutes();
    }
  }, [dispatch, routes.length, handleFetchRoutes]);

  const handleChangePaginate = useCallback(
    (page: any) => {
      dispatch(actions.setRoutesPageable(page));
    },
    [dispatch],
  );

  const searchPaquerCallback = useCallback(
    (newPaquerId: string | number) => {
      dispatch(actions.updatePaquer(newPaquerId));
    },
    [dispatch],
  );

  const searchCallback = useCallback(
    (searchText: string) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );

  const selectors = [
    {
      onChange: searchPaquerCallback,
      placeholder: 'Paquer',
      list: paquerList,
    },
  ];

  const searcher = {
    placeholder: 'Nro. de ruta',
    onSearching: searchCallback,
    numeric: true,
    allowEmptySearch: true,
  };

  const extraButtons = [
    <Button type="primary" onClick={handleFetchRoutes}>
      Reintentar
    </Button>,
  ];

  return (
    <>
      <Spin indicator={antIcon} tip="Cargando rutas..." spinning={!loaded}>
        <Row gutter={[16, 36]}>
          <Col xs={24}>
            {error && (
              <Result
                status={getResultStatus(error)}
                title="Algo salio mal..."
                subTitle={error.message}
                extra={extraButtons}
              />
            )}
            {!error && (
              <PaqueryTable
                header={{
                  searcher,
                  selectors,
                  refresh: handleFetchRoutes,
                  title: 'Histórico',
                }}
                onChangePaginate={handleChangePaginate}
                loading={false}
                dataSource={routes}
                dataColumns={dataColumns}
                paginate={pageable}
                colsForSmallDevice={columnsSmallDevice}
                colsForMediumDevice={columnsMediumDevice}
                colsForLargeDevice={columnsLargeDevice}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default RoutesHistory;
