import React, { useCallback, useState, useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Col, Form, Input as InputAntd } from 'antd';
import { LoadingOutlined, UndoOutlined } from '@ant-design/icons';

const loadingIcon = <LoadingOutlined spin />;

interface Props {
  label: string;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  fieldName: string;
  type?: string;
  min?: number;
  max?: number;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  xs?: number | undefined;
  sm?: number | undefined;
  md?: number | undefined;
  lg?: number | undefined;
  xl?: number | undefined;
  xxl?: number | undefined;
  showReset?: boolean;
  password?: boolean;
  addonBefore?: React.ReactNode;
  loading?: boolean;
}

export const Input = ({
  fieldName = '',
  label,
  placeholder = 'Sin informacion',
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  showReset = true,
  password = false,
  addonBefore,
  disabled,
  loading,
  onChange,
  onBlur,
  type,
  min,
  max,
  labelStyle,
  containerStyle,
}: Props) => {
  const { values, initialValues, errors, setFieldValue, setFieldTouched } =
    useFormikContext<any>();
  const value =
    getIn(values, fieldName) instanceof Object ? '' : getIn(values, fieldName);
  const error =
    getIn(errors, fieldName) instanceof Object ? '' : getIn(errors, fieldName);
  const initialValue =
    getIn(initialValues, fieldName) instanceof Object
      ? ''
      : getIn(initialValues, fieldName);

  const hasChanged = value && value?.toString() !== initialValue?.toString();

  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, e.target.value);
      if (onBlur) {
        onBlur(e.target.value);
      }
    },
    [fieldName, setFieldTouched, setFieldValue, onBlur],
  );

  const handleRestart = useCallback(() => {
    setFieldValue(fieldName, initialValue);
    setFieldTouched(fieldName, false);
  }, [fieldName, initialValue, setFieldValue, setFieldTouched]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
      if (onChange) onChange(e);
    },
    [setInput, onChange],
  );

  const getValidateStatus = () => {
    let validateStatus:
      | ''
      | 'error'
      | 'warning'
      | 'success'
      | 'validating'
      | undefined;
    if (hasChanged && showReset) {
      validateStatus = 'warning';
    }
    if (error) {
      validateStatus = 'error';
    }
    return validateStatus;
  };

  const validateStatus = getValidateStatus();

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          ...containerStyle,
        }}
      >
        <span
          style={{
            ...labelStyle,
          }}
        >
          {label}
        </span>
        {hasChanged && showReset && (
          <div>
            <UndoOutlined onClick={handleRestart} />
          </div>
        )}
      </div>
      <Form.Item
        validateStatus={validateStatus}
        help={error}
        style={{ margin: 0 }}
      >
        {password ? (
          <InputAntd.Password
            style={{ marginTop: '6px' }}
            value={input}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            disabled={disabled}
            addonBefore={addonBefore}
            min={min}
            max={max}
          />
        ) : (
          <InputAntd
            addonAfter={loading && loadingIcon}
            disabled={disabled || loading}
            addonBefore={addonBefore}
            style={{ marginTop: '6px' }}
            value={input}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            type={type}
            min={min}
            max={max}
          />
        )}
      </Form.Item>
    </Col>
  );
};

Input.defaultProps = {
  labelStyle: {},
  containerStyle: {},
  type: 'text',
  min: 0,
  max: 100,
  onBlur: () => {},
  disabled: false,
  onChange: () => {},
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  xxl: undefined,
  showReset: true,
  password: false,
  addonBefore: null,
  loading: false,
  placeholder: 'Sin informacion',
};

export default Input;
