import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const packagesBySearchCodeSelector = (state) =>
  state[name] || initialState;

export const packagesBySearchCodeItemsSelector = (state) => state[name].items;

export const packagesBySearchCodePageableSelector = (state) =>
  state[name].pageable;

export const packagesBySearchCodeLoadedSelector = (state) => state[name].loaded;

export const packagesBySearchCodeSearchSelector = (state) => state[name].search;

const makePackagesBySearchCodeSelector = () =>
  createSelector(packagesBySearchCodeSelector, (substate) => substate);

export default makePackagesBySearchCodeSelector;
