/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useCallback, useState } from 'react';
import JsDownload from 'js-file-download';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/paquerView/slice';
import { selectGlobals } from 'redux/globals/selectors';
import {
  paquerviewPackagesItemsSelector,
  paquerviewPackagesSearchSelector,
  paquerviewPackagesLoadedSelector,
  paquerviewPackagesTypeSelector,
  paquerviewPackagesStatusSelector,
  paquerviewPackagesMarketSelector,
  paqueryviewPackagesDateSelector,
  paquerviewPackagesPageableSelector,
} from 'redux/paquerView/selectors';
import { notification, Spin } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import { isReadyForDeliveryStatus } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { LoadingOutlined } from '@ant-design/icons';
import ViewPackageButton from 'components/viewPackageButton';
import rest from 'util/Api';
import { paquersItemsSelector } from 'redux/paquerList/selectors';
import {
  marketplacesItemsSelector,
  marketplacesLoadedSelector,
} from 'redux/marketplaces/selectors';
import externalCodeColumn from 'components/tables/columns';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import useMarketplaces from 'redux/marketplaces';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const fetchCsv = async (
  search,
  paquerId,
  date,
  status,
  packageType,
  marketplace,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      paquerId,
      to: date.to,
      from: date.from,
      status,
      packageType: packageType || '',
      marketplaceId: marketplace || '',
    });
    const response = await rest.get(
      `${API.reports.packagesOwnedByPaquer}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const rolPosColumn = {
  title: 'Rol/Pos',
  dataIndex: 'rollContainerPosition',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  sorter: true,
  sortDirections: ['descend'],
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
  sorter: true,
  sortDirections: ['descend'],
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
  sorter: true,
  sortDirections: ['descend'],
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text[0]} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  scheduledDateColumn,
  arrivedAtPaqueryPointDateColumn,
  rolPosColumn,
  packageTypeColumn,
  sizeColumn,
  statusColumn,
];

const columnsLargeDevice = [...dataColumns, viewPackageColumn, editColumn];

const columnsSmallDevice = [externalCodeColumn, viewPackageColumn, editColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  statusColumn,
  viewPackageColumn,
  editColumn,
];

const pendingEntry = 1;
const arrivedToPaqueryPoint = 2;
const pendingSchedule = 5;
const onTransit = 4;
const delivered = 20;
const deliveredPendingSignature = 19;
const canceled = 21;
const joinedPaquery = 22;
const onTransitToPaqueryPoint = 23;
const avaiableOnStore = 24;
const returnProcess = 39;
const returned = 40;
const wrongAddress = 25;
const imposibleZoneDeliver = 26;
const deliverContingency = 27;
const pendingPayment = 30;
const expired = 50;
const wrecked = 28;

const unusedStatus = [
  pendingEntry,
  arrivedToPaqueryPoint,
  pendingSchedule,
  onTransit,
  delivered,
  deliveredPendingSignature,
  canceled,
  joinedPaquery,
  onTransitToPaqueryPoint,
  avaiableOnStore,
  returnProcess,
  returned,
  wrongAddress,
  imposibleZoneDeliver,
  deliverContingency,
  pendingPayment,
  expired,
  wrecked,
];

const filterStatus = (globals) => {
  if (!globals) return [];
  const { status: globalStatus } = globals.packages;
  return globalStatus.filter(
    (singleGlobal) => !unusedStatus.includes(singleGlobal.value),
  );
};

const PaquerPackages = ({ paquerId }) => {
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const search = useSelector(paquerviewPackagesSearchSelector);
  const globals = useSelector(selectGlobals);
  const paquers = useSelector(paquersItemsSelector);
  const items = useSelector(paquerviewPackagesItemsSelector);
  const pageable = useSelector(paquerviewPackagesPageableSelector);
  const loaded = useSelector(paquerviewPackagesLoadedSelector);
  const packageType = useSelector(paquerviewPackagesTypeSelector);
  const status = useSelector(paquerviewPackagesStatusSelector);
  const date = useSelector(paqueryviewPackagesDateSelector);
  const marketFilter = useSelector(paquerviewPackagesMarketSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesLoaded = useSelector(marketplacesLoadedSelector);
  const marketplacesOptions = marketplaces.map((market) => ({
    value: market.id,
    name: market.name,
  }));
  const filteredStatus = filterStatus(globals);
  const [paquerList, setPaquerList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearchPackages(searchText));
    },
    [dispatch],
  );
  const paginateCallback = useCallback(
    (page) => {
      dispatch(actions.updatePaginationPackages(page));
    },
    [dispatch],
  );

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const searcher = {
    key: 'searchByExternalCode',
    onSearching: searchCallback,
    placeholder: 'Código externo',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      defaultValue: status,
      list: filteredStatus,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  const handleCsv = async () => {
    setDisabled(true);
    try {
      const response = await fetchCsv(
        search,
        paquerId,
        date,
        status,
        packageType,
        marketFilter,
      );
      const paquerFullName =
        paquerId !== '' &&
        paquerList.find((paquer) => paquer.value === parseInt(paquerId, 10))
          .name;
      const filename = `Paquetes-${
        paquerFullName ? `${paquerFullName}` : 'Paquer'
      }-${dayjs().tz().format(ExportDateFormat)}.xls`;
      JsDownload(response, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };
  let filteredPackages;
  if (loaded) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      scheduledDate: packet.shippingScheduleDestination.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      rollContainerPosition: packet.rollContainerPosition,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === packet.packageType,
      ).name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      isDeliverable: isReadyForDeliveryStatus(packet.status),
    }));
  }
  return (
    <Spin
      spinning={!marketplacesLoaded}
      tip="Cargando los marketplaces.."
      indicator={antIcon}
    >
      <PaqueryTable
        loading={!loaded}
        header={{
          searcher,
          selectors,
          rangePicker,
          refresh: () => dispatch(actions.refreshPage()),
          onExportCsv: { callback: handleCsv, disabled },
        }}
        // onChange={handleTableChange}
        usePackageRowColors
        onChangePaginate={paginateCallback}
        dataSource={filteredPackages}
        paginate={pageable}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </Spin>
  );
};

PaquerPackages.propTypes = {
  paquerId: PropTypes.string.isRequired,
};

export default PaquerPackages;
