import React from 'react';
import { Flex } from 'antd';
import { PieChart as ReactPieChart } from 'react-minimal-pie-chart';

interface PieChartProps {
  data: any[];
  title: string;
}

const PieChart = ({ data, title }: PieChartProps) => {
  return (
    <Flex vertical gap={10} align="center">
      <ReactPieChart
        style={{ height: '12vh' }}
        animate
        paddingAngle={data.length > 1 ? 5 : 0}
        lineWidth={20}
        label={({ dataEntry }) => dataEntry.value}
        labelStyle={(id) =>
          data.length > 1
            ? {
                fill: data[id].color,
              }
            : {
                fontSize: '24px',
                fill: data[id].color,
              }
        }
        labelPosition={data.length > 1 ? 60 : 0}
        data={data}
      />
      <span style={{ color: 'gray' }}>{title}</span>
    </Flex>
  );
};

export default PieChart;
