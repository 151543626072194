export const ROLES = [
  {
    name: 'Administrador',
    value: 1,
  },
  {
    name: 'Cliente',
    value: 2,
  },
  {
    name: 'Administrador OPL',
    value: 3,
  },
  {
    name: 'Operador OPL',
    value: 4,
  },
  {
    name: 'Administrador PaQuery Point',
    value: 7,
  },
  {
    name: 'Operador PaQuery Point',
    value: 8,
  },
  {
    name: 'Administrador MP',
    value: 5,
  },
  {
    name: 'Operador MP',
    value: 6,
  },
  {
    name: 'Operador Tienda',
    value: 12,
  },
  {
    name: 'Administrador Tienda',
    value: 11,
  },
];

export const APP_ROLES = [
  7,
  8, // Operador Paqueryoint
];
