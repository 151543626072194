/* eslint-disable import/no-named-as-default-member */
import React, { useCallback, useState } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import dayjs from 'dayjs';
import { notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackageButton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { PACKAGE } from 'constants/packages';
import API from 'constants/api';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import ModalButton from 'components/modalButton';
import useMarketplaces from 'redux/marketplaces';
import useStore from 'redux/store/index';
import {
  useFinalStatusOptions,
  useMarketplaceOptions,
  useStoreOptions,
} from 'hooks/useOptions';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import usePackagesHistory from 'redux/packagesHistory';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  packagesHistorySearchSelector,
  packagesHistoryStatusSelector,
  packagesHistoryDateSelector,
  packagesHistoryLoadedSelector,
  packagesHistoryItemsSelector,
  packagesHistoryTypeSelector,
  packagesHistoryPaginationSelector,
  packagesHistoryMarketplaceSelector,
  packagesHistoryStoreSelector,
  packagesHistoryTermSelector,
} from 'redux/packagesHistory/selectors';
import { actions } from 'redux/packagesHistory/slice';
import { actions as storeActions } from 'redux/store/slice';

const statusStyle = (status) => {
  let bgColor;
  switch (status) {
    case PACKAGE.STATUSES.DELIVERED:
      bgColor = 'green';
      break;
    case PACKAGE.STATUSES.CANCELLED:
      bgColor = 'red';
      break;
    case PACKAGE.STATUSES.DAMAGED:
      bgColor = 'orange';
      break;
    case PACKAGE.STATUSES.RETURNED:
      bgColor = 'gold';
      break;
    default:
      break;
  }
  return {
    background: bgColor,
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
};

const statusColumn = {
  title: 'Estado',
  render: (_, record) => (
    <span style={statusStyle(record.status)}>{record.statusDescription}</span>
  ),
};

const driverAvatar = (record) => (
  <ModalButton
    name={record.driverName}
    image={record.driverImage}
    icon={<UserOutlined />}
  />
);

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const driverColumn = {
  title: 'PaQuer',
  align: 'center',
  render: (_, record) => driverAvatar(record),
};

const driverNameColumn = {
  title: 'Conductor',
  dataIndex: 'driverName',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  driverNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  driverColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  statusColumn,
  viewPackageColumn,
];

const columnsSmallDevice = [externalCodeColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
];

const PackageHistory = () => {
  usePackagesHistory();
  useMarketplaces({ initialize: true });
  useStore();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const globals = useSelector(selectGlobals);
  const search = useSelector(packagesHistorySearchSelector);
  const status = useSelector(packagesHistoryStatusSelector);
  const marketplaceFilter = useSelector(packagesHistoryMarketplaceSelector);
  const storeFilter = useSelector(packagesHistoryStoreSelector);
  const deliveryTerm = useSelector(packagesHistoryTermSelector);
  const packageType = useSelector(packagesHistoryTypeSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const stores = useSelector(storeItemsSelector);
  const storesOptions = useStoreOptions(stores);
  const date = useSelector(packagesHistoryDateSelector);
  const loaded = useSelector(packagesHistoryLoadedSelector);
  const items = useSelector(packagesHistoryItemsSelector);
  const pageable = useSelector(packagesHistoryPaginationSelector);
  const filteredFinalStatuses = useFinalStatusOptions();

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const searchParams = new URLSearchParams({
        search,
        status,
        marketplaceId: marketplaceFilter || '',
        storeId: storeFilter || '',
        packageType,
        deliveryTerm,
        paquerView: false,
        from: date.from,
        to: date.to,
      });
      const response = await rest.get(
        `${API.reports.history}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const filename = `Paquetes-Historial-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(response.data, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDate(newDate));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatus(newStatus));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const selectMarketplaceCallback = useCallback(
    (newMarketplace) => {
      dispatch(actions.updateMarketplace(newMarketplace));
      if (newMarketplace) dispatch(storeActions.all(newMarketplace));
    },
    [dispatch],
  );
  const selectStoreCallback = useCallback(
    (storeSelected) => {
      dispatch(actions.updateStore(storeSelected));
    },
    [dispatch],
  );
  const selectDeliveryTermCallback = useCallback(
    (newDeliveryTerm) => {
      dispatch(actions.updateDeliveryTerm(newDeliveryTerm));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Marketplace',
      list: marketplacesOptions,
    },
    {
      onChange: selectStoreCallback,
      placeholder: 'Tienda',
      list: storesOptions,
    },
    {
      onChange: selectPackageTypeCallback,
      list: globals.packages.type,
      placeholder: 'Tipo de envio',
    },
    {
      list: filteredFinalStatuses,
      onChange: selectStatusCallback,
      placeholder: 'Estado',
    },
    {
      onChange: selectDeliveryTermCallback,
      placeholder: 'Plazo',
      list: globals.deliveryTerm,
    },
  ];
  const rangePicker = {
    onDateSelection: dateSelectionCallback,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };
  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      content: packet.caption,
      driverImage: packet.shippingScheduleDestination.driver
        ? packet.shippingScheduleDestination.driver.avatar
        : null,
      driverName: packet.shippingScheduleDestination.driver
        ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName}`
        : null,
      deliveryDate: packet.deliveryDate
        ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      signatureImage: packet.signatureImage,
      signatureName: packet.shippingScheduleDestination.name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        selectors,
        rangePicker,
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
        title: 'Histórico',
      }}
      onChangePaginate={(newPagination) =>
        dispatch(actions.updatePagination(newPagination))
      }
      usePackageRowColors
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default PackageHistory;
