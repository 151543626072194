import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  marketplaceId: '',
  storeId: '',
  status: '',
  search: '',
  type: '',
  term: '',
  date: {
    from: '',
    to: '',
  },
};

const packagesHistorySlice = createSlice({
  name: 'packages-history',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
    },
    failure: (state) => {
      state.loaded = true;
    },
    updatePagination: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePackageType: (state, action) => {
      state.type = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateDeliveryTerm: (state, action) => {
      state.pageable.pageNumber = 0;
      state.term = action.payload;
    },
    updateMarketplace: (state, action) => {
      state.marketplaceId = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateStore: (state, action) => {
      state.pageable.pageNumber = 0;
      state.storeId = action.payload;
    },
    updatePacket: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.packageId,
      );
      if (index !== -1) state[index] = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = packagesHistorySlice;
