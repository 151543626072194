/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card } from 'antd';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import PackageMarker from '../../route/components/map/components/PackageMarker';

const Map = ({ packages, center }) => {
  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng, map]);
    return null;
  };

  return (
    <Card style={{ overflow: 'hidden' }} bodyStyle={{ padding: 0 }}>
      <MapContainer
        style={{ height: '48.4vh', width: '100%' }}
        center={center}
        zoom={15}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {packages &&
          packages
            .filter((packet) => packet.geoLat && packet.geoLng)
            .map((packet) => (
              <PackageMarker
                showStatus={false}
                key={packet.id}
                packet={packet}
              />
            ))}
        <FullscreenControl />
        <RecenterAutomatically lat={center[0]} lng={center[1]} />
      </MapContainer>
    </Card>
  );
};

export default Map;
