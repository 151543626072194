/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal, notification, theme, Tooltip, Spin } from 'antd';
import {
  UserDeleteOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'util/Hooks';
import API from 'constants/api';
import rest from 'util/Api';
import { actions } from 'redux/paquers/slice';
import PackageService from 'services/PackageService';
import usePaquers from 'redux/paquers';

const WAIT_QR_SCAN = 600;

const UnassignPaquerButton = ({ packet, size, onFinish }) => {
  usePaquers();
  const dispatch = useDispatch();
  const [unassignExternalCode, setUnassignExternalCode] = useState('');
  const bundleExternals = useRef(null);
  const hasBundles = useRef(null);
  const [pendingScan, setPendingScan] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const debouncedUnassignExternalCode = useDebounce(
    unassignExternalCode,
    WAIT_QR_SCAN,
  );
  const { token } = theme.useToken();

  useEffect(() => {
    if (modalVisible) {
      setLoading(true);
      PackageService.getBundlesById(packet.id)
        .then((res) => {
          const bundles = res.data;
          const externals = bundles.map((bundle) => bundle.code);
          bundleExternals.current = externals;
          hasBundles.current = externals && externals.length > 0;
          setPendingScan(externals);
        })
        .catch((error) => {
          if (error.isAxiosError) {
            notification.error({
              message: 'Ocurrio un problema',
              description: `Ha ocurrido al liberar al paquer ${
                error.message ? `: ${error.message}` : '.'
              }`,
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [packet, modalVisible]);

  useEffect(() => {
    const unassingPacket = async (id) => {
      try {
        const payload = {
          id: parseInt(id, 10),
        };
        await rest.put(`${API.paquer.unassign}${id}`, payload);
        notification.success({
          message: 'Paquer liberado correctamente',
        });
        dispatch(actions.removePacket(id));
      } catch (error) {
        notification.error({
          message: 'Ocurrio un problema',
          description: `Ha ocurrido al liberar al paquer ${
            error.message ? `: ${error.message}` : '.'
          }`,
        });
      } finally {
        setModalVisible(false);
        if (onFinish) onFinish();
      }
    };

    setUnassignExternalCode(null);
    if (!debouncedUnassignExternalCode || debouncedUnassignExternalCode === '')
      return;

    try {
      const objectCode = JSON.parse(debouncedUnassignExternalCode);
      if (objectCode.id) return setUnassignExternalCode(() => objectCode.id);
    } catch (e) {
      // do nothing
    }

    if (!hasBundles.current) {
      if (debouncedUnassignExternalCode !== packet.externalCode)
        return notification.error({
          message: 'Ocurrio un problema',
          description: `El código externo no corresponde al paquete a liberar.`,
        });
      unassingPacket(packet.id);
      return;
    }

    if (!bundleExternals.current.includes(debouncedUnassignExternalCode))
      return notification.error({
        message: 'Ocurrio un problema',
        description: `El código externo no corresponde al paquete a liberar.`,
      });

    if (!pendingScan.includes(debouncedUnassignExternalCode))
      return notification.error({
        message: 'Ocurrio un problema',
        description: `El código externo del bulto ya ha sido leido.`,
      });

    const nextPending = pendingScan.filter(
      (code) => code !== debouncedUnassignExternalCode,
    );
    if (nextPending.length === 0) {
      unassingPacket(packet.id);
      return;
    }
    setPendingScan(nextPending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUnassignExternalCode]);

  const modalText = hasBundles.current ? (
    <>
      <p>
        Este es un paquete multibulto. Para liberar al paquer del paquete
        {` ${packet.externalCode}`} es necesario{' '}
        <b>escanear el código de barras</b> de cada uno de sus bultos.
      </p>
      <ul style={{ paddingLeft: '5%', listStyle: 'none' }}>
        {bundleExternals.current.map((code) => (
          <li
            key={code.substr(code.length - 3)}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            {pendingScan.includes(code) ? (
              <MinusCircleOutlined
                style={{
                  color: 'lightgrey',
                  fontSize: '1rem',
                  marginRight: '2%',
                }}
              />
            ) : (
              <CheckCircleOutlined
                style={{
                  color: 'green',
                  fontSize: '1rem',
                  marginRight: '2%',
                }}
              />
            )}
            <span>{`${code}`}</span>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <p>
      Para liberar al paquer del paquete {packet.externalCode} es necesario{' '}
      <b>escanear el código de barras</b>.
    </p>
  );

  return (
    <>
      <Modal
        closable
        destroyOnClose
        maskClosable
        open={modalVisible}
        title={
          <div style={{ display: 'flex', gap: '3%' }}>
            <UserDeleteOutlined style={{ paddingTop: '1%' }} />
            <p style={{ marginBottom: 0 }}>
              ¿Estas seguro que deseas liberar al paquer?
            </p>
          </div>
        }
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <Spin spinning={loading}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '0 1% 1% 1%',
            }}
          >
            {modalText}
            <Input
              value={unassignExternalCode}
              autoFocus
              style={{ textAlign: 'center' }}
              onChange={(e) => {
                setUnassignExternalCode(e.target.value);
              }}
            />
          </div>
        </Spin>
      </Modal>
      {size === 'small' ? (
        <Tooltip placement="top" title="Liberar paquer">
          <UserDeleteOutlined
            style={{
              color: token.colorPrimary,
            }}
            onClick={() => setModalVisible(true)}
          />
        </Tooltip>
      ) : (
        <Button onClick={() => setModalVisible(true)} type="primary" danger>
          Liberar paquer
        </Button>
      )}
    </>
  );
};

UnassignPaquerButton.propTypes = {
  packet: PropTypes.shape({
    externalCode: PropTypes.string,
    id: PropTypes.number,
  }),
  size: PropTypes.string,
  onFinish: PropTypes.func,
};

UnassignPaquerButton.defaultProps = {
  packet: {},
  size: 'large',
  onFinish: null,
};

export default UnassignPaquerButton;
