/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useCallback, useState } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import dayjs from 'dayjs';
import { notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackageButton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import usePackagesWithPaquersHistorical from 'redux/packagesWithPaquersHistorical';
import ModalButton from 'components/modalButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  packagePaquersHistoryDateSelector,
  packagePaquersHistoryLoadedSelector,
  packagePaquersHistoryPaquerSelector,
  packagePaquersHistoryItemsSelector,
  packagePaquersHistoryPaginationSelector,
  packagePaquersHistoryTypeSelector,
  packagePaquersHistoryStatusSelector,
} from 'redux/packagesWithPaquersHistorical/selectors';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import { actions } from 'redux/packagesWithPaquersHistorical/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import usePaquers from 'redux/paquers';
import { useFinalStatusOptions } from 'hooks/useOptions';

const statusStyle = (statusText) => {
  let style = {
    background: 'gray',
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };

  switch (statusText) {
    case 'Entregado':
      style.background = 'green';
      break;
    case 'Cancelado':
      style.background = 'red';
      break;
    case 'Devuelto':
      style.background = 'gold';
      break;
    default:
      style = {};
      break;
  }
  return style;
};

const driverAvatar = (record) => (
  <ModalButton
    name={record.driverName}
    image={record.driverImage}
    icon={<UserOutlined />}
  />
);

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const driverColumn = {
  title: 'PaQuer',
  align: 'center',
  render: (_, record) => driverAvatar(record),
};

const driverNameColumn = {
  title: 'Conductor',
  dataIndex: 'driverName',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  render: (text) => <span style={statusStyle(text)}>{text}</span>,
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packageId={record.id} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  driverNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  driverColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  statusColumn,
  viewPackageColumn,
];

const columnsSmallDevice = [externalCodeColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
];

const getAllPaquersForCsvUrl = (date, packageType, status) => {
  const searchParams = new URLSearchParams({
    search: '',
    from: date.from,
    to: date.to,
    marketplaceId: '',
    packageType,
    status,
    paquerView: true,
  });
  return `${API.reports.history}?${searchParams.toString()}`;
};

const getPaquerByIdCsvUrl = (date, paquerId) => {
  const searchParams = new URLSearchParams({
    search: '',
    paquerId,
    to: date.to,
    from: date.from,
    status: '',
    packageType: '',
  });
  return `${API.reports.historyByPaquer}?${searchParams.toString()}`;
};

const getCsvFilename = (paquerFullName) =>
  `Paquetes-Historial-${paquerFullName ? `${paquerFullName}-` : ''}${dayjs()
    .tz()
    .format(ExportDateFormat)}.xls`;

const PackageHistory = () => {
  usePackagesWithPaquersHistorical();
  usePaquers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const paquers = useSelector(paquersItemsSelector);
  const [paquerList, setPaquerList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const currentPaquer = useSelector(packagePaquersHistoryPaquerSelector);
  const date = useSelector(packagePaquersHistoryDateSelector);
  const loaded = useSelector(packagePaquersHistoryLoadedSelector);
  const status = useSelector(packagePaquersHistoryStatusSelector);
  const packageType = useSelector(packagePaquersHistoryTypeSelector);
  const items = useSelector(packagePaquersHistoryItemsSelector);
  const pageable = useSelector(packagePaquersHistoryPaginationSelector);
  const filteredFinalStatuses = useFinalStatusOptions();

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      if (!haveDateLoaded(date) || currentPaquer === null) {
        notification.warning({
          message: 'Es necesario indicar una fecha y algun paquer',
          description:
            'Para poder descargar el CSV es necesario cargar estos datos',
        });
        return;
      }
      let URL;
      let filename;
      if (currentPaquer === '') {
        URL = getAllPaquersForCsvUrl(date, packageType, status);
        filename = getCsvFilename();
      } else {
        const paquerFullName =
          currentPaquer !== '' &&
          paquerList.find((paquer) => paquer.value === currentPaquer).name;
        filename = getCsvFilename(paquerFullName);
        URL = getPaquerByIdCsvUrl(date, currentPaquer);
      }
      const response = await rest.get(URL, {
        responseType: 'blob',
      });
      JsDownload(response.data, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al exportar el archivo${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDate(newDate));
    },
    [dispatch],
  );
  const selectPaquerCallback = useCallback(
    (newPaquer) => {
      dispatch(actions.updatePaquer(newPaquer));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (newStatus) => {
      dispatch(actions.updateStatus(newStatus));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      list: paquerList,
      onChange: selectPaquerCallback,
      placeholder: 'Paquer',
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      list: filteredFinalStatuses,
      onChange: selectStatusCallback,
      placeholder: 'Estado',
    },
  ];
  const rangePicker = {
    onDateSelection: dateSelectionCallback,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };
  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      content: packet.caption,
      driverImage: packet.shippingScheduleDestination.driver
        ? packet.shippingScheduleDestination.driver.avatar
        : null,
      driverName: packet.shippingScheduleDestination.driver
        ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName}`
        : null,
      deliveryDate: packet.deliveryDate
        ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      signatureImage: packet.signatureImage,
      signatureName: packet.shippingScheduleDestination.name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searcher,
        selectors,
        rangePicker,
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
        title: 'Histórico por paquer',
      }}
      onChangePaginate={(newPagination) =>
        dispatch(actions.updatePagination(newPagination))
      }
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
      usePackageRowColors
    />
  );
};

export default PackageHistory;
