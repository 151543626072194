import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, shallowEqual } from 'react-redux';
import { reducer, name } from './slice';
import makeUserView from './selectors';
import saga from './saga';

export default function useUserView() {
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makeUserView(), shallowEqual);
  return null;
}
