import {
  isFinalStatus,
  isOnTransitStatus,
  isWithPaquersStatus,
} from 'constants/packages';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { packagesStatusesGlobalsSelector } from 'redux/globals/selectors';

export function useMarketplaceOptions(marketplaceList) {
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  useEffect(() => {
    if (marketplaceList && marketplaceList.length > 0) {
      const options = marketplaceList.map((market) => ({
        value: market.id,
        name: market.name,
      }));
      setMarketplaceOptions(options);
    }
  }, [marketplaceList]);
  return marketplaceOptions;
}

export function useStoreOptions(storeList) {
  const [storeOptions, setStoreOptions] = useState([]);
  useEffect(() => {
    if (storeList && storeList.length > 0) {
      const options = storeList.map((store) => ({
        value: store.id,
        name: store.name,
      }));
      setStoreOptions(options);
    }
  }, [storeList]);
  return storeOptions;
}

export function useFinalStatusOptions() {
  const packageStatuses = useSelector(packagesStatusesGlobalsSelector);
  const filteredFinalStatuses = useMemo(
    () =>
      packageStatuses.filter((packageStatus) =>
        isFinalStatus(packageStatus.value),
      ),
    [packageStatuses],
  );
  return filteredFinalStatuses;
}

export function useOnTransitStatusOptions() {
  const packageStatuses = useSelector(packagesStatusesGlobalsSelector);
  const filteredOnTransitStatuses = useMemo(
    () =>
      packageStatuses.filter((packageStatus) =>
        isOnTransitStatus(packageStatus.value),
      ),
    [packageStatuses],
  );
  return filteredOnTransitStatuses;
}

export function useWithPaquersStatusOptions() {
  const packageStatuses = useSelector(packagesStatusesGlobalsSelector);
  const filteredWithPaquersStatuses = useMemo(
    () =>
      packageStatuses.filter((packageStatus) =>
        isWithPaquersStatus(packageStatus.value),
      ),
    [packageStatuses],
  );
  return filteredWithPaquersStatuses;
}
