import { DEPARTURE_PACKAGE_STATUSES } from 'constants/packages';
import API from 'constants/api';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { departurePackageStatusSelector } from './selectors';
import { actions } from './slice';

function* fetchSaga({ payload: { departure } }) {
  try {
    const searchParams = new URLSearchParams({
      page: 0,
      take: 10000,
      size: 10000,
    });
    const currentDeparturePackageStatus = yield select(
      departurePackageStatusSelector,
    );
    let payload;
    let URL;
    let packages;
    switch (currentDeparturePackageStatus) {
      case DEPARTURE_PACKAGE_STATUSES.NOTARRIVED:
        URL = `${API.departures.getPackagesNotArrivedByCode(
          departure.code,
        )}&${searchParams.toString()}`;
        packages = yield call(getData, URL);
        payload = {
          items: packages,
          totals: packages.length,
        };
        break;
      case DEPARTURE_PACKAGE_STATUSES.ALL:
      default:
        URL = `${API.departures.getById(
          departure.id,
        )}?${searchParams.toString()}`;
        packages = yield call(getData, URL);
        payload = {
          items: packages.content,
          totals: packages.numberOfElements,
        };
        break;
    }
    yield put({ type: actions.success.type, payload });
  } catch (error) {
    console.log('error fetching departure history: ', error.message);
    yield put({ type: actions.failure.type });
  }
}

export default function* rootSaga() {
  yield takeEvery(
    [actions.fetch, actions.initial, actions.setDeparturePackageStatus],
    fetchSaga,
  );
}
