/* eslint-disable no-case-declarations */
import React, { useCallback, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import PaqueryTable from '@paquery-team/lib-table';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import {
  DEPARTURE_TYPE_OPTIONS,
  INTERIOR_DESTINATION_OPTIONS,
} from 'constants/options';
import { TableDateTimeFormat } from 'constants/dateFormats';
import usePaquerypoints from 'redux/paquerypoint';
import {
  paquerypointsLoadedSelector,
  paquerypointsObjectItemSelector,
} from 'redux/paquerypoint/selectors';
import {
  marketplacesLoadedSelector,
  marketplacesObjectItemSelector,
} from 'redux/marketplaces/selectors';
import { dispatchTypesGlobalsSelector } from 'redux/globals/selectors';
import useDepartureHistorical from 'redux/packageDepartureHistory';
import useMarketplaces from 'redux/marketplaces';
import { actions } from 'redux/packageDepartureHistory/slice';
import {
  itemsSelector,
  loadedSelector,
  departureTypeSelector,
  destinationOptionsSelector,
  pageableSelector,
} from 'redux/packageDepartureHistory/selectors';
import PackagesList from './components/packagesList';
import ReceiptDownload from './components/receiptDownload';

const idColumn = {
  title: 'Codigo',
  dataIndex: 'code',
  width: 150,
};

const originColumn = {
  title: 'Origen',
  dataIndex: 'originName',
};

const destinationColumn = {
  title: 'Destino',
  dataIndex: 'destinationName',
};

const dispatchTypeColumn = {
  title: 'Tipo de despacho',
  dataIndex: 'dispatchType',
  align: 'center',
};

const dateColumn = {
  title: 'Fecha de despacho',
  dataIndex: 'dateColumn',
  align: 'center',
};

const packagesColumn = {
  title: 'Paquetes',
  align: 'center',
  render: (_, departure) => <PackagesList departure={departure} />,
};

const receiptColumn = {
  title: 'Remito',
  align: 'center',
  render: (_, departure) => <ReceiptDownload departure={departure} />,
};

const dataColumns = [
  idColumn,
  originColumn,
  destinationColumn,
  dispatchTypeColumn,
  dateColumn,
  packagesColumn,
  receiptColumn,
];

const columnsSmallDevice = [...dataColumns];

const columnsMediumDevice = [...dataColumns];

const columnsLargeDevice = [...dataColumns];

const useDatasource = (loaded) => {
  const items = useSelector(itemsSelector);
  const [datasource, setDatasource] = useState([]);
  const paquerypointsSource = useSelector(paquerypointsObjectItemSelector);
  const marketplacesSource = useSelector(marketplacesObjectItemSelector);
  const departureType = useSelector(departureTypeSelector);
  const departureTypes = useSelector(dispatchTypesGlobalsSelector);
  useEffect(() => {
    if (loaded) {
      const paquerypoints = Object.values(paquerypointsSource);
      const marketplaces = Object.values(marketplacesSource);
      setDatasource(
        items.map((item) => {
          let destinationName = 'Desconocido';
          switch (item.destinationType) {
            case DEPARTURE_TYPE_OPTIONS.INTERIOR:
              const interiorDestination = INTERIOR_DESTINATION_OPTIONS.find(
                (destination) => destination.id === item.destinationId,
              );
              if (interiorDestination) {
                destinationName = interiorDestination.name;
              }
              break;
            case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
              destinationName =
                paquerypoints.find(
                  (paquerypoint) => paquerypoint.id === item.destinationId,
                )?.name ?? item.destinationDetail;
              break;
            case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
              const returningDestination = marketplaces.find(
                (market) => market.id === item.destinationId,
              );
              if (returningDestination) {
                destinationName = returningDestination.name;
              }
              break;
            default:
              break;
          }

          return {
            ...item,
            originName:
              paquerypoints.find(
                (paquerypoint) => paquerypoint.id === item.originId,
              )?.name ?? item.originDetail,
            destinationName,
            dispatchType: departureTypes.find(
              (departure) => departure.value === item.destinationType,
            ).name,
            dateColumn: dayjs(item.dispatchedDate)
              .tz()
              .format(TableDateTimeFormat),
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, paquerypointsSource, marketplacesSource, loaded, departureType]);
  return datasource;
};

const mapPaquerypointCallback = (paquerypoint) => ({
  name: paquerypoint.name,
  value: paquerypoint.id,
});

const mapDepartureTypeCallback = (departureType) => ({
  name: departureType.name,
  value: departureType.value,
});

const useOptions = (items, mapCallback) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const itemsArray = Object.values(items);
    if (itemsArray?.length > 0) {
      setOptions(itemsArray.map(mapCallback));
    }
  }, [items, mapCallback]);
  return options;
};

const DeparturePaqueryPointHistory = () => {
  useDepartureHistorical();
  usePaquerypoints({ initialize: true });
  useMarketplaces({ initialize: true });
  const dispatch = useDispatch();
  const historyLoaded = useSelector(loadedSelector);
  const paquerypointLoaded = useSelector(paquerypointsLoadedSelector);
  const marketplacesLoaded = useSelector(marketplacesLoadedSelector);
  const loaded = historyLoaded && paquerypointLoaded && marketplacesLoaded;
  const pageable = useSelector(pageableSelector);
  const paquerypoints = useSelector(paquerypointsObjectItemSelector);
  const departureTypes = useSelector(dispatchTypesGlobalsSelector);
  const destinationOptions = useSelector(destinationOptionsSelector);
  const paquerypointOptions = useOptions(
    paquerypoints,
    mapPaquerypointCallback,
  );
  const departureTypeOptions = useOptions(
    departureTypes,
    mapDepartureTypeCallback,
  );
  const datasource = useDatasource(loaded);

  const searchByExternalCodeCallback = useCallback(
    (value) => {
      dispatch(actions.setSearchByExternalCode(value));
    },
    [dispatch],
  );

  const searchByOriginCallback = useCallback(
    (originCriteria) => {
      dispatch(actions.setOrigin(originCriteria));
    },
    [dispatch],
  );

  const searchByDestinationCallback = useCallback(
    (destinationCriteria) => {
      dispatch(actions.setDestination(destinationCriteria));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.setPageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.setDate(value));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchByExternalCodeCallback,
    placeholder: 'Código de despacho',
    allowEmptySearch: true,
  };

  const selectDepartureTypeCallback = useCallback(
    (selectedDepartureType) => {
      dispatch(actions.setDepartureType(selectedDepartureType));
    },
    [dispatch],
  );

  const selectors = [
    {
      onChange: selectDepartureTypeCallback,
      placeholder: 'Tipo de despacho',
      list: departureTypeOptions,
    },
    {
      onChange: searchByOriginCallback,
      placeholder: 'Origen',
      list: paquerypointOptions,
    },
    {
      onChange: searchByDestinationCallback,
      placeholder: 'Destino',
      list: destinationOptions,
    },
  ];

  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        selectors,
        searcher,
        rangePicker,
        refresh: () => dispatch(actions.refreshPage()),
        title: 'Transferir o devolver paquetes - Histórico',
      }}
      onChangePaginate={updatePaginate}
      dataSource={datasource}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default DeparturePaqueryPointHistory;
