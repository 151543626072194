import { createSelector } from 'reselect';
import { PACKAGE, isVisitStatus } from 'constants/packages';
import { name, initialState } from './slice';

export const paquersSelector = (state) => state[name] || initialState;

export const paquersItemsSelector = (state) => state[name].items;

export const paquersLoadedSelector = (state) => state[name].loaded;

export const paquersPaquerIdSelector = (state) => state[name].paquerId;

export const paquersPackagesItems = (state) => state[name].packages.items;

export const paquersPackagesPageable = (state) => state[name].packages.pageable;

export const paquersPackagesLoaded = (state) => state[name].packages.loaded;

export const paquersPackagesSearch = (state) => state[name].packages.search;

export const paquersPackagesTotalizerSelector = (state) =>
  state[name].packages.totalizers;

const makePaquerSelector = () =>
  createSelector(paquersSelector, (substate) => substate);

export const paquersCountedTotalizersSelector = createSelector(
  paquersPackagesItems,
  (items) => {
    const itemsArray = Object.values(items);
    const aceptedByPaquer = itemsArray.filter(
      (packet) => packet.status === PACKAGE.STATUSES.ACCEPTEDBYPAQUER,
    ).length;
    const ownedByPaquer = itemsArray.filter(
      (packet) => packet.status === PACKAGE.STATUSES.INHANDSOFPAQUER,
    ).length;
    const visitAttempts = itemsArray.filter((packet) =>
      isVisitStatus(packet.status),
    ).length;
    return {
      aceptedByPaquer,
      ownedByPaquer,
      visitAttempts,
    };
  },
);

const moneySumCallback = (total, currentPackage) =>
  currentPackage.estimatedCost ? total + currentPackage.estimatedCost : total;

export const paquersPackagesMoneyAmmount = createSelector(
  paquersPackagesItems,
  (packagesObject) => {
    const packages = Object.values(packagesObject);
    if (!packages || packages.length === 0) {
      return 0;
    }
    return packages.reduce(moneySumCallback, 0);
  },
);

const paquersPackagesFilterItems = createSelector(
  paquersPackagesItems,
  paquersPackagesSearch,
  (items, search) => {
    if (!items) return [];
    const itemsArray = Object.values(items);
    let resultItems = itemsArray;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase().trim();
        const packageCodeIgnoreCase = item.externalCode.toUpperCase();
        return packageCodeIgnoreCase.includes(searchIgnoreCase);
      });
    }
    return resultItems;
  },
);

export const paquersPackageFilteredPagedItems = createSelector(
  paquersPackagesFilterItems,
  paquersPackagesPageable,
  (filteredItems, { pageNumber, pageSize }) => {
    let resultItems = filteredItems;
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = [...resultItems].slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makePaquerSelector;
