import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: {},
  loaded: false,
};

const marketplaceSlice = createSlice({
  name: 'marketplaces',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      action.payload.forEach((marketplace) => {
        state.items[marketplace.id] = marketplace;
      });
    },
    failure: (state) => {
      state.loaded = false;
    },
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = marketplaceSlice;
