import Api from 'util/Api';
import API from 'constants/api';
import { Paquer } from 'interfaces/paquer';

export const geyById = async (id: string | number | null | undefined) => {
  const response = await Api.apiAxios.get(`${API.paquer.getById}${id}`);
  const routes: Paquer = response.data.data;

  return routes;
};

const PaquerService = {
  geyById,
};

export default PaquerService;
