/* eslint-disable react/prop-types */
import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { PACKAGE, isVisitStatus } from 'constants/packages';
import { usePackageStatus } from '@paquery-team/lib-paquery-language/globals';
import {
  IconMarkerGray,
  IconMarkerGreen,
  IconMarkerOrange,
  IconMarkerRed,
} from './icons';

const getIconByStatus = (package_) => {
  if (package_.packageStatus === PACKAGE.STATUSES.DELIVERED)
    return IconMarkerGreen;
  if (isVisitStatus(package_.packageStatus)) {
    return IconMarkerOrange;
  }
  if (package_.packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER)
    return IconMarkerGray;
  return IconMarkerRed;
};

const PackageMarker = ({ packet, showStatus = true }) => {
  const icon = getIconByStatus(packet);
  const statuses = usePackageStatus();

  const status =
    statuses.find((s) => s.value === packet.packageStatus)?.name ||
    packet.packageStatus;

  const position =
    packet.geoLat && packet.geoLng ? [packet.geoLat, packet.geoLng] : [0, 0];

  return (
    <Marker key={packet.id} position={position} icon={icon}>
      <Popup>
        <div>
          <h3>
            {showStatus
              ? `${packet.externalCode} - ${status}`
              : packet.externalCode}
          </h3>
          <p>{packet.geoAddress}</p>
        </div>
      </Popup>
    </Marker>
  );
};

export default PackageMarker;
