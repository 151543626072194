import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { DEPARTURE_TYPE_OPTIONS } from 'constants/options';
import API from 'constants/api';
import { getData } from 'util/Api';
import {
  crossdockingDepartureTypeSelector,
  crossdockingDepartureRemovePacketIDSelector,
  crossdockingDepartureItemsSelector,
  crossdockingDepartureOperatorsSelector,
} from './selectors';
import { name, actions } from './slice';
import { actions as paqueryPointDepartureActions } from '../packagePaquerypointDeparture/slice';
import { actions as refundDepartureActions } from '../packageRefundDeparture/slice';

function* initialSaga() {
  const items = yield select(crossdockingDepartureItemsSelector);
  const operators = yield select(crossdockingDepartureOperatorsSelector);
  if (!items || items.length === 0) {
    const storageItems = localStorage.getItem(name);
    if (storageItems) {
      yield put({
        type: actions.success.type,
        payload: JSON.parse(storageItems),
      });
    }
  }
  if (!operators || operators.length === 0) {
    yield put({ type: actions.fetchOperators.type });
  }
}

function* fetchOperatorsSaga() {
  try {
    const operators = yield call(getData, `${API.operators.getAll}`);
    yield put({ type: actions.operatorsSuccess.type, payload: operators });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* updateStoragePackagesSaga() {
  const items = yield select(crossdockingDepartureItemsSelector);
  localStorage.setItem(name, JSON.stringify(items));
}

function* removePacketSaga() {
  const departureType = yield select(crossdockingDepartureTypeSelector);
  const packetID = yield select(crossdockingDepartureRemovePacketIDSelector);
  switch (departureType) {
    case DEPARTURE_TYPE_OPTIONS.INTERIOR:
      yield put({
        type: actions.removePacketSuccess.type,
        payload: packetID,
      });
      break;
    case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
      yield put({
        type: paqueryPointDepartureActions.removePacketSuccess.type,
        payload: packetID,
      });
      break;
    case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
      yield put({
        type: refundDepartureActions.removePacketSuccess.type,
        payload: packetID,
      });
      break;
    default:
      break;
  }
  yield put({
    type: actions.resetRemovePacketID.type,
  });
}

function* dispatchSuccessSaga({ payload }) {
  const packageIds = payload;
  const items = yield select(crossdockingDepartureItemsSelector);
  const filteredPackages = items.filter(
    (packet) => !packageIds.includes(packet.id),
  );
  localStorage.removeItem(name);
  yield put({ type: actions.reset.type });
  if (filteredPackages.length) {
    yield put({ type: actions.updatePackets.type, payload: filteredPackages });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.dispatchPackagesSuccess, dispatchSuccessSaga),
    takeEvery(actions.fetchOperators, fetchOperatorsSaga),
    takeEvery(actions.removePacket, removePacketSaga),
    takeEvery(actions.removePacketSuccess, updateStoragePackagesSaga),
    takeEvery(actions.addPackage, updateStoragePackagesSaga),
  ]);
}
