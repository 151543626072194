import L from 'leaflet';
import IconRed from '../assets/map-marker-red.svg';
import IconOrange from '../assets/map-marker-orange.svg';
import IconGreen from '../assets/map-marker-green.svg';
import IconGray from '../assets/map-marker-gray.svg';
import PpointIcon from '../assets/ppoint.svg';

export const IconMarkerGreen = L.icon({
  iconUrl: IconGreen,
  iconRetinaUrl: IconGreen,
  iconAnchor: L.point(15, 38),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [60, 50],
  className: 'Leaflet-venue-icon',
});
export const IconMarkerGray = L.icon({
  iconUrl: IconGray,
  iconRetinaUrl: IconGray,
  iconAnchor: L.point(15, 38),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [60, 50],
  className: 'Leaflet-venue-icon',
});
export const IconMarkerOrange = L.icon({
  iconUrl: IconOrange,
  iconRetinaUrl: IconOrange,
  iconAnchor: L.point(15, 38),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [60, 50],
  className: 'Leaflet-venue-icon',
});
export const IconMarkerRed = L.icon({
  iconUrl: IconRed,
  iconRetinaUrl: IconRed,
  iconAnchor: L.point(15, 38),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [60, 50],
  className: 'Leaflet-venue-icon',
});
export const PaqueryPointIconMarker = L.icon({
  iconUrl: PpointIcon,
  iconRetinaUrl: PpointIcon,
  iconAnchor: L.point(20, 25),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [35, 35],
  className: 'Leaflet-venue-icon',
});
