import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import {
  paquersPackagesMoneyAmmount,
  paquersPaquerIdSelector,
} from 'redux/paquers/selectors';
import { authProfile } from 'redux/auth/selectors';
import { countriesGlobalsSelector } from 'redux/globals/selectors';
import { DEPARTURE_PACKAGES_VALUE_THRESHOLD } from 'constants/defaultValues';
import { Col, Row } from 'antd';
import { countries } from 'country-data';

const backgroundSafeColor = '#effff0';
const fontSafeColor = '#75a478';
const backgroundWarningColor = '#ffcccb';
const fontWarningColor = '#ba6b6c';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (isSafe) =>
      isSafe ? backgroundSafeColor : backgroundWarningColor,
    padding: '21px 0px',
    fontSize: '1.4em',
    color: (isSafe) => (isSafe ? fontSafeColor : fontWarningColor),
    fontWeight: 700,
    borderRadius: '8px',
    marginTop: '10px',
  },
});

const EstimatedMoneyAmmount = () => {
  const totalMoneyAmmount = useSelector(paquersPackagesMoneyAmmount);
  const isSafe = totalMoneyAmmount < DEPARTURE_PACKAGES_VALUE_THRESHOLD;
  const paquerSelected = useSelector(paquersPaquerIdSelector);
  const profile = useSelector(authProfile);
  const globalCountries = useSelector(countriesGlobalsSelector);
  const classes = useStyles(isSafe);
  const countryCode = globalCountries.find(
    (item) => item.id === profile.countryID,
  )?.twoLetterISOCode;

  const formatMoney = (value) => {
    // this is due Intl.NumberFormat can throw an error if lang is not supported
    try {
      const valueFormated = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: countries[countryCode].currencies[0],
      }).format(value);
      return valueFormated;
    } catch (error) {
      return value;
    }
  };

  const estimatedMoneyAmount = formatMoney(totalMoneyAmmount);

  if (!paquerSelected) return null;
  return (
    <Row className={classes.container}>
      <Col xs={24}>
        <div>Valor estimado de ruta:</div>
        <div style={{ fontSize: '1.7em', wordBreak: 'break-word' }}>
          {estimatedMoneyAmount}
        </div>
      </Col>
    </Row>
  );
};

export default EstimatedMoneyAmmount;
