import Api from 'util/Api';
import API from 'constants/api';
import store from 'redux/store';

export interface AddressGelocalized {
  exactMatch: boolean;
  lat: number;
  lng: number;
  normalizedAddress?: string;
  resolutedPostalCode?: string;
  resolutedStreet?: string;
  resolutedStreetNumber?: string;
  resolutedLocality?: string;
}

/**
 * @description
 * Obtiene la direccion geo-localizada
 */
export const getAddressGelocalized = async (
  address: string | null | undefined,
) => {
  if (!address) throw new Error('No se ha ingresado una dirección');
  try {
    const state: any = store.getState();
    const profileCountryId = state.auth.profile.countryID;
    const country = state.globals.countries.filter(
      (_country: any) => _country.id === profileCountryId,
    );
    const body = {
      address,
      country: country[0].twoLetterISOCode,
    };
    const response = await Api.apiAxios.post(
      `${API.adresses.getGeolocalized}`,
      body,
    );

    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }

    const arriveResponse: AddressGelocalized = response.data;

    return arriveResponse;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw error;
    }
    throw new Error(error.message);
  }
};

const AddressesService = {
  getAddressGelocalized,
};

export default AddressesService;
