import React from 'react';
import PropTypes from 'prop-types';
import {
  WarningOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { STATUS_PACKAGE_RESPONSE } from 'constants/defaultValues';
import { PACKAGE } from 'constants/packages';
import { useStylesStatusPackage } from '../styles';

const Loading = ({ ...props }) => <LoadingOutlined {...props} />;

const statusStyles = (status) => {
  const styles = {};
  switch (status) {
    case STATUS_PACKAGE_RESPONSE.OK:
      styles.iconColor = 'green';
      break;
    case STATUS_PACKAGE_RESPONSE.ERROR:
      styles.iconColor = 'gold';
      break;
    default:
      styles.iconColor = 'black';
      break;
  }
  return styles;
};

const StatusPackage = ({
  state: {
    status,
    loading,
    externalCode,
    paquerName,
    packageStatus,
    error,
    show,
  },
}) => {
  const componentStyles = statusStyles(status);
  const classes = useStylesStatusPackage(componentStyles);

  let statusBody;
  if (loading) {
    statusBody = (
      <div className={classes.container}>
        <Loading className={classes.icon} />
      </div>
    );
  } else if (status === STATUS_PACKAGE_RESPONSE.OK) {
    if (packageStatus === PACKAGE.STATUSES.INHANDSOFPAQUER) {
      statusBody = (
        <div className={classes.container}>
          <CheckCircleOutlined className={classes.icon} />
          <span className={classes.description}>
            {`El paquete ${externalCode} fue asignado a ${paquerName}`}
          </span>
        </div>
      );
    } else {
      statusBody = (
        <div className={classes.container}>
          <ExclamationCircleOutlined className={classes.icon} />
          <span className={classes.description}>
            {`Faltan ingresar bultos del paquete ${externalCode}`}
          </span>
        </div>
      );
    }
  } else if (status === STATUS_PACKAGE_RESPONSE.ERROR) {
    statusBody = (
      <div className={classes.container}>
        <WarningOutlined className={classes.icon} />
        <span className={classes.description}>
          {`No se pudo asignar: ${error}`}
        </span>
      </div>
    );
  }
  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames={{
        enter: classes.fadeEnter,
        enterActive: classes.fadeEnterActive,
        exit: classes.fadeExit,
        exitActive: classes.fadeExitActive,
      }}
      mountOnEnter
      unmountOnExit
    >
      {statusBody}
    </CSSTransition>
  );
};

StatusPackage.propTypes = {
  state: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    status: PropTypes.string,
    externalCode: PropTypes.string,
    paquerName: PropTypes.string,
    error: PropTypes.string,
    show: PropTypes.bool,
    packageStatus: PropTypes.number,
  }).isRequired,
};

export default StatusPackage;
