import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackages = (state) => state[name] || initialState;

export const packagesWithPaquersItemsSelector = (state) => state[name].items;

export const packagesWithPaquersStatusSelector = (state) => state[name].status;

export const packagesWithPaquersPageableSelector = (state) =>
  state[name].pageable;

export const packagesWithPaquersLoadedSelector = (state) => state[name].loaded;

export const packagesWithPaquersTypeSelector = (state) => state[name].type;

export const packagesWithPaquersSearchSelector = (state) => state[name].search;

export const packagesWithPaquersDateSelector = (state) => state[name].date;

export const packagesWithPaquersPaquerSelector = (state) =>
  state[name].paquerId;

const makeSelectPackagesWithPaquers = () =>
  createSelector(selectPackages, (substate) => substate);

export default makeSelectPackagesWithPaquers;
