import React, { useCallback, useMemo } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Col, Form, Select as SelectAntd } from 'antd';
import { UndoOutlined } from '@ant-design/icons';

const { Option } = SelectAntd;

interface Props {
  label: string;
  fieldName: string;
  disabled?: boolean;
  placeholder?: string;
  onSelect?: React.ChangeEventHandler<HTMLInputElement>;
  xs?: number | undefined;
  sm?: number | undefined;
  md?: number | undefined;
  lg?: number | undefined;
  xl?: number | undefined;
  xxl?: number | undefined;
  showReset?: boolean;
  loading?: boolean;
  fieldNames?:
    | {
        value: any;
        label: string;
      }
    | undefined;
  options?: any[];
  allowClear?: boolean;
  defaultValue?: any;
  firstDefault?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  // eslint-disable-next-line no-unused-vars
  onSearch?: (value: any) => void;
  showSearch?: boolean;
  // eslint-disable-next-line no-unused-vars
  filterOption?: (inputValue: any, option: any) => boolean;
}

export const Select = ({
  label,
  placeholder = 'Sin informacion',
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  options,
  fieldName = '',
  showReset = true,
  fieldNames = undefined,
  allowClear,
  mode,
  showSearch,
  filterOption,
  disabled,
  defaultValue,
  firstDefault,
  loading,
  onSearch,
  onSelect,
}: Props) => {
  const { values, initialValues, errors, setFieldValue, setFieldTouched } =
    useFormikContext<any>();
  const error =
    getIn(errors, fieldName) instanceof Object ? '' : getIn(errors, fieldName);
  const initialValue =
    getIn(initialValues, fieldName) instanceof Object
      ? ''
      : getIn(initialValues, fieldName);

  const value = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _val =
      getIn(values, fieldName) instanceof Object
        ? ''
        : getIn(values, fieldName);
    const hasChanged = _val && _val?.toString() !== initialValue?.toString();
    if (defaultValue && !hasChanged) return defaultValue;
    return _val;
  }, [values, fieldName, initialValue, defaultValue]);

  const hasChanged = value && value?.toString() !== initialValue?.toString();

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, e);
      if (onSelect) onSelect(e);
    },
    [fieldName, setFieldTouched, setFieldValue, onSelect],
  );

  const handleRestart = useCallback(() => {
    setFieldValue(fieldName, initialValue);
    setFieldTouched(fieldName, false);
  }, [fieldName, initialValue, setFieldValue, setFieldTouched]);

  const getValidateStatus = () => {
    let validateStatus:
      | ''
      | 'error'
      | 'warning'
      | 'success'
      | 'validating'
      | undefined;
    if (hasChanged && showReset) {
      validateStatus = 'warning';
    }
    if (error) {
      validateStatus = 'error';
    }
    return validateStatus;
  };

  const validateStatus = getValidateStatus();

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <span>{label}</span>
        {hasChanged && showReset && (
          <div>
            <UndoOutlined onClick={handleRestart} />
          </div>
        )}
      </div>
      <Form.Item
        validateStatus={validateStatus}
        help={error}
        style={{ margin: 0 }}
      >
        <SelectAntd
          loading={loading}
          style={{ marginTop: '6px', width: '100%' }}
          placeholder={placeholder}
          onSelect={handleBlur}
          value={value}
          id={`select-${fieldName}`}
          allowClear={allowClear}
          mode={mode}
          showSearch={showSearch}
          filterOption={filterOption}
          disabled={disabled}
          defaultValue={defaultValue}
          defaultActiveFirstOption={firstDefault}
          onSearch={onSearch}
        >
          {options?.map((option) => (
            <Option
              key={fieldNames?.value ? option[fieldNames.value] : option.value}
              value={
                fieldNames?.value ? option[fieldNames.value] : option.value
              }
              disabled={option.disabled}
            >
              {fieldNames?.label ? option[fieldNames.label] : option.label}
            </Option>
          ))}
        </SelectAntd>
      </Form.Item>
    </Col>
  );
};

Select.defaultProps = {
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  xxl: undefined,
  showReset: true,
  loading: false,
  fieldNames: undefined,
  options: [],
  allowClear: false,
  defaultValue: undefined,
  firstDefault: false,
  mode: undefined,
  onSearch: undefined,
  showSearch: false,
  filterOption: undefined,
  disabled: false,
  placeholder: 'Sin informacion',
  onSelect: undefined,
};

export default React.memo(Select);
