/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import EntryPacketView, { ERROR_STATUS } from '@paquery-team/lib-arrive-packet';
import { Button, Card, Modal, theme } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useMarketplaces from 'redux/marketplaces';
import { useDebounce } from 'util/Hooks';
import PackageSelectModal from 'components/packageSelectModal';
import QRScanner from 'components/QRScanner';
import PackageService, { FoundPackage } from 'services/PackageService';

const { confirm } = Modal;

const WAIT_INTERVAL = 600;

const DeliverPickup = () => {
  useMarketplaces();
  const [packetResponse, setPacketResponse] = useState<{
    status: number;
    message: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [externalCode, setExternalCode] = useState('');
  const [packageOptions, setPackageOptions] = useState<any[]>([]);
  const [packageModal, setPackageModal] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const inputExternalCode = useRef<HTMLInputElement | null>(null);
  const debouncedExternalCode = useDebounce(externalCode, WAIT_INTERVAL);
  const { token } = theme.useToken();

  const clearInputs = () => {
    setLoading(false);
    setPackageOptions([]);
    setPackageModal(false);
    if (inputExternalCode.current) {
      inputExternalCode.current.value = '';
      inputExternalCode.current.focus();
      inputExternalCode.current.select();
    }
  };

  const deliverPacket = async (externalCode: string) => {
    try {
      const response =
        await PackageService.deliverPickupPacketByExternalCode(externalCode);
      // Si se entrego el paquete
      if (response.status === 200) {
        setPacketResponse({
          status: 20,
          message: response.message || 'Paquete entregado',
        });
        return;
      }
      // Si se encontraron mas paquetes con el mismo codigo externo
      if (
        response.data.foundPackages &&
        response.data.foundPackages.length > 1
      ) {
        const suggestedPackageOptions = response.data.foundPackages.map(
          (packet: FoundPackage) => {
            return {
              ...packet,
              marketplace: packet.marketplace?.name,
            };
          },
        );
        setPackageOptions(suggestedPackageOptions);
        setPackageModal(true);
        return;
      }

      throw new Error(response.message || 'Hubo un error en el servidor.');
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log({ error });
      if (error.isAxiosError) {
        setPacketResponse({
          status: ERROR_STATUS,
          message:
            error.response?.data.message || 'Hubo un error en el servidor',
        });
        return;
      }
      setPacketResponse({ status: ERROR_STATUS, message: error.message });
    } finally {
      clearInputs();
    }
  };

  const deliverPacketById = async (id: number) => {
    setLoading(true);
    try {
      const response = await PackageService.deliverPickupPacketByID(id);
      // Se muestra una alerta de que el paquete ya fue entregado, usando el codigo -2 que indica "Arribado" (-2 es hardcoded)
      setPacketResponse({
        status: 20,
        message: response.data?.message || 'Paquete arribado correctamente',
      });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      if (error.isAxiosError) {
        setPacketResponse({
          status: ERROR_STATUS,
          message:
            error.response?.data.message || 'Hubo un error en el servidor',
        });
        return;
      }
      setPacketResponse({ status: ERROR_STATUS, message: error.message });
    } finally {
      clearInputs();
    }
  };

  const showConfirmDelivery = (extCode: string) => {
    confirm({
      title: '¿Desea confirmar la entrega?',
      icon: <ExclamationCircleOutlined />,
      content: `Confirme que el código ${externalCode} sea el correcto.`,
      onOk() {
        deliverPacket(extCode);
      },
      onCancel: clearInputs,
    });
  };

  const showConfirmDeliveryByID = (id: number) => {
    confirm({
      title: '¿Desea confirmar la entrega?',
      icon: <ExclamationCircleOutlined />,
      content: `Confirme que el código ${externalCode} sea el correcto.`,
      onOk() {
        deliverPacketById(id);
      },
      onCancel: clearInputs,
    });
  };

  const handleScanQR = (data: string | null) => {
    if (data) {
      setExternalCode(data);
      setShowCamera(false);
    }
  };

  const handlePackageSelection = (_package: FoundPackage) => {
    showConfirmDeliveryByID(_package.id);
  };

  useEffect(() => {
    const handleSubmit = async (extCode: string | null | undefined) => {
      setPacketResponse(null);
      if (!extCode || extCode === '') {
        return;
      }
      setLoading(true);
      let receivedExternalCode = extCode.trim();
      try {
        const qrCode = JSON.parse(extCode.trim());
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // pass
      }
      showConfirmDelivery(receivedExternalCode);
    };
    handleSubmit(debouncedExternalCode);
  }, [debouncedExternalCode]);

  return (
    <Card>
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handlePackageSelection={handlePackageSelection}
        handleCloseModal={() => {
          setPackageModal(false);
          setPackageOptions([]);
          setLoading(false);
          if (inputExternalCode.current) {
            inputExternalCode.current.focus();
            inputExternalCode.current.select();
            inputExternalCode.current.value = '';
          }
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            paddingTop: '3vh',
            justifySelf: 'center',
            color: token.colorPrimary,
          }}
          type="link"
          onClick={() => setShowCamera(true)}
        >
          Escanear QR
        </Button>
      </div>
      <EntryPacketView
        inputExternalCode={inputExternalCode}
        onChange={(event) => setExternalCode(event.target.value)}
        loading={loading}
        packetResponse={packetResponse}
        title="Entregar paquete PickUp"
      />
      <QRScanner
        onScan={handleScanQR}
        showCamera={showCamera}
        setShowCamera={setShowCamera}
      />
    </Card>
  );
};

export default DeliverPickup;
