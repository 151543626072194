import React, { useState, useEffect, useCallback } from 'react';
import PackageDetail from '@paquery-team/lib-package-detail';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Result, notification } from 'antd';
import JsDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import {
  countriesGlobalsSelector,
  packagesStatusesGlobalsSelector,
} from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import { zoneItemsSelector } from 'redux/zones/selectors';
import {
  getZoneFromAddress,
  isProfileAdministrator,
} from 'constants/operations';
import { ExportDateFormat } from 'constants/dateFormats';
import {
  PACKAGE,
  isArrivableAtPaQueryPointStatus,
  isCancellableStatus,
} from 'constants/packages';
import API from 'constants/api';
import { IMAGE_REGEX } from 'constants/defaultValues';
import rest, { getData } from 'util/Api';

const disabledInputs = {
  externalCode: true,
  caption: true,
  estimatedCost: true,
  ownerID: true,
  packageType: true,
  deliveryTerm: true,
  arrivedAtPaqueryPointDate: true,
  additionalCode: true,
  shippingScheduleDestination: {
    scheduledDate: true,
    name: true,
    destinationEmail: true,
    shippingAddress: {
      geoKey: true,
    },
    destinationAclaration: true,
    destinationDocNumber: true,
    tramitNumber: true,
  },
  shippingScheduleOrigin: {
    name: true,
    phone: true,
    shippingAddress: {
      addressDetail: true,
      geoKey: true,
    },
  },
  storeId: true,
};

const validationSchema = Yup.object().shape({
  status: Yup.number(),
  packageType: Yup.number(),
  reason: Yup.string().when('status', {
    is: 21,
    then: Yup.string()
      .required('Indique el motivo de la cancelación')
      .typeError('Indique el motivo de la cancelación'),
    otherwise: Yup.string().nullable(),
  }),
  shippingScheduleDestination: Yup.object().shape({
    shippingAddress: Yup.object().shape({
      addressDetail: Yup.string().required('Indique la dirección de entrega'),
    }),
    deliveryNote: Yup.boolean().nullable(),
    paymentOnDelivery: Yup.boolean().nullable(),
    paymentAmount: Yup.number().when('paymentOnDelivery', {
      is: true,
      then: Yup.number()
        .required('Es necesario especificar un monto')
        .typeError('El monto debe ser un numero valido'),
      otherwise: Yup.number().nullable(),
    }),
  }),
  additionalCode: Yup.string().when('packageType', {
    // when package is type flex, additionalCode is required
    is: 5,
    then: Yup.string().required('Es necesario especificar el codigo de venta'),
    otherwise: Yup.string().nullable(),
  }),
});

const fetchPackageByExternalcode = async (externalCode) => {
  try {
    if (externalCode === null || externalCode === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const searchParams = new URLSearchParams({
      externalCode,
    });
    const {
      data: { content },
      // eslint-disable-next-line import/no-named-as-default-member
    } = await rest.get(
      `${API.packages.getByExternalCode}?${searchParams.toString()}`,
    );
    if (content.length === 0) {
      const error = new Error(
        'El paquete con el codigo externo indicado no existe',
      );
      return error;
    }
    return content[0];
  } catch (error) {
    if (error.message.includes(404)) {
      return new Error('No se ha encontrado el paquete con el id solicitado');
    }
    return error;
  }
};

const fetchPackageById = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const packet = await getData(`${API.packages.getById}${id}`);
    return packet;
  } catch (error) {
    if (error.message.includes(404)) {
      return new Error('No se ha encontrado el paquete con el id solicitado');
    }
    return error;
  }
};

const PackageModify = () => {
  const { externalCode, id } = useParams();
  const history = useHistory();

  const zones = useSelector(zoneItemsSelector);
  const countries = useSelector(countriesGlobalsSelector);
  const profileLogged = useSelector(authProfile);
  const packageStatuses = useSelector(packagesStatusesGlobalsSelector);

  const [marketplaces, setMarketplaces] = useState([]);
  const [stores, setStores] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  const [packet, setPacket] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState([]);

  const getAllowedStatus = (statusList, currentStatus) => {
    const allowedStatus = [];
    if (isProfileAdministrator(profileLogged)) {
      return statusList;
    }
    const currentStatusFullDescription = statusList.find(
      (status) => status.value === currentStatus,
    );
    allowedStatus.push(currentStatusFullDescription);
    if (currentStatus === PACKAGE.STATUSES.DELIVERYATTEMPTTWO) {
      const visitFirstTryFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.DELIVERYATTEMPTONE,
      );
      allowedStatus.push(visitFirstTryFullDescription);
      const devolutionProcessFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.INRETURNPROCESS,
      );
      allowedStatus.push(devolutionProcessFullDescription);
    }
    if (currentStatus === PACKAGE.STATUSES.DELIVERYATTEMPTONE) {
      const visitSecondTryFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
      );
      allowedStatus.push(visitSecondTryFullDescription);
    }
    if (isArrivableAtPaQueryPointStatus(currentStatus)) {
      const arrivedPaquerypointFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT,
      );
      allowedStatus.push(arrivedPaquerypointFullDescription);
    }
    if (currentStatus === PACKAGE.STATUSES.PENDINGENTRYPAQUERYPOINT) {
      const onPaqueryPointFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.LOGGEDINTOPAQUERY,
      );
      allowedStatus.push(onPaqueryPointFullDescription);
    }
    if (isCancellableStatus(currentStatus)) {
      const cancelledFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.CANCELLED,
      );

      allowedStatus.push(cancelledFullDescription);
    }
    if (currentStatus === PACKAGE.STATUSES.CANCELLED) {
      const devolutionProcessFullDescription = statusList.find(
        (status) => status.value === PACKAGE.STATUSES.INRETURNPROCESS,
      );
      allowedStatus.push(devolutionProcessFullDescription);
    }
    return allowedStatus;
  };

  const getPacket = useCallback(
    // eslint-disable-next-line consistent-return
    async (fetch, data) => {
      const interfacePackage = async (_packet) => {
        try {
          const marketplace = await getData(
            API.marketplaces.getById(_packet.ownerID),
          );
          setMarketplaces((mkps) => [...mkps, marketplace]);
          // Si el paquete tiene una tienda asignada, fetchear la tienda.
          if (_packet.storeId) {
            const store = await getData(API.stores.getById(_packet.storeId));
            setStores((_stores) => [..._stores, store]);
          }

          _packet.shippingScheduleDestination.zone =
            _packet.shippingScheduleDestination.zone?.id;

          return _packet;
        } catch (error) {
          return error;
        }
      };
      const packetResponse = await fetch(data);
      if (packetResponse instanceof Error) {
        setErrors(packetResponse.message);
      } else {
        const convertedPackage = await interfacePackage(packetResponse);
        if (convertedPackage instanceof Error) {
          return setErrors(convertedPackage.message);
        }
        setFilteredStatus(
          getAllowedStatus(packageStatuses, convertedPackage.status),
        );
        setPacket(convertedPackage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [packageStatuses, countries],
  );

  useEffect(() => {
    if (externalCode) {
      getPacket(fetchPackageByExternalcode, externalCode);
    }
  }, [externalCode, getPacket]);

  useEffect(() => {
    if (id) {
      getPacket(fetchPackageById, id);
    }
  }, [id, getPacket]);

  const unassignPacket = async (prevStatus, newStatus) => {
    if (
      newStatus === PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT &&
      prevStatus !== newStatus
    ) {
      try {
        const payload = {
          id: parseInt(id, 10),
        };
        await rest.put(`${API.paquer.unassign}${id}`, payload);
      } catch (error) {
        notification.error({
          message: 'Ocurrio un problema',
          description: `Ha ocurrido un error al modificar el paquete${
            error.message ? `: ${error.message}` : '.'
          }`,
        });
      }
    }
  };

  const parsePacket = async (values) => {
    const resolvedZone = await getZoneFromAddress(
      values.shippingScheduleDestination.shippingAddress.addressDetail,
      profileLogged.logisticOperatorID,
    );
    const builtPackage = {
      ...values,
      signatureImage: values.signatureFileName
        ? values.signatureImage.replace(IMAGE_REGEX, '')
        : undefined,
      signatureFileName: values.signatureFileName,
      shippingScheduleOrigin: {
        ...values.shippingScheduleOrigin,
        driver: packet.shippingScheduleOrigin?.driver,
      },
      shippingScheduleDestination: {
        ...values.shippingScheduleDestination,
        zoneID: values.shippingScheduleDestination.zone?.id,
        distributionZoneID:
          values.shippingScheduleDestination.distributionZone?.id,
        driver: packet.shippingScheduleDestination?.driver,
        shippingAddress: {
          ...values.shippingScheduleDestination.shippingAddress,
          lat: resolvedZone.lat,
          lng: resolvedZone.lng,
          postalCode: resolvedZone.postalCode,
          resolutedAddress: resolvedZone.resolutedAddress,
          resolutedStreet: resolvedZone.resolutedStreet,
          resolutedStreetNumber: resolvedZone.resolutedStreetNumber,
          resolutedLocality: resolvedZone.resolutedLocality,
        },
      },
      zone: {
        id: values.shippingScheduleDestination.zone,
      },
    };
    return builtPackage;
  };

  const submitEdition = async (values) => {
    setSaving(true);
    const putPackage = await parsePacket(values);

    try {
      const response = await rest.put(
        `${API.packages.get}/${putPackage.id}`,
        putPackage,
      );
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Modificación de paquete correcto',
          description: `El paquete ${packet.externalCode} ha sido modificado correctamente.`,
        });
        if (
          putPackage.shippingScheduleDestination.driver ||
          putPackage.shippingScheduleOrigin.driver
        ) {
          unassignPacket(packet.status, putPackage.status);
        }
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al modificar el paquete${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
      setSaving(false);
    }
  };

  const handleDownloadRemito = async () => {
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.packages.downloadDeliveryNoteById(packet.id)}`,
        {
          responseType: 'blob',
        },
      );
      if (!rest.isSuccessResponse(response)) {
        // converts blob to text
        const error = await response.data.text();
        throw new Error(error);
      }
      const filename = `Remito ${packet.externalCode} ${dayjs()
        .tz()
        .format(ExportDateFormat)}.pdf`;
      JsDownload(response.data, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer el remito',
        description: `Intente nuevamente por favor o informelo a los administradores${
          exception.message ? ` - ${exception.message}` : '.'
        }`,
      });
    }
  };

  const handleBlur = async (destinationAddressValue, setFieldValue) => {
    if (!destinationAddressValue) return;
    try {
      const zone = await getZoneFromAddress(
        destinationAddressValue,
        profileLogged.logisticOperatorID,
      );
      if (zone.id)
        setFieldValue(
          'shippingScheduleDestination.zone',
          parseInt(zone.id, 10),
        );
      else
        notification.error({
          message: 'Oops!',
          description: 'No se ha encontrado una zona para esa dirección.',
        });
    } catch (error) {
      notification.error({
        message: 'Oops!',
        description: 'Ha ocurrido un error. Intente de nuevo más tarde.',
      });
    }
  };

  if (errors) return <Result status="warning" title={errors} />;

  return (
    <PackageDetail
      packageStatuses={filteredStatus}
      marketplaces={marketplaces}
      stores={stores}
      zones={zones}
      saving={saving}
      isModify
      countries={countries}
      loaded={!!packet}
      disabledInputs={disabledInputs}
      initialValues={packet}
      validationSchema={validationSchema}
      onSubmit={submitEdition}
      onDownloadRemito={handleDownloadRemito}
      onBlurDeliveryAddress={handleBlur}
    />
  );
};

PackageModify.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      packet: PropTypes.shape({
        prefix: PropTypes.string,
        country: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default PackageModify;
