import React, { useMemo } from 'react';
import { Affix } from 'antd';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
} from 'react-leaflet';
import PropTypes from 'prop-types';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/dist/styles.css';
import Card from 'components/card';
import { PaqueryPointIconMarker, IconMarkerGray } from './components/icons';
import PackageMarker from './components/PackageMarker';
// import RoutingMachine from './components/RoutingMachine';

const pathPolylinesByRoute = (route) => {
  const path = [];
  // Si la ruta tiene localizacion de inicio, la agrego al path
  if (route.starting_waypoint.lat && route.starting_waypoint.lng) {
    path.push([route.starting_waypoint.lat, route.starting_waypoint.lng]);
  }
  // Agrego las localizaciones de los paquetes al path
  route.packages.forEach((packet) => {
    if (packet.geoLat && packet.geoLng)
      path.push([packet.geoLat, packet.geoLng]);
  });

  // Si la ruta tiene localizacion de final, la agrego al path
  if (route.ending_waypoint.lat && route.ending_waypoint.lng) {
    path.push([route.ending_waypoint.lat, route.ending_waypoint.lng]);
  }
  return path;
};

const Map = ({ route }) => {
  const center =
    route.starting_waypoint.lat && route.starting_waypoint.lng
      ? [route.starting_waypoint.lat, route.starting_waypoint.lng]
      : [0, 0];

  const EndingMarker = useMemo(() => {
    // Si no hay localizacion de final, no dibujo el marcador
    if (!route.ending_waypoint.lat || !route.ending_waypoint.lng) return null;
    // Si la localizacion de final de la ruta es la misma que la de inicio, no dibujo el marcador
    if (
      route.ending_waypoint.lat === route.starting_waypoint.lat &&
      route.ending_waypoint.lng === route.starting_waypoint.lng
    )
      return null;

    // Si la localizacion de final de la ruta es un paquery point, mostrar el marcador de paquery point.
    if (route.ending_waypoint.type === 'paquery_point') {
      return (
        <Marker
          position={[route.ending_waypoint.lat, route.ending_waypoint.lng]}
          icon={PaqueryPointIconMarker}
        >
          <Popup>
            <div className="text-center">
              <h4>
                {route.ending_waypoint.name} - {route.ending_waypoint.address}
              </h4>
            </div>
          </Popup>
        </Marker>
      );
    }

    // Si la localizacion de final de la ruta es una direccion, mostrar el marcador de la direccion.
    if (route.ending_waypoint.type === 'other') {
      return (
        <Marker
          position={[route.ending_waypoint.lat, route.ending_waypoint.lng]}
          icon={IconMarkerGray}
        >
          <Popup>
            <div className="text-center">
              <h4>{route.ending_waypoint.address}</h4>
            </div>
          </Popup>
        </Marker>
      );
    }

    return null;
  }, [route]);

  return (
    <Affix offsetTop={16}>
      <Card style={{ overflow: 'hidden' }} bodyStyle={{ padding: 0 }}>
        <MapContainer className="route-map" center={center} zoom={14}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {route?.packages
            .filter((packet) => packet.geoLat && packet.geoLng)
            .map((packet) => (
              <PackageMarker key={packet.id} packet={packet} />
            ))}

          <Marker
            key={route?.paqueryPoint?.id}
            position={center}
            icon={PaqueryPointIconMarker}
          >
            <Popup>
              <div>
                <h3>Lugar de inicio</h3>
                <p>{route?.paqueryPoint?.name}</p>
                <p>{route?.paqueryPoint?.locationAddress?.address}</p>
              </div>
            </Popup>
          </Marker>

          {EndingMarker}
          {/* <RoutingMachine locations={pathPolylinesByRoute(route)} /> */}
          <Polyline
            pathOptions={{ color: '#6FA1EC', weight: 4 }}
            positions={pathPolylinesByRoute(route)}
          />

          <FullscreenControl />
        </MapContainer>
      </Card>
    </Affix>
  );
};

Map.propTypes = {
  route: PropTypes.shape({
    starting_waypoint: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      entity_id: PropTypes.number,
    }),
    ending_waypoint: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      entity_id: PropTypes.number,
    }),
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        geoLat: PropTypes.number,
        geoLng: PropTypes.number,
      }),
    ),
    paqueryPoint: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      locationAddress: PropTypes.shape({
        address: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Map;
