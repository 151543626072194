import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DriverDetail } from '@paquery-team/lib-driver-detail';
import rest, { getData } from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import {
  countriesGlobalsSelector,
  vehicleTypesGlobalsSelector,
} from 'redux/globals/selectors';
import { paquerviewLoadingSelector } from 'redux/paquerView/selectors';
import { actions } from 'redux/paquerView/slice';
import { actions as ppointDepartureActions } from 'redux/packagePaquerypointDeparture/slice';
import usePaquerview from 'redux/paquerView';
import { notification } from 'antd';
import { ContainerOutlined, HistoryOutlined } from '@ant-design/icons';
import API from 'constants/api';
import * as Yup from 'yup';
import { zoneItemsSelector } from 'redux/zones/selectors';
import usePackagePpointDeparture from 'redux/packagePaquerypointDeparture';
import { packagePaquerypointDepartureItemsSelector } from 'redux/packagePaquerypointDeparture/selectors';
import PaquerService from 'services/PaquerService';
import PaquerPackages from './components/packages';
import PaquerHistory from './components/history';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastname: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  docNumber: Yup.number()
    .typeError('Solo se deben indicar números y es requerido')
    .required('El número de documento es necesario'),
  mobile: Yup.number()
    .typeError('Solo deben ser numeros y son requeridos')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
  city: Yup.object().shape({
    id: Yup.number()
      .typeError('Es necesario indicar la ciudad')
      .required('Es necesario indicar la ciudad'),
    countryID: Yup.number()
      .typeError('Es necesario indicar el país')
      .required('Es necesario indicar el país'),
  }),
});

const initialValues = {
  name: '',
  lastname: '',
  docNumber: null,
  cbu: null,
  cuil: null,
  pwd: '',
  birthDate: null,
  prefix: '',
  phone: null,
  active: true,
  confirmpassword: '',
  cityID: null,
  countryID: null,
  vehicleTypeID: null,
  zones: [],
  paqueryPoints: [],
};

const disabledInputs = {
  avatar: true,
  name: true,
  lastname: true,
  birthDate: true,
  docNumber: true,
  cbu: true,
  cuil: true,
  vehicleTypeID: true,
  mobile: true,
  countryId: true,
  cityId: true,
  email: true,
  paqueryPoints: true,
  workingZones: true,
};

/**
 * Returns if the paquer is active by its paquer.status property
 * @param {number} status
 * */
const isPaquerActive = (status) => {
  if (status === 1) return true;
  if (status === 6) return false;
  return false;
};

const PaquerModify = () => {
  usePaquerview();
  usePackagePpointDeparture();
  const dispatch = useDispatch();
  const loading = useSelector(paquerviewLoadingSelector);
  const zones = useSelector(zoneItemsSelector);
  const vehicleTypes = useSelector(vehicleTypesGlobalsSelector);
  const paqueryPoints = useSelector(packagePaquerypointDepartureItemsSelector);
  const countries = useSelector(countriesGlobalsSelector);
  const [paquerData, setPaquerData] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const { paquerId } = useParams();

  useEffect(() => {
    dispatch(actions.reset());
    dispatch(ppointDepartureActions.initial());
    dispatch(actions.setDriverId(paquerId));
  }, [dispatch, paquerId]);

  const onChangeCountry = useCallback(
    async (country) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  const onChangePassword = async (email) => {
    try {
      const body = {
        email,
      };
      const bodyParsed = new URLSearchParams(body);
      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      await rest.post(
        `${API.user.changePassword}`,
        bodyParsed.toString(),
        options,
      );
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Hubo un error al solicitar el cambio de contraseña${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };

  const handleGetPaquer = useCallback(async () => {
    dispatch(actions.loading());
    const fetchedPaquer = await PaquerService.geyById(paquerId);
    setPaquerData({
      ...fetchedPaquer,
      lastname: fetchedPaquer.lastName,
      isActive: isPaquerActive(fetchedPaquer.status),
      cityID: fetchedPaquer.city.id,
      countryID: fetchedPaquer.city.countryID,
      zones: fetchedPaquer.zones
        .filter((zone) => zone != null)
        .map(
          (zone) =>
            zones.find((zoneInRedux) => zoneInRedux.id === zone.id) || {
              id: zone.id,
              name: 'ZONA DESCONOCIDA',
            },
        ),
    });

    dispatch(actions.setAvatar({ data: fetchedPaquer.avatar }));

    if (fetchedPaquer.city.countryID) {
      const citiesResponse = await getData(
        `${API.globals.cities}/${fetchedPaquer.city.countryID}`,
      );
      setCities(citiesResponse);
    }

    dispatch(actions.loaded());
  }, [paquerId]);

  useEffect(() => {
    handleGetPaquer();
  }, [handleGetPaquer]);

  return (
    <DriverDetail
      loaded={!loading}
      initialValues={paquerData || initialValues}
      validationSchema={validationSchema}
      disabledInputs={disabledInputs}
      currentCity={currentCity}
      vehicles={vehicleTypes}
      countries={countries}
      cities={cities}
      zones={zones}
      paqueryPoints={paqueryPoints}
      onChangePassword={onChangePassword}
      onChangeCountry={onChangeCountry}
      lists={[
        {
          title: 'En tránsito',
          icon: <ContainerOutlined />,
          table: PaquerPackages,
        },
        {
          title: 'Histórico',
          icon: <HistoryOutlined />,
          table: PaquerHistory,
        },
      ]}
      isModify
    />
  );
};
export default PaquerModify;
