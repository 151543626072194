import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import API from 'constants/api';
import { get } from 'util/Api';
import { authProfile } from 'redux/auth/selectors';
import { actions } from './slice';
import { actions as globalsActions } from '../globals/slice';
import { zoneItemsSelector } from './selectors';

function* fetchSaga() {
  try {
    const profile = yield select(authProfile);
    const options = {
      headers: {
        TENANT_KEY: profile.ownerID,
      },
    };

    const response = yield call(
      get,
      `${API.zones.getAllByLogisticOperator(profile.logisticOperatorID)}`,
      options,
    );

    const zones = response.data;

    yield put({
      type: actions.success.type,
      payload: zones,
    });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(zoneItemsSelector);
  if (!items || Object.values(items).length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery([actions.initial, globalsActions.success], initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
