import React from 'react';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';
import useAuthToken from 'hooks/useAuthToken';
import SITELINKS from 'constants/sitelinks';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';

const App = () => {
  const tokenChecked = useAuthToken();
  if (!tokenChecked) {
    return null;
  }
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path={SITELINKS.login} exact />
        <PublicRoute path={SITELINKS.recover} exact />
        <PublicRoute path={SITELINKS.confirm} exact />
        <PrivateRoute path="/" />
        <Redirect to="" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
