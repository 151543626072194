import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  notification,
  Modal,
  Button,
  Form,
  Row,
  Alert,
  Col,
  theme,
} from 'antd';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { actions } from 'redux/paquers/slice';
import RouteService, { GenerateRouteParams } from 'services/RouteService';
import rest from 'util/Api';
import AddressesService, {
  AddressGelocalized,
} from 'services/AddressesService';
import SITELINKS from 'constants/sitelinks';
import { useHistory } from 'react-router-dom';
import Select from './Select';
import Input from './Input';

const validationSchema = Yup.object().shape({
  last_destiny: Yup.string().required('Esta opcion es requerida'),
  address: Yup.string()
    .when(['last_destiny', 'isAddressValid'], {
      is: (val: string, isAddressValid: boolean) =>
        val === 'other' && !isAddressValid,
      then: Yup.string().required('Esta opcion es requerida'),
      otherwise: Yup.string().notRequired().nullable(),
    })
    .nullable(),
  address_lat: Yup.number()
    .when('last_destiny', {
      is: (val: string) => val === 'other',
      then: Yup.number().required('Esta opcion es requerida'),
      otherwise: Yup.number().notRequired().nullable(),
    })
    .nullable(),
  address_lng: Yup.number()
    .when('last_destiny', {
      is: (val: string) => val === 'other',
      then: Yup.number().required('Esta opcion es requerida'),
      otherwise: Yup.number().notRequired().nullable(),
    })
    .nullable(),
  isAddressValid: Yup.boolean().nullable(),
});

interface FormValues {
  last_destiny: 'other' | 'last_package' | 'paquery_point';
  address: string;
  address_lat: number;
  address_lng: number;
  isAddressValid: boolean;
}
const initialValues: FormValues = {
  last_destiny: 'last_package',
  address: '',
  address_lat: 0,
  address_lng: 0,
  isAddressValid: false,
};

interface Props {
  paquerID?: string;
}

interface IAlert {
  type: 'success' | 'error';
  message: string | undefined;
  address: AddressGelocalized | undefined;
}

const DEFAULT_ALERT: IAlert = {
  type: 'success',
  message: undefined,
  address: undefined,
};

const GenerateRouteButton: React.FC<Props> = ({ paquerID }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const formik = useRef<FormikProps<FormValues>>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState<IAlert>(DEFAULT_ALERT);
  const [activeRouteId, setActiveRouteId] = useState<number | undefined>(
    undefined,
  );
  const { token } = theme.useToken();

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    setAlert(DEFAULT_ALERT);
    if (formik.current) {
      formik.current.resetForm();
    }
  }, []);

  // eslint-disable-next-line camelcase
  const paquer_id = useMemo(() => {
    return paquerID || '';
  }, [paquerID]);

  useEffect(() => {
    setLoading(true);
    if (paquer_id) {
      RouteService.getPaquerActiveRoute(paquer_id)
        .then((res) => {
          setActiveRouteId(res.routeId);
        })
        .catch((error) => {
          if (error.isAxiosError) {
            notification.error({
              message: 'Ocurrio un problema',
              description: `Ha ocurrido al buscar la ruta activa del paquer ${
                error.message ? `: ${error.message}` : '.'
              }`,
            });
          }
        });
    }
    setLoading(false);
  }, [paquer_id]);

  const handleGenerateRoute = async () => {
    setLoading(true);
    const paquerId = parseInt(paquer_id, 10);
    try {
      const {
        last_destiny,
        address,
        address_lat,
        address_lng,
        isAddressValid,
      } = formik.current?.values || initialValues;

      const params: GenerateRouteParams = {
        paquerId,
        type: last_destiny,
      };

      if (
        last_destiny === 'other' &&
        address &&
        address_lat &&
        address_lng &&
        isAddressValid
      ) {
        params.address = address;
        params.lat = address_lat;
        params.lng = address_lng;
      }

      const response = await RouteService.generateRoute(params);
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Ruta generada',
          description: 'La ruta ha sido generada con éxito',
        });
        dispatch(actions.refreshPage());
      }
      handleCloseModal();
    } catch (error: any) {
      notification.error({
        message: 'Ha ocurrido un error al generar la ruta',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGeolocalizeAddress = useCallback(async (address: any) => {
    if (formik.current) {
      formik.current.setFieldValue('address_lat', initialValues.address_lat);
      formik.current.setFieldValue('address_lng', initialValues.address_lng);
      formik.current.setFieldValue(
        'isAddressValid',
        initialValues.isAddressValid,
      );
    }
    try {
      if (!address) {
        setAlert({
          ...DEFAULT_ALERT,
        });
        return;
      }

      const data = await AddressesService.getAddressGelocalized(address);
      if (!data.resolutedLocality && !data.resolutedStreet) {
        setAlert({
          type: 'error',
          message: `La direccion ${address} no existe`,
          address: undefined,
        });
        return;
      }
      if (formik.current) {
        formik.current.setFieldValue('address_lat', data.lat);
        formik.current.setFieldValue('address_lng', data.lng);
        formik.current.setFieldValue('isAddressValid', true);
      }
      setAlert({
        type: 'success',
        message: `La direccion ${address} es valida`,
        address: data,
      });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      if (formik.current) {
        formik.current.setFieldValue('address_lat', initialValues.address_lat);
        formik.current.setFieldValue('address_lng', initialValues.address_lng);
        formik.current.setFieldValue(
          'isAddressValid',
          initialValues.isAddressValid,
        );
      }
      setAlert({
        type: 'error',
        message: `${
          error.message === 'Network Error'
            ? 'Hay un problema con el servidor, intente de nuevo más tarde.'
            : error.message
        }`,
        address: undefined,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleGenerateRoute}
        validateOnBlur
        innerRef={formik}
      >
        {({ values, submitForm }) => {
          const selectedLastDestiny = values.last_destiny;
          return (
            <Modal
              destroyOnClose
              title="Generar ruta"
              open={open}
              onCancel={handleCloseModal}
              footer={[
                <Button loading={loading} key="back" onClick={handleCloseModal}>
                  Cancelar
                </Button>,

                <Button loading={loading} type="primary" onClick={submitForm}>
                  Generar Ruta
                </Button>,
              ]}
            >
              <Form>
                <Row gutter={[16, 16]}>
                  <Select
                    fieldName="last_destiny"
                    label="Destino final"
                    defaultValue="last_package"
                    options={[
                      { label: 'Ultimo paquete', value: 'last_package' },
                      {
                        label: 'PaQuery Point',
                        value: 'paquery_point',
                        disabled: true,
                      },
                      {
                        label: 'Nueva direccion',
                        value: 'other',
                        disabled: true,
                      },
                    ]}
                    xs={24}
                  />
                  {selectedLastDestiny === 'other' && (
                    <>
                      <Input
                        fieldName="address"
                        label="Direccion"
                        xs={24}
                        onBlur={handleGeolocalizeAddress}
                        onChange={() => setLoading(true)}
                      />
                      <Col xs={24}>
                        {alert.message && (
                          <Alert
                            // style={{ width: '100%' }}
                            description={
                              <>
                                <p style={{ margin: 0 }}>{alert.message}</p>
                                {alert.address && (
                                  <a
                                    href={`https://maps.google.com/?q=${alert.address.lat},${alert.address.lng}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: token.colorPrimary }}
                                  >
                                    Ver en mapa.
                                  </a>
                                )}
                              </>
                            }
                            type={alert.type}
                          />
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Modal>
          );
        }}
      </Formik>
      <Button
        type={activeRouteId ? 'default' : 'primary'}
        disabled={!paquerID || loading}
        // eslint-disable-next-line consistent-return
        onClick={() => {
          if (activeRouteId)
            return history.push(
              SITELINKS.routes.view.replace(':id', activeRouteId.toString()),
            );
          setOpen(true);
        }}
      >
        {activeRouteId ? 'Ver ruta activa' : 'Generar ruta'}
      </Button>
    </>
  );
};

GenerateRouteButton.defaultProps = {
  paquerID: undefined,
};

export default GenerateRouteButton;
