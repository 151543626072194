import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackagesWithoutZone = (state) => state[name] || initialState;

export const packagesWithoutZoneItemsSelector = (state) => state[name].items;

export const packagesWithoutZonePaginationSelector = (state) =>
  state[name].pageable;

export const packagesWithoutZonesLoadedSelector = (state) => state[name].loaded;

export const packagesWithoutZonesDateSelector = (state) => state[name].date;

export const packagesWithoutZoneFetchedDatesSelector = (state) =>
  state[name].fetchedDates;

export const packagesWithoutZonesTypeSelector = (state) => state[name].type;

export const packagesWithoutZonesStatusSelector = (state) => state[name].status;

export const packagesWithoutZonesSearchSelector = (state) => state[name].search;

const makeSelectpackagesWithoutZone = () =>
  createSelector(selectPackagesWithoutZone, (substate) => substate);

export default makeSelectpackagesWithoutZone;
