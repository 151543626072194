export const PACKAGE = {
  STATUSES: {
    PENDINGENTRYPAQUERY: 1,
    ARRIVEDATPAQUERYPOINT: 2,
    INHANDSOFPAQUER: 3,
    ONWAY: 4,
    PENDINGSCHEDULE: 5,
    PENDINGPREPARATION: 6,
    ACCEPTEDBYPAQUER: 7,
    AVAILABLEFORPICKUP: 8,
    COLLECTED: 9,
    DELIVEREDPENDINGSIGNATURE: 19,
    DELIVERED: 20,
    CANCELLED: 21,
    LOGGEDINTOPAQUERY: 22,
    PENDINGENTRYPAQUERYPOINT: 23,
    AVAILABLEINSTORE: 24,
    WRONGADDRESS: 25,
    UNREACHABLEZONE: 26,
    DELIVERYCONTINGENCY: 27,
    DAMAGED: 28,
    NOTOPERATED: 29,
    PENDINGPAYMENT: 30,
    DELIVERYATTEMPTONE: 31,
    DELIVERYATTEMPTTWO: 32,
    INRETURNPROCESS: 39,
    RETURNED: 40,
    EXPIRED: 50,
    INGRESSBYEXTERNALOPERATION: 60,
  },
  TYPES: {
    RECEIVE: 1,
    SEND: 2,
    PICKUP: 3,
    DIRECT: 4,
    FLEX: 5,
  },
  SIZES: {
    UNKNOWN: 0,
    SMALL: 1,
    MEDIUM: 2,
    BIG: 3,
    CUSTOM: 4,
  },
};

export const DEPARTURE_PACKAGE_STATUSES = Object.freeze({
  ALL: 1,
  NOTARRIVED: 2,
});

const cancellableStatuses = [
  PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT,
  PACKAGE.STATUSES.DELIVERYATTEMPTONE,
  PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
];

export const isCancellableStatus = (statusId) => {
  return cancellableStatuses.includes(statusId);
};

const finalStatuses = [
  PACKAGE.STATUSES.DELIVERED,
  PACKAGE.STATUSES.CANCELLED,
  PACKAGE.STATUSES.DAMAGED,
  PACKAGE.STATUSES.RETURNED,
];

export const isFinalStatus = (statusId) => {
  return finalStatuses.includes(statusId);
};

const visitStatuses = [
  PACKAGE.STATUSES.DELIVERYATTEMPTONE,
  PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
];

export const isVisitStatus = (statusId) => {
  return visitStatuses.includes(statusId);
};

const readyForDeliveryStatuses = [
  PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT,
  PACKAGE.STATUSES.AVAILABLEINSTORE,
];

export const isReadyForDeliveryStatus = (statusId) => {
  return readyForDeliveryStatuses.includes(statusId);
};

const contactPaquerStatuses = [
  PACKAGE.STATUSES.INHANDSOFPAQUER,
  PACKAGE.STATUSES.ACCEPTEDBYPAQUER,
  PACKAGE.STATUSES.DELIVERYATTEMPTONE,
  PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
];

export const isContactPaquerStatus = (statusId) => {
  return contactPaquerStatuses.includes(statusId);
};

const onTransitUnusedStatuses = [
  PACKAGE.STATUSES.ONWAY,
  PACKAGE.STATUSES.PENDINGSCHEDULE,
  PACKAGE.STATUSES.DELIVEREDPENDINGSIGNATURE,
  PACKAGE.STATUSES.DELIVERED,
  PACKAGE.STATUSES.CANCELLED,
  PACKAGE.STATUSES.WRONGADDRESS,
  PACKAGE.STATUSES.UNREACHABLEZONE,
  PACKAGE.STATUSES.DELIVERYCONTINGENCY,
  PACKAGE.STATUSES.DAMAGED,
  PACKAGE.STATUSES.PENDINGPAYMENT,
  PACKAGE.STATUSES.RETURNED,
  PACKAGE.STATUSES.EXPIRED,
];

export const isOnTransitStatus = (statusId) => {
  return !onTransitUnusedStatuses.includes(statusId);
};

const withPaquersUnusedStatuses = [
  ...onTransitUnusedStatuses,
  PACKAGE.STATUSES.PENDINGENTRYPAQUERY,
  PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT,
  PACKAGE.STATUSES.LOGGEDINTOPAQUERY,
  PACKAGE.STATUSES.PENDINGENTRYPAQUERYPOINT,
  PACKAGE.STATUSES.AVAILABLEINSTORE,
  PACKAGE.STATUSES.INRETURNPROCESS,
];

export const isWithPaquersStatus = (statusId) => {
  return !withPaquersUnusedStatuses.includes(statusId);
};

const arrivableAtPaQueryPointStatuses = [
  PACKAGE.STATUSES.INHANDSOFPAQUER,
  PACKAGE.STATUSES.LOGGEDINTOPAQUERY,
  PACKAGE.STATUSES.CANCELLED,
  PACKAGE.STATUSES.DELIVERED,
  PACKAGE.STATUSES.DELIVERYATTEMPTONE,
  PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
];

export const isArrivableAtPaQueryPointStatus = (statusId) => {
  return arrivableAtPaQueryPointStatuses.includes(statusId);
};
