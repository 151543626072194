import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { authTokenSelector } from 'redux/auth/selectors';
import { Redirect, Route } from 'react-router';
import SITELINKS from 'constants/sitelinks';
import AppRoutes from 'routes/app';

const PrivateRoute = ({ ...otherProps }) => {
  const auth = useSelector(authTokenSelector);
  if (!auth) {
    return <Redirect to={SITELINKS.login} />;
  }
  return <Route {...otherProps} render={(props) => <AppRoutes {...props} />} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
