import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const usersSelector = (state) => state[name] || initialState;

export const usersItemsSelector = (state) => Object.values(state[name].items);

const makeUserSelector = () =>
  createSelector(usersSelector, (substate) => substate);

export default makeUserSelector;
