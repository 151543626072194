import { createSelector } from 'reselect';
import { name, initialState } from './slice';

const selectUserView = (state) => state[name] || initialState;

export const userViewAvatar = (state) => (state[name] || initialState).avatar;
export const userViewLoaded = (state) => (state[name] || initialState).loaded;
export const userviewUserSelector = (state) => state[name].user;

const makeSelectUserView = () =>
  createSelector(selectUserView, (substate) => substate);

export default makeSelectUserView;
