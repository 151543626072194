import React, { useMemo } from 'react';
import { Col, Row, Card, Flex } from 'antd';
import Statistic from 'antd/es/statistic/Statistic';
import dayjs from 'dayjs';
import { Route } from 'interfaces/route';
import PieChart from '../../../../components/pieChart';

interface RouteStatisticProps {
  route: Route;
}

const RouteStatistics = ({ route }: RouteStatisticProps) => {
  const packageTotal = route.packages?.length;
  const ungeoTotal = route.packages?.filter(
    (packet) => !packet.geoLng || !packet.geoLat,
  ).length;
  let sizes = [
    { title: 'Desconocido', value: 0, color: 'lightgrey' },
    { title: 'Chico', value: 0, color: '#597ef7' },
    { title: 'Mediano', value: 0, color: '#389e0d' },
    { title: 'Grande', value: 0, color: '#eb2f96' },
    { title: 'Personalizado', value: 0, color: '#0DB8B2' },
  ];
  if (route.packages)
    route.packages.forEach((packet: any) => {
      sizes[packet.packageSize].value += 1;
    });
  sizes = sizes.filter((size) => size.value > 0);
  let geolocalized = [
    {
      title: 'Geolocalizados',
      value: packageTotal - ungeoTotal,
      color: '#7cb305',
    },
    {
      title: 'Sin geolocalizar',
      value: ungeoTotal,
      color: '#FF9900',
    },
  ];
  geolocalized = geolocalized.filter((size) => size.value > 0);

  const estimatedTime = useMemo(() => {
    const minutesParsed = route.estimatedDeliveryTime
      ? Number.parseFloat(route.estimatedDeliveryTime)
      : 0.0;
    // eslint-disable-next-line eqeqeq
    return minutesParsed == 0
      ? 'Tiempo desconocido'
      : dayjs.duration(minutesParsed, 'minutes').humanize();
  }, [route]);

  return (
    <Col span={24}>
      <Card>
        <Row>
          <Flex align="center" style={{ width: '100%' }}>
            <Col span={12}>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Statistic title="Nro. de ruta" value={route.id} />
                </Col>
                <Col span={12}>
                  <Statistic title="Paquetes" value={packageTotal} />
                </Col>
                <Col span={12}>
                  <Statistic title="Tiempo estimado" value={estimatedTime} />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Distancia estimada"
                    value={
                      route.distance
                        ? `${Number.parseFloat(
                            route.distance?.split(' ')[0],
                          ).toFixed(1)} km`
                        : '---'
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <PieChart title="Tamaños" data={sizes} />
                </Col>
                <Col span={12}>
                  <PieChart title="Geolocalizados" data={geolocalized} />
                </Col>
              </Row>
            </Col>
          </Flex>
        </Row>
      </Card>
    </Col>
  );
};

export default RouteStatistics;
