import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SITELINKS from 'constants/sitelinks';
import { useSelector } from 'react-redux';
import { authTokenSelector } from 'redux/auth/selectors';
import Login from 'routes/login';

const PublicRoute = ({ ...otherProps }) => {
  const auth = useSelector(authTokenSelector);
  if (auth) {
    return <Redirect to={SITELINKS.loggedHome} />;
  }
  return <Route {...otherProps} render={(props) => <Login {...props} />} />;
};

export default PublicRoute;
