import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectGlobals = (state) => state[name] || initialState;

export const countriesGlobalsSelector = (state) => state[name].countries;

export const rolesGlobalsSelector = (state) => state[name].roles;

export const loadedGlobalsSelector = (state) => state[name].loaded;

export const packagesStatusesGlobalsSelector = (state) =>
  state[name].packages.status;

export const currentServicesGlobalsSelector = (state) =>
  state[name].currentServices;

export const dispatchTypesGlobalsSelector = (state) =>
  state[name].dispatchTypes;

export const daysServiceGlobalsSelector = (state) => state[name].daysService;

export const vehicleTypesGlobalsSelector = (state) => state[name].vehicleTypes;

const makeSelectGlobals = () =>
  createSelector(selectGlobals, (substate) => substate);

export default makeSelectGlobals;
