import { name, initialState } from './slice';

export const selectRoutesHistory = (state) => state[name] || initialState;

export const routesHistorySelector = (state) => state[name].routes;

export const routesHistoryPageableSelector = (state) => state[name].pageable;

export const routesHistoryLoadedSelector = (state) => state[name].loaded;

export const routesHistoryErrorSelector = (state) => state[name].error;
