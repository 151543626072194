import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const paquerypointsSelector = (state) => state[name] || initialState;

export const paquerypointsObjectItemSelector = (state) => state[name].items;

export const paquerypointsSearchSelector = (state) => state[name].search;

export const paquerypointsLoadedSelector = (state) => state[name].loaded;

export const paquerypointsPageableSelector = (state) => state[name].pageable;

const makePaquerypointSelector = () =>
  createSelector(paquerypointsSelector, (substate) => substate);

export const paquerypointsItemsSelector = createSelector(
  paquerypointsObjectItemSelector,
  (items) => {
    if (!items) return [];
    return Object.values(items);
  },
);

export const paquerypointsFilteredItemsSelector = createSelector(
  paquerypointsItemsSelector,
  paquerypointsPageableSelector,
  paquerypointsSearchSelector,
  (items, { pageSize, pageNumber }, search) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const storeNameIgnoreCase = item.name.toUpperCase();
        return storeNameIgnoreCase.includes(searchIgnoreCase);
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makePaquerypointSelector;
