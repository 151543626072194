/* eslint-disable no-underscore-dangle */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Progress, Result, Row, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getAmountOfDeliveredPackages,
  getAmountOfPackagesInPaquersHands,
  getAmountOfPackagesNotDelivered,
} from 'services/RouteService';
import './index.css';
import { RoutePackage } from 'interfaces/route.package';
import { Route } from 'interfaces/route';
import { AxiosError } from 'axios';
import PaqueryTable from '@paquery-team/lib-table';
import SITELINKS from 'constants/sitelinks';
import { useDispatch, useSelector } from 'react-redux';
import {
  routesTrackingErrorSelector,
  routesTrackingLoadedSelector,
  routesTrackingPageableSelector,
  routesTrackingSelector,
} from 'redux/routesTracking/selectors';
import { actions } from 'redux/routesTracking/slice';
import useRoutes from 'redux/routesTracking';
import usePaquers from 'redux/paquers';
import { actions as paquerActions } from 'redux/paquers/slice';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import EditRecordButton from 'components/editRecordButton';
import dayjs from 'dayjs';
import { Flex } from 'antd/lib';
import { Paquer } from 'interfaces/paquer';
import Driver from '../routeGenerate/components/Driver';

const getPercentage = (amount: number, total: number | null | undefined) => {
  if (!total) return undefined;
  if (!amount) return 0;
  return Math.round((amount / total) * 100);
};

interface ProgressBarProps {
  route: Route;
}

const ProgressBar = ({ route }: ProgressBarProps) => {
  // Paquetes entregados (20)
  const packagesDelivered = getAmountOfDeliveredPackages(route?.packagesList);

  // Paquetes en poder del paquer (3)
  const packagesInPaquersHands = getAmountOfPackagesInPaquersHands(
    route?.packagesList,
  );
  // Paquetes no entregados, en estado final (en poder del paquer, intentos de entrega (1 y 2), cancelado, y siniestrado)
  const packagesNotDelivered = getAmountOfPackagesNotDelivered(
    route?.packagesList,
  );

  // Porcentaje de paquetes entregados y no entregados
  const percentageStatus =
    getPercentage(
      packagesNotDelivered + packagesDelivered,
      route?.packagesList?.length,
    ) ?? 0;

  // Porcentaje de paquetes entregados
  const percentageDelivered = getPercentage(
    packagesDelivered,
    route?.packagesList?.length,
  );

  return (
    <div>
      <Tooltip
        title={`${packagesDelivered} entregados / ${packagesNotDelivered} no entregados / ${packagesInPaquersHands} en ruta`}
      >
        <Progress
          status="active"
          percent={percentageStatus}
          success={{ percent: percentageDelivered }}
          showInfo={false}
          strokeColor="#ff4d4f"
        />
      </Tooltip>
    </div>
  );
};

const idColumn = {
  title: 'Nro.',
  dataIndex: 'id',
  key: 'id',
  align: 'center',
};

const estimatedDeliveryTimeColumn = {
  title: 'Tiempo estimado',
  render: (minutes: string) => {
    const minutesParsed = Number.parseFloat(minutes);
    // eslint-disable-next-line eqeqeq
    return minutesParsed == 0
      ? 'Tiempo desconocido'
      : dayjs.duration(minutesParsed, 'minutes').humanize();
  },
  dataIndex: 'estimatedDeliveryTime',
  key: 'estimatedDeliveryTime',
  responsive: ['md'],
};

const distanceColumn = {
  title: 'Distancia estimada',
  dataIndex: 'distance',
  render: (distance: string) => {
    const parsedDistance = Number.parseFloat(distance.split(' ')[0]);
    return parsedDistance === 0
      ? 'Distancia desconocida'
      : `${parsedDistance.toFixed(1)} km`;
  },
  key: 'distance',
  responsive: ['lg'],
};

const DriverButton = ({ route }: any) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [driver, setDriver] = useState<Paquer>();

  if (route.drive) {
    return (
      <Flex gap="middle" justify="space-between" align="center">
        {route._paquerName}
        <Button disabled>Transferir</Button>
      </Flex>
    );
  }
  return (
    <>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          if (driver && driver.id) {
            console.log(
              `POST assignDriverToRoute driverId: ${driver.id} routeId: ${route.id}`,
            );
            dispatch(actions.fetchRoutes());
            setOpen(false);
          }
        }}
        okButtonProps={{ disabled: !driver }}
        okText="Asignar"
        cancelText="Cancelar"
        closeIcon={null}
        destroyOnClose
        width={700}
      >
        <Driver driver={driver} setDriver={setDriver} />
      </Modal>
      <Flex justify="end">
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          Asignar
        </Button>
      </Flex>
    </>
  );
};

const driverNameColumn = {
  title: 'Driver',
  key: 'paquerName',
  render: (route: any) => <DriverButton route={route} />,
  width: 400,
  responsive: ['sm'],
};

const packagesAmountColumn = {
  title: 'Paquetes',
  key: 'packagesAmount',
  // El guion bajo indica que es una propiedad que se genera en local, no la trae el backend
  // Revisar el sagas
  dataIndex: '_packagesAmount',
  responsive: ['lg'],
  align: 'center',
  width: '5%',
};

const progressColumn = {
  title: 'Progreso',
  key: 'progress',
  dataIndex: 'progress',
  width: '20%',
  responsive: ['sm'],
  render: (text: string, record: Route) => <ProgressBar route={record} />,
};

const actionsColumn = {
  key: 'detail',
  render: (record: RoutePackage) => (
    <EditRecordButton
      link={`${SITELINKS.routes.list}/view`}
      queryParams={new URLSearchParams({ hasEnded: 'false' })}
      record={record}
    />
  ),
};

const dataColumns = [
  idColumn,
  packagesAmountColumn,
  estimatedDeliveryTimeColumn,
  distanceColumn,
  driverNameColumn,
  progressColumn,
  actionsColumn,
];

const columnsSmallDevice = [...dataColumns];
const columnsMediumDevice = [...dataColumns];
const columnsLargeDevice = [...dataColumns];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const RoutesTracking = () => {
  usePaquers();
  useRoutes();
  const routes: Route[] = useSelector(routesTrackingSelector);
  const paquers = useSelector(paquersItemsSelector);
  const dispatch = useDispatch();
  const error: null | AxiosError = useSelector(routesTrackingErrorSelector);
  const loaded: boolean = useSelector(routesTrackingLoadedSelector);
  const pageable: any = useSelector(routesTrackingPageableSelector);
  const [driverList, setDriverList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    const drivers: any[] = [
      {
        value: '0',
        name: 'Sin driver',
      },
    ];
    if (paquers.length > 0) {
      setDriverList(
        paquers.forEach((paquer: { id: any; name: any; lastName: any }) =>
          drivers.push({
            value: paquer.id,
            name: `${paquer.name} ${paquer.lastName}`,
          }),
        ),
      );
    }
    setDriverList(drivers);
  }, [paquers]);

  const handleFetchRoutes = useCallback(() => {
    dispatch(actions.fetchRoutes());
  }, [dispatch]);

  // This is due <Result/> from antd only supports 403 | 404 | 500 | "error" | "success" | "info" | "warning"
  const getResultStatus = (err: AxiosError<any>) => {
    if (!err.response?.status) return 'error';
    if (
      err.response.status === 404 ||
      err.response.status === 403 ||
      err.response.status === 500
    )
      return err.response.status;
    return 'error';
  };

  useEffect(() => {
    if (routes.length === 0) {
      handleFetchRoutes();
    }
  }, [dispatch, routes.length, handleFetchRoutes]);

  const handleChangePaginate = (page: any) => {
    dispatch(actions.setRoutesPageable(page));
  };

  const extraButtons = [
    <Button type="primary" onClick={handleFetchRoutes}>
      Reintentar
    </Button>,
  ];

  const searchPaquerCallback = useCallback(
    (newPaquerId: string | number) => {
      dispatch(actions.updatePaquer(newPaquerId));
    },
    [dispatch],
  );

  const searchCallback = useCallback(
    (searchText: string) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );

  const selectors = [
    {
      onChange: searchPaquerCallback,
      placeholder: 'Driver',
      list: driverList,
    },
  ];

  const searcher = {
    placeholder: 'Nro. de ruta',
    onSearching: searchCallback,
    numeric: true,
    allowEmptySearch: true,
  };

  return (
    <>
      <Spin indicator={antIcon} tip="Cargando rutas..." spinning={!loaded}>
        <Row gutter={[16, 36]}>
          {/* Estas metricas estan comentadas hasta que se desarrolle un endpoint apropiado para recuperarlas.
            Estas metricas no deberian ser calculadas en el frontend, solo en el backend.  Para evitar que se
            muestre informacion no consistente (Ya que el paginado puede no mostrar todos los registros) */}
          {/* <Col xs={24}>
              <Card>
                <Row gutter={[48, 20]}>
                  <Col xs={24} sm={12} lg={6} xl={5} xxl={4}>
                    <Row gutter={16} justify="space-between">
                      <Statistic title="Rutas iniciadas" value="2" />
                      <Progress
                        style={{ marginTop: '0.5rem' }}
                        type="circle"
                        strokeColor="orange"
                        percent={30}
                        width={70}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} lg={6} xl={5} xxl={4}>
                    <Row
                      gutter={16}
                      justify="space-between"
                      style={{ cursor: 'pointer' }}
                    >
                      <Statistic title="Rutas finalizadas" value="2" />
                      <Progress
                        style={{ marginTop: '0.5rem' }}
                        type="circle"
                        strokeColor="#52c41a"
                        percent={30}
                        width={70}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col> */}
          <Col xs={24}>
            {error && (
              <Result
                status={getResultStatus(error)}
                title="Algo salio mal..."
                subTitle={error.message}
                extra={extraButtons}
              />
            )}
            {!error && (
              <PaqueryTable
                header={{
                  searcher,
                  selectors,
                  refresh: handleFetchRoutes,
                  title: 'Seguimiento',
                }}
                onChangePaginate={handleChangePaginate}
                loading={false}
                dataSource={routes}
                dataColumns={dataColumns}
                paginate={pageable}
                colsForSmallDevice={columnsSmallDevice}
                colsForMediumDevice={columnsMediumDevice}
                colsForLargeDevice={columnsLargeDevice}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default RoutesTracking;
