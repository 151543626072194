import React, { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Affix, Button, Col, Modal, Result, Row, Spin } from 'antd';

import { useHistory, useParams } from 'react-router';
import RouteService from 'services/RouteService';
import axios, { AxiosError } from 'axios';
import { Route } from 'interfaces/route';
import Map from './components/map';
import './index.css';
import RouteData from './components/routeData';
import PackagesData from './components/packagesData';

const { confirm } = Modal;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export interface IRouteProps {
  hasEnded: boolean;
}

const RouteDetail = ({ location }: any) => {
  const hasEnded = location.search.split('=')[1];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [route, setRoute] = useState<Route | null>(null);
  const [loadingEndRoute, setLoadingEndRoute] = useState(false);
  const [endRouteResult, setEndRouteResult] = useState<{
    title: string;
    status: string;
  } | null>(null);
  const params = useParams<{ id: string | undefined }>();
  const history = useHistory();

  const getResultStatus = (err: AxiosError) => {
    if (!err.response?.status) return 'error';
    if (
      err.response?.status === 404 ||
      err.response?.status === 403 ||
      err.response?.status === 500
    )
      return err.response?.status;
    return 'error';
  };

  const handleGetAllRoutes = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const routeID = params.id;
      const routeFetched = await RouteService.getRouteDetailByID(routeID);
      const orderedPackages = routeFetched?.packages?.sort((a, b) => {
        if (a.sequence && b.sequence) {
          return a.sequence - b.sequence;
        }
        return 0;
      });
      routeFetched.packages = orderedPackages;
      setRoute(routeFetched);
    } catch (err: any | AxiosError) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  const endRoute = useCallback(async () => {
    setEndRouteResult(null);
    setLoadingEndRoute(true);
    try {
      const routeID = params.id;
      await RouteService.endRouteByID(routeID);
      setEndRouteResult({
        title: 'La ruta ha sido finalizada',
        status: 'success',
      });
    } catch (err: any | AxiosError) {
      if (axios.isAxiosError(err)) {
        setError(err);
      }
    } finally {
      setLoadingEndRoute(false);
    }
  }, [params.id]);

  useEffect(() => {
    handleGetAllRoutes();
  }, [params, handleGetAllRoutes]);

  const handleEndRoute = () => {
    confirm({
      title: 'Atencion',
      icon: <ExclamationCircleOutlined />,
      content: 'Estas seguro que desas finalizar esta ruta?',
      okText: 'Finalizar',
      okButtonProps: { danger: true },
      onOk: async () => {
        await endRoute();
      },
      cancelText: 'Cancelar',
    });
  };

  if (endRouteResult) {
    const extraButtons = [
      <Button key="buy" onClick={() => history.goBack()}>
        Vover al listado
      </Button>,
    ];
    return (
      <Result
        status="success"
        title={endRouteResult.title}
        extra={extraButtons}
      />
    );
  }

  if (error) {
    const extraButtons = [
      <Button key="buy" onClick={() => history.goBack()}>
        Vover al listado
      </Button>,
      <Button type="primary" key="buy" onClick={() => handleGetAllRoutes()}>
        Reintentar
      </Button>,
    ];
    return (
      <Result
        status={getResultStatus(error)}
        title="Algo salio mal..."
        subTitle={error.message}
        extra={extraButtons}
      />
    );
  }

  return (
    <Spin indicator={antIcon} tip="Cargando la ruta..." spinning={loading}>
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} lg={24}>
          <RouteData route={route} refreshRoute={handleGetAllRoutes} />
        </Col>
        <Col xs={24} lg={11}>
          <PackagesData route={route} hasEnded={hasEnded} />
        </Col>
        <Col xs={24} lg={13}>
          {route && <Map route={route} />}
        </Col>

        <Col>
          <Affix offsetBottom={48}>
            {!hasEnded && (
              <Button
                loading={loadingEndRoute}
                onClick={handleEndRoute}
                style={{ zIndex: 100 }}
                type="primary"
                danger
              >
                Finalizar ruta
              </Button>
            )}
          </Affix>
        </Col>
        <Col>
          <Affix offsetBottom={48}>
            {hasEnded ? (
              <Button
                loading={loadingEndRoute}
                onClick={handleGetAllRoutes}
                style={{ zIndex: 100 }}
                disabled
              >
                Actualizar
              </Button>
            ) : (
              <Button
                loading={loadingEndRoute}
                onClick={handleGetAllRoutes}
                style={{ zIndex: 100 }}
              >
                Actualizar
              </Button>
            )}
          </Affix>
        </Col>
        <Col>
          <Affix offsetBottom={48}>
            <Button style={{ zIndex: 100 }} onClick={() => history.goBack()}>
              Volver
            </Button>
          </Affix>
        </Col>
      </Row>
    </Spin>
  );
};

export default RouteDetail;
