import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const packagesStatusSelector = (state) => state[name].status;

export const packagesTypeSelector = (state) => state[name].type;

export const packagesDateSelector = (state) => state[name].date;
export const packagesMarketFilterSelector = (state) =>
  state[name].marketplaceId;
export const packagesStoreSelector = (state) => state[name].storeId;
export const packagesTermSelector = (state) => state[name].term;

export const selectPackages = (state) => state[name] || initialState;

export const packagesItemsSelector = (state) => state[name].items;

export const packagesPageableSelector = (state) => state[name].pageable;

export const packagesLoadedSelector = (state) => state[name].loaded;

export const packagesSearchSelector = (state) => state[name].search;

const makePackagesBySearchCodeSelector = () =>
  createSelector(selectPackages, (substate) => substate);

export default makePackagesBySearchCodeSelector;
