import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { put, call, select, all, takeLatest } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { actions } from './slice';
import { selectPackages } from './selectors';

const URL = `${API.packages.withPaquers}`;

function* fetchPackageSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize, totalElements },
      search,
      status,
      date,
      paquerId,
      type,
    } = yield select(selectPackages);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      total: totalElements,
      desc: true,
      packageType: type,
      paquerId,
      search,
      status,
      from: date.from,
      to: date.to,
    });
    const request = yield call(getData, `${URL}?${searchParams.toString()}`);
    const payload = {
      items: request.content,
      total: request.totalElements,
    };
    yield put({ type: actions.fetchSuccess.type, payload });
  } catch (error) {
    yield put({ type: actions.fetchFailure.type });
  }
}

export const fetchPackage = async (externalCode, dispatch) => {
  try {
    if (externalCode === null || externalCode === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    dispatch(actions.fetchOnePacket());
    const packet = await getData(
      `${API.packages.getByExternalCode}${externalCode}`,
    );
    if (packet.length === 0) {
      const error = new Error(
        'El paquete con el codigo externo indicado no existe',
      );
      dispatch(actions.fetchOnePacketFailure());
      return error;
    }
    dispatch(actions.fetchOnePacketSuccess());
    return packet[0];
  } catch (error) {
    dispatch(actions.fetchOnePacketFailure());
    return error;
  }
};

function* updateBasefilters() {
  const { status, date } = yield select(selectPackages);

  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

function* addPacketSagas({ payload }) {
  const { items } = yield select(selectPackages);
  const index = items.findIndex((packet) => packet.id === payload.id);
  if (index !== -1 && items[index].status === payload.status) {
    return;
  }
  yield call(updateBasefilters);
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateStatus,
        actions.updateDate,
        actions.updateSearch,
        actions.updatePaquerId,
        actions.updatePackageType,
        actions.updatePageable,
        actions.resetFilters,
        actions.refreshPage,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.addPacket, addPacketSagas),
    takeLatest(actions.fetch, fetchPackageSaga),
  ]);
}
