import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  operators: {},
  destinyID: null,
  removePacketID: null,
  departureType: null,
  search: '',
  loaded: false,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
  },
};

const crossdockingDepartureSlice = createSlice({
  name: 'packageCrossdockingDeparture',
  initialState,
  reducers: {
    initial: () => {},
    fetchOperators: (state) => {
      state.loaded = false;
    },
    operatorsSuccess: (state, action) => {
      state.loaded = true;
      action.payload.forEach((item) => {
        state.operators[item.id] = item;
      });
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload;
    },
    failure: (state) => {
      state.loaded = true;
    },
    dispatchPackages: (state) => {
      state.loaded = false;
    },
    dispatchPackagesSuccess: (state) => {
      state.loaded = true;
    },
    removePacket: (state, action) => {
      state.removePacketID = action.payload;
    },
    removePacketSuccess: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    assignPacket: (state, action) => {
      state.assignPacketID = action.payload;
    },
    updatePackets: (state, action) => {
      state.items = action.payload;
    },
    addPackage: (state, action) => {
      state.items.unshift(action.payload);
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateDestiny: (state, action) => {
      state.destinyID = action.payload;
    },
    updateDepartureType: (state, action) => {
      state.departureType = action.payload;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    resetRemovePacketID: (state) => {
      state.removePacketID = initialState.removePacketID;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    reset: (state) => {
      state.items = initialState.items;
    },
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = crossdockingDepartureSlice;
