/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Flex,
  Button,
  notification,
  message,
  Modal,
  Tour,
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import useCheckDesktop from 'hooks/useIsDesktop';
import 'react-leaflet-fullscreen/dist/styles.css';
import { getZoneFromAddress } from 'constants/operations';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import dayjs from 'dayjs';
import Driver from './components/Driver';
import PackageInput from './components/PackageInput';
import RouteStatistics from './components/RouteStatistics';
import RouteSemaphore from './components/RouteSemaphore';
import RoutePackages from './components/RoutePackages';
import Map from './components/Map';
import Result from './components/Result';

const RouteGenerate = () => {
  const isDesktop = useCheckDesktop();
  const profile = useSelector(authProfile);
  const [driver, setDriver] = useState();

  const [route, setRoute] = useState({
    level: null,
    packages: [],
    distance: '',
    estimatedDeliveryTime: '',
  });
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState();
  const [center, setCenter] = useState([-34.6, -58.4]);
  const [tourOpen, setTourOpen] = useState(false);

  const recenter = (newCenter) => {
    setCenter([newCenter.lat, newCenter.lng]);
  };

  const resolvePackageGeolocation = async (packet, address) => {
    if (!address) return;
    try {
      const resolvedAddress = await getZoneFromAddress(
        address,
        profile.logisticOperatorID,
      );
      console.log('PUT package id: ', packet.id);
      const packetOrder = route.packages.findIndex((p) => p.id === packet.id);
      const newList = route.packages.filter((p) => p.id !== packet.id);
      const newPacket = {
        ...packet,
        geoAddress: address,
        geoLat: resolvedAddress.lat,
        geoLng: resolvedAddress.lng,
      };
      newList.splice(packetOrder, 0, newPacket);
      recenter({ lat: newPacket.geoLat, lng: newPacket.geoLng });
      setRoute({ ...route, packages: newList });
      message.success('Paquete geolocalizado correctamente');
    } catch (error) {
      notification.error({
        message: 'Error de geolocalización',
        description:
          'Ha ocurrido un error intentando geolocalizar el paquete. Intente de nuevo más tarde.',
      });
    }
  };

  const getRoute = useCallback(() => {
    setLoading(true);
    setResult();
    setDriver();
    console.log('GET activeRoute userId: ', profile.id);
    const newRoute = {
      id: 109,
      distance: '97.746796 km',
      estimatedDeliveryTime: '97.848335',
      level: 0,
      packages: [
        {
          id: 1,
          externalCode: '123123',
          packageSize: 1,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 2,
          externalCode: '123124',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 3,
          externalCode: '123125',
          packageSize: 2,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 4,
          externalCode: '123126',
          packageSize: 3,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 5,
          externalCode: '123127',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 6,
          externalCode: '123128',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 7,
          externalCode: '123129',
          packageSize: 3,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 8,
          externalCode: '123130',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 9,
          externalCode: '123129',
          packageSize: 3,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 10,
          externalCode: '123130',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 11,
          externalCode: '123129',
          packageSize: 3,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 12,
          externalCode: '123130',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
        {
          id: 13,
          externalCode: '123129',
          packageSize: 3,
          geoAddress: 'av. mayo 1350',
          geoLat: null,
          geoLng: null,
        },
        {
          id: 14,
          externalCode: '123130',
          packageSize: 2,
          geoAddress: 'av. de mayo 1350',
          geoLat: -34.6095732,
          geoLng: -58.3880109,
        },
      ],
    };
    console.log('Response payload: ', newRoute);
    setRoute(newRoute);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoute();
  }, [getRoute]);

  const removePackage = (packageId) => {
    console.log(
      `PUT removePackage routeId: ${route.id} packageId: ${packageId}`,
    );
  };

  const handleReady = () => {
    console.log('PUT readyRoute id: ', route.id);
    if (driver) {
      console.log(
        `PUT assignDriver routeId: ${route.id} driverId: ${driver.id}`,
      );
      setResult({
        status: 'success',
        title: `Ruta ${route.id} lista para operación`,
        subTitle: (
          <p>
            Fecha y hora: {dayjs().format('DD/MM/YY HH:mm:ss')}
            <br />
            Driver: {driver.email}
          </p>
        ),
        extraButtons: [
          <Button type="primary">Ver ruta</Button>,
          <Button onClick={getRoute}>Nueva ruta</Button>,
        ],
      });
    } else {
      setResult({
        status: 'success',
        title: `Ruta ${route.id} lista para operación`,
        subTitle: (
          <p>
            Fecha y hora: {dayjs().format('DD/MM/YY HH:mm:ss')}
            <br />
            La ruta se ha generado sin driver, ¿desea asignarlo ahora?
          </p>
        ),
        extraButtons: [
          <Button type="primary">Asignar driver</Button>,
          <Button onClick={getRoute}>Nueva ruta</Button>,
        ],
      });
    }
  };

  const handleDestroy = () => {
    Modal.confirm({
      title: '¿Desea deshacer la ruta?',
      icon: <DeleteOutlined />,
      content: 'Se liberaran los paquetes y se creará una ruta nueva vacía.',
      okText: 'Deshacer',
      okButtonProps: { danger: true },
      cancelText: 'Cancelar',
      onOk() {
        getRoute();
        console.log('DELETE route id: ', route.id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const refInput = useRef(null);

  const steps = [
    {
      title: 'Titulo',
      description: 'Descripcion',
      target: () => refInput.current,
    },
  ];

  return (
    <Result result={result}>
      <Row gutter={[16, 16]} justify="end">
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Row gutter={[0, 16]}>
                <PackageInput ref={refInput} />
                {isDesktop && (
                  <RouteStatistics loading={loading} route={route} />
                )}
              </Row>
            </Col>
            {isDesktop && (
              <>
                <Col xs={24} md={2}>
                  <RouteSemaphore loading={loading} level={route?.level} />
                </Col>
                <Col xs={24} md={10}>
                  <Card
                    style={{ height: '100%' }}
                    bodyStyle={{ padding: 13, height: '100%' }}
                  >
                    <Driver driver={driver} setDriver={setDriver} />
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <RoutePackages
                editAddressCallback={resolvePackageGeolocation}
                recenterCallback={recenter}
                removePackageCallback={removePackage}
                loading={loading}
                packages={route?.packages}
              />
            </Col>
            {isDesktop && (
              <Col span={12} style={{ height: '100%' }}>
                <Map packages={route?.packages} center={center} />
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <Flex gap={8}>
            <Button
              icon={<InfoCircleOutlined />}
              onClick={() => setTourOpen(true)}
            />
            <Button danger onClick={handleDestroy}>
              Deshacer ruta
            </Button>
            <Button htmlType="submit" type="primary" onClick={handleReady}>
              Listo
            </Button>
          </Flex>
        </Col>
      </Row>
      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
    </Result>
  );
};

export default RouteGenerate;
