import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  marketplaceId: null,
  status: '',
  search: '',
  type: '',
  date: {
    from: '',
    to: '',
  },
};

const packagesWithoutZoneSlice = createSlice({
  name: 'packagesWithoutZone',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
    },
    failure: (state) => {
      state.loaded = true;
    },
    // Esto es porque components/paquerSelector utiliza este action en orden de editar el paquer y luego updatearlo con un PUT en paquerSettingsButton
    updatePackagePaquer: (state, action) => {
      const { packageId, selectedPaquer } = action.payload;
      const index = state.items.findIndex((item) => item.id === packageId);
      if (index > -1) {
        state.items[index].shippingScheduleDestination.driver = selectedPaquer;
      }
    },
    updatePagination: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePackageType: (state, action) => {
      state.type = action.payload;
      state.pageable.pageNumber = 0;
    },
    resetFetchedDates: (state) => {
      state.fetchedDates = {};
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
    // Esto es porque PaquerSettingsButton puede (o no) modificar el estado del paquete. Se le delega la responsabilidad al sagas
    addPacket: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = packagesWithoutZoneSlice;
