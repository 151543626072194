import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  routes: [],
  loaded: true,
  error: null,
  paquerId: '',
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  search: '',
};

const routes = createSlice({
  name: 'routesTracking',
  initialState,
  reducers: {
    setRoutesPageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    fetchRoutes: (state) => {
      state.loaded = false;
      state.error = null;
    },
    fetchRoutesSuccess: (state, action) => {
      state.routes = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    fetchRoutesFailure: (state, action) => {
      state.loaded = true;
      state.error = action.payload;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    updatePaquer: (state, action) => {
      state.paquerId = action.payload;
      state.pageable.pageNumber = 0;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
      state.paquerId = initialState.paquerId;
    },
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = routes;
