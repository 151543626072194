import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { Buffer } from 'buffer';
import App from './app';
import './index.css';
import 'app/i18n';
import 'util/dayjs';

// @ts-ignore
window.Buffer = Buffer;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
