import { createSlice } from '@reduxjs/toolkit';
import { DEPARTURE_PACKAGE_STATUSES } from 'constants/packages';

export const initialState = {
  items: [],
  departurePackageStatus: DEPARTURE_PACKAGE_STATUSES.ALL,
  loaded: false,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalElements: 0,
  },
};

const packageDispatchViewSlice = createSlice({
  name: 'dispatchView',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.totals;
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = true;
    },
    setDeparturePackageStatus: (state, action) => {
      state.departurePackageStatus = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { name, actions, reducer } = packageDispatchViewSlice;
