import { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch } from 'react-redux';
import { name, reducer, actions } from './slice';
import saga from './saga';

export default function useRoutesHistory() {
  const dispatch = useDispatch();
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useEffect(() => {
    dispatch(actions.resetFilters());
  }, [dispatch]);
}
