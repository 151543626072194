export const DEPARTURE_TYPE_OPTIONS = Object.freeze({
  INTERIOR: 10,
  PAQUERYPOINT: 20,
  MARKETPLACE: 30,
});

export const INTERIOR_DESTINATION_OPTIONS = [
  {
    id: 27,
    name: 'Andesmar Cargas',
  },
];
