import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, shallowEqual } from 'react-redux';
import makePaquerSelector from './selectors';
import { name, reducer } from './slice';
import saga from './saga';

export default function usePaquers() {
  useInjectReducer({ key: name, reducer });
  useInjectSaga({ key: name, saga });
  useSelector(makePaquerSelector(), shallowEqual);

  return null;
}
