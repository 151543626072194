import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackagesHistory = (state) => state[name] || initialState;

export const packagesHistoryItemsSelector = (state) => state[name].items;

export const packagesHistoryPaginationSelector = (state) =>
  state[name].pageable;

export const packagesHistoryLoadedSelector = (state) => state[name].loaded;

export const packagesHistoryDateSelector = (state) => state[name].date;

export const packagesHistoryMarketplaceSelector = (state) =>
  state[name].marketplaceId;

export const packagesHistoryStoreSelector = (state) => state[name].storeId;

export const packagesHistoryTermSelector = (state) => state[name].term;

export const packagesHistoryFetchedDatesSelector = (state) =>
  state[name].fetchedDates;

export const packagesHistoryTypeSelector = (state) => state[name].type;

export const packagesHistoryStatusSelector = (state) => state[name].status;

export const packagesHistorySearchSelector = (state) => state[name].search;

const makeSelectPackagesHistory = () =>
  createSelector(selectPackagesHistory, (substate) => substate);

export default makeSelectPackagesHistory;
