/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  notification,
  Result,
  Row,
  Tooltip,
  theme,
} from 'antd';
import Modal from 'components/modal';
import { isContactPaquerStatus } from 'constants/packages';
import API from 'constants/api';
import rest, { getData } from 'util/Api';
import Avatar from 'antd/lib/avatar/avatar';
import PaquerSelector from 'components/paquerSelector';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import dayjs from 'dayjs';
import {
  countriesGlobalsSelector,
  selectGlobals,
} from 'redux/globals/selectors';
import { buildPutPackage } from 'constants/operations';
import { useDispatch, useSelector } from 'react-redux';
import UnassignPaquerButton from 'components/unassignPaquerButton';

const compareFromDates = (dateA, dateB) => {
  const fromA = dateA.creationDate;
  const fromB = dateB.creationDate;

  if (dayjs(fromB).isBefore(fromA)) {
    return 0;
  }
  if (dayjs(fromB).isSame(fromA)) {
    return 0;
  }
  return 1;
};

const SPLITTER = '/';
const ON_PAQUERY_STATUS = 22;
const ON_HANDS_PAQUER_STATUS = 3;
const ARRIVED_AT_PAQUERYPOINT_STATUS = 2;

const getZoneDistributionID = (zoneId, distributionZoneID) =>
  `${zoneId}${SPLITTER}${distributionZoneID}`;

const fetchPackageById = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    const packet = await getData(`${API.packages.getById}${id}`);
    return packet;
  } catch (error) {
    if (error.message.includes(404)) {
      return new Error('No se ha encontrado el paquete con el id solicitado');
    }
    return error;
  }
};

const PaquerSettingsButton = ({
  packageId: rowPackage,
  isWithPaquerListing,
  isWithoutZone,
  isBySearchCode,
  actions,
}) => {
  const [visible, setVisible] = useState();
  const paquers = useSelector(paquersItemsSelector);
  const globals = useSelector(selectGlobals);
  const countries = useSelector(countriesGlobalsSelector);
  const dispatch = useDispatch();
  const driver = rowPackage.shippingScheduleDestination?.driver;
  const [packet, setPacket] = useState(null);
  const [errors, setErrors] = useState(null);
  const { token } = theme.useToken();

  const getPacket = useCallback(
    async (fetch, data) => {
      const interfacePackage = async (_packet) => {
        try {
          const zoneID =
            _packet.shippingScheduleDestination.zone &&
            _packet.shippingScheduleDestination.distributionZone &&
            getZoneDistributionID(
              _packet.shippingScheduleDestination.zone?.id,
              _packet.shippingScheduleDestination.distributionZone?.id,
            );
          const resultPackage = {
            ..._packet,
            shippingScheduleOrigin: {
              ..._packet.shippingScheduleOrigin,
            },
            shippingScheduleDestination: {
              ..._packet.shippingScheduleDestination,
              destinationEmail:
                _packet.shippingScheduleDestination.destinationEmail ||
                _packet.user.email,
              zoneID,
            },
            logStatusPackages:
              _packet.logStatusPackages?.sort(compareFromDates),
            logShipping: _packet.logShipping?.sort(compareFromDates),
            onHandsOfPaquerDate: _packet.logStatusPackages?.find(
              (log) => log.nextStatus === ON_HANDS_PAQUER_STATUS,
            )?.creationDate,
            onPaqueryDate: _packet.logStatusPackages?.find(
              (log) => log.nextStatus === ON_PAQUERY_STATUS,
            )?.creationDate,
            arrivedAtPaqueryPointDate:
              _packet.arrivedAtPaqueryPointDate ||
              _packet.logStatusPackages?.find(
                (log) => log.nextStatus === ARRIVED_AT_PAQUERYPOINT_STATUS,
              )?.creationDate,
          };
          return resultPackage;
        } catch (error) {
          return error;
        }
      };
      const packetResponse = await fetch(data);
      if (packetResponse instanceof Error) {
        setErrors(packetResponse.message);
      } else {
        const convertedPackage = await interfacePackage(packetResponse);
        if (convertedPackage instanceof Error) {
          return setErrors(convertedPackage.message);
        }
        setPacket(convertedPackage);
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globals.packages.status, countries],
  );

  const handleOpenModal = () => {
    if (rowPackage.id) {
      getPacket(fetchPackageById, rowPackage.id);
    }
    setVisible(true);
  };

  if (errors) return <Result status="warning" title={errors} />;
  const handleModifyPaquer = async (newStatus, driverID) => {
    const putPackage = buildPutPackage(packet);
    const builtPackage = {
      ...putPackage,
      status: newStatus || putPackage.status,
      shippingScheduleOrigin: {
        ...putPackage.shippingScheduleOrigin,
        driver,
        driverID,
      },
      shippingScheduleDestination: {
        ...putPackage.shippingScheduleDestination,
        driver,
        driverID,
      },
    };
    try {
      const response = await rest.put(
        `${API.packages.get}/${builtPackage.id}`,
        builtPackage,
      );
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Modificación de paquete correcto',
          description: `El paquete ${packet.externalCode} ha sido modificado correctamente.`,
        });
        setVisible(false);
        if (actions) {
          dispatch(actions.addPacket(builtPackage));
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al modificar el paquete${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };

  if (!driver) {
    return (
      <Tooltip title="El paquete no tiene un paquer asignado">
        <Button type="link" disabled>
          <UserOutlined style={{ color: 'lightgrey', fontSize: 18 }} />
        </Button>
      </Tooltip>
    );
  }

  return (
    isContactPaquerStatus(rowPackage.status) && (
      <div>
        <Button type="link" onClick={handleOpenModal}>
          <UserOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
        </Button>
        {packet && (
          <Modal
            title="Paquer asignado:"
            open={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            style={{ textAlign: 'center' }}
          >
            <Row
              align="middle"
              justify="center"
              style={{ textAlign: 'start', marginTop: 20 }}
            >
              <Col xs={6} style={{ height: '100%' }}>
                <Avatar
                  size="large"
                  icon={
                    <UserOutlined style={{ margin: '20%', fontSize: 60 }} />
                  }
                  shape="square"
                  style={{ width: '100px', height: '100px' }}
                  src={driver?.avatar}
                />
              </Col>
              <Col xs={18}>
                <Row>
                  <Col xs={8} style={{ fontWeight: 600 }}>
                    Nombre:
                  </Col>
                  <Col xs={16}>{driver?.name}</Col>
                </Row>
                <Divider style={{ margin: '8px 0px ' }} />
                <Row>
                  <Col xs={8} style={{ fontWeight: 600 }}>
                    Apellido:
                  </Col>
                  <Col xs={16}>{driver?.lastName}</Col>
                </Row>
                <Divider style={{ margin: '8px 0px ' }} />
                <Row>
                  <Col xs={8} style={{ fontWeight: 600 }}>
                    Email:
                  </Col>
                  <Col xs={16}>{driver?.email}</Col>
                </Row>
              </Col>
              <Col xs={24} style={{ fontWeight: 600, margin: 20 }}>
                Modificar Paquer:
              </Col>
              <Col xs={24}>
                <PaquerSelector
                  options={paquers}
                  isWithPaquerListing={isWithPaquerListing}
                  isWithoutZone={isWithoutZone}
                  isBySearchCode={isBySearchCode}
                  defaultValue={driver?.id}
                  packageId={rowPackage.id}
                />
                <Button
                  type="primary"
                  style={{ margin: '0 2vw' }}
                  onClick={() => handleModifyPaquer(undefined, driver?.id)}
                >
                  Guardar
                </Button>
                <UnassignPaquerButton
                  packet={packet}
                  onFinish={() => {
                    setVisible(false);
                    if (actions) {
                      dispatch(actions.refreshPage());
                    }
                  }}
                  size="large"
                />
              </Col>
            </Row>
          </Modal>
        )}
      </div>
    )
  );
};

PaquerSettingsButton.propTypes = {
  packageId: PropTypes.shape({
    id: PropTypes.number,
    shippingScheduleOrigin: PropTypes.shape({
      driver: PropTypes.shape({
        id: PropTypes.number,
        avatar: PropTypes.string,
        name: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  }).isRequired,
  isWithPaquerListing: PropTypes.bool,
  isWithoutZone: PropTypes.bool,
  isBySearchCode: PropTypes.bool,
  actions: PropTypes.shape({
    addPacket: PropTypes.func,
    refreshPage: PropTypes.func,
  }),
};

PaquerSettingsButton.defaultProps = {
  isWithPaquerListing: false,
  isWithoutZone: false,
  isBySearchCode: false,
  actions: undefined,
};

export default PaquerSettingsButton;
