import { name, initialState } from './slice';

export const selectRoutesTracking = (state) => state[name] || initialState;

export const routesTrackingSelector = (state) => state[name].routes;

export const routesTrackingPageableSelector = (state) => state[name].pageable;

export const routesTrackingLoadedSelector = (state) => state[name].loaded;

export const routesTrackingErrorSelector = (state) => state[name].error;
