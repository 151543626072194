import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import API from 'constants/api';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const DownloadLabelButton = ({ packageId, externalCode }) => {
  return (
    <Tooltip title="Imprimir etiqueta">
      <Button
        type="primary"
        href={`${API.packages.label}${packageId}`}
        download={`Etiqueta-${externalCode}.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        icon={<VerticalAlignBottomOutlined />}
      />
    </Tooltip>
  );
};

DownloadLabelButton.propTypes = {
  packageId: PropTypes.number.isRequired,
  externalCode: PropTypes.string.isRequired,
};

export default DownloadLabelButton;
