import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectDispatchView = (state) => state[name] || initialState;

export const itemsSelector = (state) => state[name].items;

export const departureTypeSelector = (state) => state[name].departureType;

export const searchExternalCodeSelector = (state) =>
  state[name].searchExternalCode;

export const originSelector = (state) => state[name].origin;

export const destinationSelector = (state) => state[name].destination;

export const dateSelector = (state) => state[name].date;

export const loadedSelector = (state) => state[name].loaded;

export const departurePackageStatusSelector = (state) =>
  state[name].departurePackageStatus;

export const pageableSelector = (state) => state[name].pageable;

const makeSelectDispatchView = () =>
  createSelector(selectDispatchView, (substate) => substate);

export default makeSelectDispatchView;
