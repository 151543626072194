/* eslint-disable import/no-named-as-default-member */
import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
} from 'react';
import dayjs from 'dayjs';
import JsDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import PaqueryTable from '@paquery-team/lib-table';
import {
  Row,
  Col,
  Select,
  Input,
  notification,
  Button,
  Alert,
  theme,
} from 'antd';
import usePaquers from 'redux/paquers';
import { actions } from 'redux/paquers/slice';
import {
  CarryOutOutlined,
  DownCircleOutlined,
  FieldTimeOutlined,
  IdcardOutlined,
  LoadingOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import {
  paquersItemsSelector,
  paquersLoadedSelector,
  paquersPackagesLoaded,
  paquersPackagesSearch,
  paquersPackageFilteredPagedItems,
  paquersPaquerIdSelector,
  paquersCountedTotalizersSelector,
} from 'redux/paquers/selectors';
import { selectGlobals } from 'redux/globals/selectors';
import rest from 'util/Api';
import { useDebounce } from 'util/Hooks';
import {
  DEFAULT_PAGINATE,
  STATUS_PACKAGE_RESPONSE,
  PACKET_VALUE_THRESHOLD,
} from 'constants/defaultValues';
import { PACKAGE } from 'constants/packages';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { fetchPackageWithExactExtCode } from 'constants/operations';
import externalCodeColumn from 'components/tables/columns';
import useMarketplaces from 'redux/marketplaces';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import PackageSelectModal from 'components/packageSelectModal';
import QRScanner from 'components/QRScanner';
import UnassignPaquerButton from 'components/unassignPaquerButton';
import EditRecordButton from 'components/editRecordButton';
import Card from 'components/card';
import StatusPackage from './components/statusPackage';
import {
  useStylesSelectPaquer,
  useStylesDeparturePacket,
  useStylesLoadingPaquers,
} from './styles';
import EstimatedMoneyAmmount from './components/EstimatedMoneyAmmount';
import PackageView from '../packageView';
import GenerateRouteButton from './components/GenerateRouteButton';

const { Option } = Select;
const { Search } = Input;

const WAIT_INPUT_EXTERNAL_CODE = 1500;
const PleaseSelectPaquer = () => {
  const classes = useStylesSelectPaquer();
  return (
    <div className={classes.container}>
      <img src="/logo.svg" alt="paquery-logo" className={classes.paqueryLogo} />
      <span className={classes.selectQuote}>
        Selecciona un paquer por favor
      </span>
    </div>
  );
};

const LoadingPaquers = () => {
  const classes = useStylesLoadingPaquers();
  return (
    <div className={classes.container}>
      <LoadingOutlined className={classes.loadingIcon} />
      <span className={classes.loadingText}>Estamos cargando los paquers</span>
    </div>
  );
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  align: 'center',
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
  align: 'center',
};

const unassignPacketColumn = {
  align: 'center',
  render: (_, record) => <UnassignPaquerButton packet={record} size="small" />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton link={`${SITELINKS.packages.list}/id`} record={record} />
  ),
};

const dataColumns = [
  externalCodeColumn,
  scheduledDateColumn,
  statusColumn,
  unassignPacketColumn,
  editColumn,
];

const columnsLargeDevice = dataColumns;

const actionsStatusPackage = {
  LOAD: 'LOAD',
  LOADED: 'LOADED',
  UPDATE_SUCCESS_PACKAGE: 'UPDATE_SUCCESS_PACKAGE',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_EXTERNAL_CODE: 'UPDATE_EXTERNAL_CODE',
  UPDATE_PAQUER_NAME: 'UPDATE_PAQUER_NAME',
  ERROR: 'ERROR',
  RESET: 'RESET',
  HIDE_STATUS: 'HIDE_STATUS',
  SHOW_STATUS: 'SHOW_STATUS',
};

const initialStateStatusPackage = {
  show: false,
  loading: false,
  status: null,
  externalCode: null,
  paquerName: null,
  error: null,
  packageStatus: null,
};

const reducerStatusPackage = (state, action) => {
  switch (action.type) {
    case actionsStatusPackage.HIDE_STATUS:
      return {
        ...state,
        show: false,
      };
    case actionsStatusPackage.LOAD:
      return {
        ...state,
        loading: true,
        show: true,
      };
    case actionsStatusPackage.LOADED:
      return {
        ...state,
        loading: false,
      };
    case actionsStatusPackage.UPDATE_SUCCESS_PACKAGE:
      return {
        ...state,
        status: STATUS_PACKAGE_RESPONSE.OK,
        externalCode: action.payload.externalCode,
        paquerName: action.payload.paquerName,
        packageStatus: action.payload.packageStatus,
      };
    case actionsStatusPackage.UPDATE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case actionsStatusPackage.UPDATE_EXTERNAL_CODE:
      return {
        ...state,
        externalCode: action.payload,
      };
    case actionsStatusPackage.UPDATE_PAQUER_NAME:
      return {
        ...state,
        paquerName: action.payload,
      };
    case actionsStatusPackage.ERROR:
      return {
        ...state,
        status: STATUS_PACKAGE_RESPONSE.ERROR,
        error: action.payload,
      };
    case actionsStatusPackage.RESET: {
      return initialStateStatusPackage;
    }
    default:
      return state;
  }
};

const DeparturePacket = () => {
  usePaquers();
  useMarketplaces({ initialize: true });
  const externalCodeInput = useRef(null);
  const { token } = theme.useToken();
  const classes = useStylesDeparturePacket({ theme: token });
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const [stateStatusPackage, dispatchStatusPackage] = useReducer(
    reducerStatusPackage,
    initialStateStatusPackage,
  );
  const [assignPacket, setAssignPacket] = useState('');
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageModal, setPackageModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [externalCodeInputValue, setExternalCodeInputValue] = useState('');
  const paquersLoaded = useSelector(paquersLoadedSelector);
  const packageLoaded = useSelector(paquersPackagesLoaded);
  const paquerSelected = useSelector(paquersPaquerIdSelector);
  const paquers = useSelector(paquersItemsSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const { items: packages, pageable } = useSelector(
    paquersPackageFilteredPagedItems,
  );
  const search = useSelector(paquersPackagesSearch);
  const totalizers = useSelector(paquersCountedTotalizersSelector);
  const debouncedAssignPacket = useDebounce(
    assignPacket,
    WAIT_INPUT_EXTERNAL_CODE,
  );
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    if (externalCodeInput.current) {
      externalCodeInput.current.focus();
    }
  }, [paquerSelected]);

  const getPackage = async (externalCode) => {
    const response = await fetchPackageWithExactExtCode(externalCode);
    if (response instanceof Error) {
      setPackageOptions([]);
      setAssignPacket('');
      if (externalCodeInputValue !== '') {
        setExternalCodeInputValue('');
      }
      return notification.error({
        message: 'Ha ocurrido un error al encontrar el paquete',
        description: `No se ha encontrado el paquete con el código ${externalCode}`,
      });
    }
    return response;
  };

  const departurePacket = async ({ code, externalCode }, extCode) => {
    try {
      dispatchStatusPackage({ type: actionsStatusPackage.LOAD });
      let isBundle = false;
      if (extCode) {
        const isBundleParams = new URLSearchParams({ externalCode: extCode });
        const isBundleResponse = await rest.apiAxios.get(
          `${API.packages.isPackageBundle}?${isBundleParams.toString()}`,
        );
        isBundle = isBundleResponse.data.data;
      }
      const payload = {
        paquerId: parseInt(paquerSelected, 10),
        searchRolled: isBundle ? extCode : code,
      };
      const searchParams = new URLSearchParams(payload);
      const response = await rest.apiAxios.put(
        `${API.paquer.assign}?${searchParams.toString()}`,
      );
      if (rest.isSuccessResponse(response)) {
        dispatch(actions.removePacket(externalCode));
        const paquer = paquers.find(
          (paquerInList) => paquerInList.id === paquerSelected,
        );
        const packet = response.data.data;
        dispatchStatusPackage({
          type: actionsStatusPackage.UPDATE_SUCCESS_PACKAGE,
          payload: {
            externalCode,
            paquerName: `${paquer.name} ${paquer.lastName}`,
            packageStatus: packet.status,
          },
        });
        if (packet.estimatedCost > PACKET_VALUE_THRESHOLD) {
          notification.warning({
            message: 'Notificación de valor',
            description: `El paquete con codigo externo ${externalCode} tiene un valor elevado.`,
            className: classes.highValueNotificationContainer,
            placement: 'topLeft',
            icon: <div />,
          });
        }
        dispatch(actions.addPacket(packet));
        setPackageModal(false);
      } else {
        throw new Error(response.data.message);
      }
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ERROR: ', error);
      dispatchStatusPackage({
        type: actionsStatusPackage.ERROR,
        payload: error.message,
      });
      return notification.error({
        message: 'No se pudo asignar el paquete',
        description: `Por favor seleccione otro paquete${
          error.message ? `, error: ${error.message}` : '.'
        }`,
      });
    } finally {
      setAssignPacket('');
      setFetching(false);
      if (externalCodeInputValue !== '') {
        setExternalCodeInputValue('');
      }
      dispatchStatusPackage({
        type: actionsStatusPackage.LOADED,
      });
    }
  };

  useEffect(() => {
    const assignPacketRequest = async (externalCode) => {
      setFetching(true);
      let receivedExternalCode = externalCode.trim();
      // Check if the typed extCode is a scanned QR
      try {
        const qrCode = JSON.parse(externalCode.trim());
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // not QR and it's okay
      }
      const fetchedPackets = await getPackage(receivedExternalCode);
      if (!fetchedPackets) {
        setFetching(false);
        return;
      }
      if (fetchedPackets.length === 1) {
        departurePacket(...fetchedPackets, externalCode);
        return;
      }
      const filteredPackages = fetchedPackets.filter(
        (packet) =>
          packet.status === PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT ||
          packet.status === PACKAGE.STATUSES.ACCEPTEDBYPAQUER ||
          packet.status === PACKAGE.STATUSES.INGRESSBYEXTERNALOPERATION,
      );
      if (filteredPackages.length === 1) {
        departurePacket(...filteredPackages);
        return;
      }
      const suggestedPackageOptions = filteredPackages.map((packet) => {
        return {
          ...packet,
          marketplace: marketplaces.find((mkp) => mkp.id === packet.ownerID)
            ?.name,
          destinationAddress:
            packet.shippingScheduleDestination.shippingAddress.addressDetail,
          statusDescription: globals.packages.status.find(
            (state) => state.value === packet.status,
          ).name,
        };
      });
      if (suggestedPackageOptions.length === 0) {
        notification.info({
          title: 'Información',
          message: `No se ha encontrado el paquete con código externo: ${receivedExternalCode}`,
        });
        setPackageOptions([]);
        setFetching(false);
        return;
      }
      setFetching(false);
      setPackageModal(true);
      setPackageOptions(suggestedPackageOptions);
    };
    if (debouncedAssignPacket !== '') {
      dispatchStatusPackage({ type: actionsStatusPackage.RESET });
      assignPacketRequest(debouncedAssignPacket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAssignPacket, paquerSelected, paquers]);

  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);

  const updatePaginate = useCallback(
    (newPagination) => {
      dispatch(actions.updatePagination(newPagination));
    },
    [dispatch],
  );

  const updateSearch = useCallback(
    (newSearch) => {
      dispatch(actions.updatePackagesSearch(newSearch));
    },
    [dispatch],
  );

  const handleExportCsv = async () => {
    setDisabled(true);
    const paquerId = parseInt(paquerSelected, 10);
    try {
      const searchParams = new URLSearchParams({
        search,
        paquerId,
      });
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.reports.packagesOwnedByPaquer}?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const paquerFilteredName = paquerId
        ? `${paquers.find((paquerOnList) => paquerOnList.id === paquerId).name}`
        : 'Paquer';
      const filename = `Paquetes-Por-${paquerFilteredName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(response.data, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searcher = {
    onSearching: updateSearch,
    placeholder: 'Código externo',
    allowEmptySearch: true,
  };

  const optionsPaquers = paquers.map((paquer) => (
    <Option value={paquer.id}>{`${paquer.name} ${paquer.lastName}`}</Option>
  ));

  let filteredPackages;
  if (packages) {
    filteredPackages = packages.map((item) => ({
      ...item,
      key: item.id,
      arrivedAtPaqueryPointDate: dayjs(item.arrivedAtPaqueryPointDate)
        .tz()
        .format(TableDateTimeFormat),
      externalCode: item.externalCode,
      destinationAddress:
        item.shippingScheduleDestination.shippingAddress.addressDetail,
      scheduledDate: item.shippingScheduleDestination.scheduledDate
        ? dayjs(item.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === item.deliveryTerm,
      ).description,
      rollContainerPosition: item.rollContainerPosition,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === item.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === item.packageType,
      ).name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === item.status,
      ).name,
    }));
  }

  const handleScanQR = (data) => {
    if (data) {
      setAssignPacket(data);
      setShowScanner(false);
      dispatchStatusPackage({
        type: actionsStatusPackage.HIDE_STATUS,
      });
    }
  };

  if (!paquersLoaded) {
    return <LoadingPaquers />;
  }
  return (
    <>
      <QRScanner
        showCamera={showScanner}
        setShowCamera={setShowScanner}
        onScan={handleScanQR}
      />
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handlePackageSelection={departurePacket}
        handleCloseModal={() => {
          setPackageModal(false);
          setPackageOptions([]);
          setAssignPacket('');
          if (externalCodeInput.current) {
            externalCodeInput.current.state.value = '';
          }
        }}
      />
      <Alert
        message="Las opciones de despacho con destino final en PaQuery Point y Nueva dirección estarán deshabilitadas temporalmente por mantenimiento."
        type="info"
        showIcon
        style={{ marginBottom: '15px' }}
      />
      <Card title="Despacho de paquetes">
        <Row
          className={classes.pageContainer}
          align="stretch"
          style={{ marginTop: -15 }}
          gutter={[16, 16]}
        >
          <Col xs={24} lg={6}>
            <div className={classes.sidebarContainer}>
              <div className={classes.selectPaquerContainer}>
                <span>Paquer:</span>
                <Select
                  showSearch
                  defaultValue={paquerSelected}
                  placeholder="Seleccione un paquer"
                  optionFilterProp="children"
                  onChange={(newPaquer) =>
                    dispatch(actions.updatePaquerId(newPaquer))
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optionsPaquers}
                </Select>
              </div>
              <div className={classes.registerPacketContainer}>
                <span className={classes.registerPacketSpan}>
                  <DownCircleOutlined />
                  Registrar paquete:
                </span>
                <Search
                  ref={externalCodeInput}
                  placeholder="Ingrese el codigo externo"
                  className={paquerSelected && classes.registerPacketInput}
                  onChange={(event) => {
                    setExternalCodeInputValue(event.target.value);
                    dispatchStatusPackage({
                      type: actionsStatusPackage.HIDE_STATUS,
                    });
                    setAssignPacket(event.target.value);
                  }}
                  disabled={!paquerSelected}
                  loading={fetching}
                  value={externalCodeInputValue}
                  autoFocus
                />
              </div>
              <div style={{ margin: '0.5rem 0px' }}>
                <Button
                  loading={fetching}
                  disabled={!paquerSelected}
                  style={{ width: '100%' }}
                  icon={<QrcodeOutlined />}
                  type="primary"
                  onClick={() => setShowScanner(true)}
                >
                  Escanear QR
                </Button>
              </div>
              <Row justify="center" className={classes.totalizersContainer}>
                <Col xs={12} style={{ color: 'deepskyblue' }}>
                  <div>
                    <CarryOutOutlined />
                  </div>
                  <span>Aceptados por el paquer:</span>
                  <span className={classes.totalizerCount}>
                    {totalizers.aceptedByPaquer}
                  </span>
                </Col>
                <Col xs={12} style={{ color: 'limegreen' }}>
                  <div>
                    <IdcardOutlined />
                  </div>
                  <span>En poder del paquer:</span>
                  <span className={classes.totalizerCount}>
                    {totalizers.ownedByPaquer}
                  </span>
                </Col>
                <Col xs={24} style={{ color: 'orange' }}>
                  <div>
                    <FieldTimeOutlined />
                  </div>
                  <span>En Intento de entrega</span>
                  <span className={classes.totalizerCount}>
                    {totalizers.visitAttempts}
                  </span>
                </Col>
              </Row>
              <EstimatedMoneyAmmount />
              <div className={classes.statusRegisterContainer}>
                <StatusPackage state={stateStatusPackage} />
              </div>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Row justify="end">
              <Col xs={24}>
                {!paquerSelected ? (
                  <PleaseSelectPaquer />
                ) : (
                  <div style={{ marginTop: '22px' }}>
                    <PaqueryTable
                      loading={!packageLoaded}
                      header={{
                        searcher,
                        onExportCsv: { callback: handleExportCsv, disabled },
                        refresh: () => {
                          dispatchStatusPackage({
                            type: actionsStatusPackage.HIDE_STATUS,
                          });
                          dispatch(actions.refreshPage());
                        },
                        primaryButton: (
                          <GenerateRouteButton paquerID={paquerSelected} />
                        ),
                      }}
                      content={PackageView}
                      usePackageRowColors
                      onChangePaginate={updatePaginate}
                      dataSource={filteredPackages}
                      paginate={pageable || DEFAULT_PAGINATE}
                      dataColumns={dataColumns}
                      colsForLargeDevice={columnsLargeDevice}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DeparturePacket;
