import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAuth from 'redux/auth';
import { authTokenSelector } from 'redux/auth/selectors';
import { actions } from 'redux/auth/slice';
import { loadKey } from 'util/localstorage';

export default function useAuthToken() {
  useAuth();
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(authTokenSelector);
  useEffect(() => {
    if (!token) {
      const tokenOnLocalStorage = loadKey('sessionToken');
      if (tokenOnLocalStorage) {
        dispatch(actions.rehydrate(tokenOnLocalStorage));
        dispatch(actions.fetchLoggedUser());
      }
    }
    setIsTokenChecked(true);
  }, [token, dispatch]);
  return isTokenChecked;
}
