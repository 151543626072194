import { PACKAGE } from './packages';

export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const IMAGE_REGEX = /data:(.*);base64,/;

export const PREFIX_REGEX =
  /(?:\+)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/;

export const DEFAULT_STATUS_FILTER_PACKAGE = ''; // Todos
export const DEFAULT_STATUS_FILTER_PACKAGE_WITH_PAQUERS =
  PACKAGE.STATUSES.INHANDSOFPAQUER;

export const DEFAULT_PACKAGE_TYPE = PACKAGE.TYPES.SEND;

export const ALL_STATUS_FILTERING = 'ALL_STATUS';
export const STATUS_PACKAGE_RESPONSE = {
  OK: 'OK',
  ERROR: 'ERROR',
};

export const DEFAULT_PAGINATE = {
  pageNumber: 1,
  pageSize: 10,
  totalElements: 0,
};

export const PACKET_VALUE_THRESHOLD =
  ~~process.env.REACT_APP_PACKET_VALUE_THRESHOLD;

export const DEPARTURE_PACKAGES_VALUE_THRESHOLD =
  ~~process.env.REACT_APP_DEPARTURE_PACKAGES_VALUE_THRESHOLD;

export default {};
