import { useEffect, useState } from 'react';

export default function useCheckDesktop() {
  const [isDesktop, setIsDesktop] = useState<boolean>(true);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 700);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isDesktop;
}
