import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import API from 'constants/api';
import { getData } from 'util/Api';
import { actions } from './slice';
import { paquersItemsSelector, paquersPaquerIdSelector } from './selectors';

function* fetchPackagesSaga() {
  try {
    yield put({ type: actions.fetchPackages });

    const driverID = yield select(paquersPaquerIdSelector);
    const searchParams = new URLSearchParams({
      search: '',
      page: 0,
      take: 10000,
      size: 10000,
      desc: false,
      driverID,
      searchRolled: '',
    });
    const response = yield call(
      getData,
      `${API.paquer.getPackages}${driverID}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.fetchPackagesSucess.type,
      payload: {
        items: response.content,
        total: response.totalElements,
      },
    });
  } catch (error) {
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      size: 10000,
      search: '',
    });
    const paquers = yield call(
      getData,
      `${API.paquer.getAll}?${searchParams.toString()}`,
    );
    yield put({ type: actions.success.type, payload: paquers.content });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(paquersItemsSelector);
  if (!items || items.length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(
      [actions.updatePaquerId, actions.refreshPage, actions.fetchPackages],
      fetchPackagesSaga,
    ),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
