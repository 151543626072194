/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { DEPARTURE_TYPE_OPTIONS } from 'constants/options';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat } from 'constants/dateFormats';
import store from 'redux/store';
import PaqueryTable from '@paquery-team/lib-table';
import dayjs from 'dayjs';
import externalCodeColumn from 'components/tables/columns';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Col,
  notification,
  Row,
  Select,
  Modal,
  Input,
  theme,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/packageCrossdockingDeparture/slice';
import { actions as ppointDepartureActions } from 'redux/packagePaquerypointDeparture/slice';
import { actions as refundDepartureActions } from 'redux/packageRefundDeparture/slice';
import { selectGlobals } from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import {
  paquerypointsItemsSelector,
  paquerypointsLoadedSelector,
} from 'redux/paquerypoint/selectors';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import usePaquerypoints from 'redux/paquerypoint';
import useMarketplaces from 'redux/marketplaces';
import {
  DownCircleOutlined,
  ExclamationCircleOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'util/Hooks';
import rest from 'util/Api';
import usePackageCrossdockingDeparture from 'redux/packageCrossdockingDeparture';
import usePackagePpointDeparture from 'redux/packagePaquerypointDeparture';
import usePackageRefundDeparture from 'redux/packageRefundDeparture';
import {
  crossdockingDepartureFilteredItemsSelector,
  crossdockingDepartureItemsSelector,
  crossdockingDepartureOperatorsSelector,
  crossdockingDepartureDestinySelector,
  crossdockingDepartureTypeSelector,
  crossdockingDepartureLoadedSelector,
} from 'redux/packageCrossdockingDeparture/selectors';
import {
  packagePaquerypointFilteredItemsSelector,
  packagePaquerypointDepartureItemsSelector,
} from 'redux/packagePaquerypointDeparture/selectors';
import {
  packageRefundFilteredItemsSelector,
  packageRefundDepartureItemsSelector,
} from 'redux/packageRefundDeparture/selectors';
import PackageSelectModal from 'components/packageSelectModal';
import QRScanner from 'components/QRScanner';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import DeleteRecordButton from 'components/deleteRecordButton';
import Card from 'components/card';

const { Search } = Input;

const WAIT_INPUT_EXTERNAL_CODE = 600;

const useStylesDeparturePacket = createUseStyles((_theme) => {
  return {
    pageTitle: {
      letterSpacing: '-0.03cm',
      alignSelf: 'flex-start',
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '2em',
      boxShadow: '0px 0px 2px #c3c3c3',
      margin: '15px 15px 0 15px',
    },
    headerButtonContainer: {
      textAlign: 'center',
      alignItems: 'flex-end',
    },
    registerPacketInput: {
      boxShadow: `0px 0px 3px ${_theme.colorPrimary}`,
      border: `1px solid ${_theme.colorPrimary}`,
      outline: '1px solid transparent',
      transition: '100ms all linear',
      '&:focus': {
        boxShadow: `0px 0px 3px ${_theme.colorPrimary}`,
        border: `1px solid ${_theme.colorPrimary}`,
        outline: `1px solid ${_theme.colorPrimary}`,
      },
    },
    inputContainer: {
      '@media (max-width: 768px)': {
        marginTop: '20px',
      },
    },
    buttonContainer: {
      '@media (min-width: 769px)': {
        padding: '0px 16px',
      },
    },
    destinyInput: {
      '@media (min-width: 769px)': {
        marginLeft: '30px',
      },
    },
    registerInput: {
      '@media (min-width: 1025px)': {
        marginRight: '30px',
      },
    },
    buttonText: {
      '@media (max-width: 1024px)': {
        fontSize: '13px',
      },
    },
  };
});

const { confirm } = Modal;

externalCodeColumn.width = 200;

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
  width: 130,
};

const packageTypeColumn = {
  title: 'Tipo',
  dataIndex: 'packageType',
};

const zoneColumn = {
  title: 'Zona',
  dataIndex: 'zone',
  align: 'center',
};

const scheduledDateColumn = {
  title: 'Fecha Programada',
  dataIndex: 'scheduledDate',
  align: 'center',
};

const bundlesColumn = {
  title: 'Bultos',
  dataIndex: 'numberPackages',
  align: 'center',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deleteAction = (packetID, packetCode) => {
  confirm({
    title: `¿Estás seguro de que deseas eliminar el paquete con código externo: ${packetCode} ?`,
    icon: <ExclamationCircleOutlined />,
    content: 'Esta accion no se puede deshacer',
    onOk() {
      store.dispatch(actions.removePacket(packetID));
    },
  });
};

const marketplaceColumn = {
  title: 'Marketplace',
  dataIndex: 'marketplace',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSize',
  align: 'center',
  render: (text) => <SizeIndicator text={text[0]} />,
  sorter: true,
  sortDirections: ['descend'],
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const removePackageColumn = {
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() => deleteAction(record.id, record.externalCode)}
    />
  ),
};

const dataColumns = [
  externalCodeColumn,
  deliveryTermColumn,
  scheduledDateColumn,
  zoneColumn,
  packageTypeColumn,
  marketplaceColumn,
  bundlesColumn,
  sizeColumn,
];

const columnsLargeDevice = [...dataColumns, editColumn, removePackageColumn];

const columnsSmallDevice = [
  externalCodeColumn,
  editColumn,
  removePackageColumn,
];

const columnsMediumDevice = [
  externalCodeColumn,
  scheduledDateColumn,
  arrivedAtPaqueryPointDateColumn,
  zoneColumn,
  marketplaceColumn,
  editColumn,
  removePackageColumn,
];

const DeparturePaqueryPointPacket = () => {
  usePackageCrossdockingDeparture();
  usePackagePpointDeparture();
  usePackageRefundDeparture();
  usePaquerypoints({ initialize: true });
  useMarketplaces({ initialize: true });
  const searcherInput = useRef(null);
  const externalCodeInput = useRef(null);
  const { token } = theme.useToken();
  const classes = useStylesDeparturePacket({ theme: token });
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const profile = useSelector(authProfile);
  const [destinyOptions, setDestinyOptions] = useState([]);
  const [packageModal, setPackageModal] = useState(false);
  const [packageSearch, setPackageSearch] = useState('');
  const [suggestedPackages, setSuggestedPackages] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const loaded = useSelector(crossdockingDepartureLoadedSelector);
  const departureType = useSelector(crossdockingDepartureTypeSelector);
  const destiny = useSelector(crossdockingDepartureDestinySelector);
  const paquerypoints = useSelector(paquerypointsItemsSelector);
  const paquerypointsLoaded = useSelector(paquerypointsLoadedSelector);
  const inlandDepartureOptions = useSelector(
    crossdockingDepartureOperatorsSelector,
  );
  const marketplaces = useSelector(marketplacesItemsSelector);
  const totalInlandPackages = useSelector(crossdockingDepartureItemsSelector);
  const totalPpointPackages = useSelector(
    packagePaquerypointDepartureItemsSelector,
  );
  const totalRefundPackages = useSelector(packageRefundDepartureItemsSelector);
  const totalPackages = [
    ...totalInlandPackages,
    ...totalPpointPackages,
    ...totalRefundPackages,
  ];
  const { items: inlandItems, pageable: inlandPageable } = useSelector(
    crossdockingDepartureFilteredItemsSelector,
  );
  const { items: ppointItems, pageable: ppointPageable } = useSelector(
    packagePaquerypointFilteredItemsSelector,
  );
  const { items: refundItems, pageable: refundPageable } = useSelector(
    packageRefundFilteredItemsSelector,
  );
  const debouncedPackageSearch = useDebounce(
    packageSearch,
    WAIT_INPUT_EXTERNAL_CODE,
  );
  const [loading, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  const getCurrentDepartureTypeOptions = () => {
    let destinationOptions;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        destinationOptions = inlandDepartureOptions.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        destinationOptions = paquerypoints.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        destinationOptions = marketplaces.map((destination) => ({
          label: destination.name,
          value: destination.id,
        }));
        break;
      default:
        break;
    }
    setDestinyOptions(destinationOptions);
  };

  const getPackage = async (externalCode) => {
    try {
      if (externalCode === null || externalCode === '') {
        const error = new Error('El codigo externo no puede ir vacío.');
        return error;
      }
      const searchParams = new URLSearchParams({
        page: 0,
        take: 1000,
        size: 1000,
        externalCode,
      });
      const packet = await rest.get(
        `${API.packages.findByExternalCodeDispatch}?${searchParams.toString()}`,
      );
      if (packet.data.content.length === 0) {
        throw new Error('El paquete con el codigo externo indicado no existe');
      }
      return packet.data.content;
    } catch (error) {
      setSuggestionsLoading(false);
      return notification.error({
        message: 'Ha ocurrido un error al encontrar el paquete',
        description: `No se ha encontrado el paquete con el código ${externalCode}`,
      });
    }
  };

  const checkPackageAvaiability = (packet) => {
    const packetInListing = totalPackages.find((item) => item.id === packet.id);
    if (packetInListing) {
      throw new Error(
        `El paquete ${packetInListing.externalCode} se encuentra asignado a: ${packetInListing.destinyName}`,
      );
    }
  };

  const assignPacket = ({ id }) => {
    try {
      const selectedPacket = suggestedPackages.find(
        (packet) => packet.id === id,
      );
      if (selectedPacket) {
        checkPackageAvaiability(selectedPacket);
        let selectedDestinyName;
        const packetWithDestiny = {
          ...selectedPacket,
          destinyID: destiny,
        };
        switch (departureType) {
          case DEPARTURE_TYPE_OPTIONS.INTERIOR:
            selectedDestinyName = inlandDepartureOptions.find(
              (option) => option.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(actions.addPackage(packetWithDestiny));
            break;
          case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
            selectedDestinyName = paquerypoints.find(
              (ppoint) => ppoint.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(ppointDepartureActions.addPackage(packetWithDestiny));
            break;
          case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
            selectedDestinyName = marketplaces.find(
              (marketplace) => marketplace.id === destiny,
            )?.name;
            packetWithDestiny.destinyName = selectedDestinyName;
            dispatch(refundDepartureActions.addPackage(packetWithDestiny));
            break;
          default:
            break;
        }
        notification.success({
          message: 'Asignación de paquete correcta',
          description: `El paquete ${selectedPacket.externalCode} fue asignado correctamente a ${selectedDestinyName}`,
        });
        setPackageModal(false);
        setPackageOptions([]);
      }
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un problema al asignar el paquete',
        description: error.message || 'Ha ocurrido un error en el servidor.',
      });
    } finally {
      setSuggestionsLoading(false);
    }
  };
  const buildListingPackage = (packet) => {
    return {
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      scheduledDate: packet.shippingScheduleDestination.scheduledDate
        ? dayjs(packet.shippingScheduleDestination.scheduledDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      zone: packet.shippingScheduleDestination.zone?.name ?? 'Sin Zona',
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      marketplace: marketplaces.find(
        (marketplace) => marketplace.id === packet.ownerID,
      )?.name,
      packageSize: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      packageType: globals.packages.type.find(
        (packType) => packType.value === packet.packageType,
      ).name,
    };
  };
  const getFilteredPackages = () => {
    let filteredPackages;
    if (inlandItems && ppointItems && refundItems) {
      switch (departureType) {
        case DEPARTURE_TYPE_OPTIONS.INTERIOR:
          filteredPackages = inlandItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
          filteredPackages = ppointItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
          filteredPackages = refundItems.map((packet) =>
            buildListingPackage(packet),
          );
          break;
        default:
          filteredPackages = [];
          break;
      }
    }
    return filteredPackages;
  };
  const filteredPackages = getFilteredPackages();

  useEffect(() => {
    dispatch(actions.initial());
    dispatch(ppointDepartureActions.initial());
    dispatch(refundDepartureActions.initial());
  }, [dispatch]);

  useEffect(() => {
    getCurrentDepartureTypeOptions();
    dispatch(actions.resetFilters());
    dispatch(ppointDepartureActions.resetFilters());
    dispatch(refundDepartureActions.resetFilters());
    if (searcherInput.current) {
      searcherInput.current.state.value = '';
    }
  }, [departureType, dispatch, paquerypointsLoaded]);

  useEffect(() => {
    const getPackageSuggestions = async (externalCode) => {
      let receivedExternalCode = externalCode;
      // Check if the typed extCode is a scanned QR
      try {
        const qrCode = JSON.parse(externalCode);
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // not QR and it's okay
      }
      const fetchedPackets = await getPackage(receivedExternalCode);
      if (!fetchedPackets) {
        return;
      }
      let suggestedPackageOptions = fetchedPackets;
      if (departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE) {
        suggestedPackageOptions = suggestedPackageOptions.filter(
          (packet) => packet.ownerID === destiny,
        );
      }
      if (suggestedPackageOptions.length === 0) {
        notification.info({
          title: 'Información',
          message: `No se ha encontrado el paquete con código externo: ${externalCode}`,
        });
        setSuggestionsLoading(false);
        setPackageOptions([]);
        return;
      }
      setSuggestedPackages(suggestedPackageOptions);
      if (suggestedPackageOptions.length > 1) {
        // If results are more than 1 packet, show modal and filter results by packages status allowed by ppoint.
        let filteredPackets = suggestedPackageOptions;
        if (departureType === DEPARTURE_TYPE_OPTIONS.INTERIOR) {
          filteredPackets = filteredPackets.filter(
            (packet) =>
              !totalInlandPackages.some((item) => item.id === packet.id),
          );
        }
        if (departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE) {
          filteredPackets = filteredPackets.filter(
            (packet) =>
              !totalRefundPackages.some((item) => item.id === packet.id),
          );
        }
        if (departureType === DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT) {
          filteredPackets = filteredPackets.filter(
            (packet) =>
              !totalPpointPackages.some((item) => item.id === packet.id),
          );
        }
        if (!filteredPackets.length) {
          notification.info({
            message: 'No se ha podido registrar el paquete',
            description:
              'Asegurese de que el paquete se encuentra en un estado permitido para despachar',
          });
          return;
        }
        suggestedPackageOptions = filteredPackets.map((packet) => {
          return {
            ...packet,
            marketplace: marketplaces.find((mkp) => mkp.id === packet.ownerID)
              ?.name,
            destinationAddress:
              packet.shippingScheduleDestination.shippingAddress.addressDetail,
            statusDescription: globals.packages.status.find(
              (state) => state.value === packet.status,
            ).name,
          };
        });
        if (suggestedPackageOptions.length > 1) {
          setPackageModal(true);
          setSuggestionsLoading(false);
          setPackageOptions(suggestedPackageOptions);
        } else {
          setSuggestedPackages(suggestedPackageOptions);
        }
      }
    };
    if (debouncedPackageSearch) {
      setPackageSearch('');
      setPackageOptions([]);
      setSuggestedPackages([]);
      setSuggestionsLoading(true);
      getPackageSuggestions(debouncedPackageSearch);
    }
    return undefined;
  }, [debouncedPackageSearch]);

  useEffect(() => {
    if (suggestedPackages.length === 1) {
      assignPacket(...suggestedPackages);
    }
  }, [suggestedPackages]);

  const handleExportRemit = () => {
    setDisabled(true);
    // TODO: hacer logica para exportar remito
    setDisabled(false);
  };

  const handleCloseModal = () => {
    setPackageModal(false);
    setPackageOptions([]);
  };

  const departurePackages = () => {
    const departurePackagesAction = async (currentRedux, payload) => {
      setLoading(true);
      try {
        const packageIds = payload.dispatchDetails.map(
          (items) => items.packageId,
        );
        const result = await rest.post(API.departures.create, payload);
        if (rest.isSuccessResponse(result)) {
          notification.success({
            message: 'Paquetes despachados correctamente',
            description: `Los paquetes han sido despachados correctamente.`,
          });
          dispatch(currentRedux.dispatchPackagesSuccess(packageIds));
        }
      } catch (error) {
        dispatch(currentRedux.failure());
      }
      setLoading(false);
    };
    let packages;
    let departureDestiny;
    let currentRedux;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        packages = totalInlandPackages;
        departureDestiny = inlandDepartureOptions;
        currentRedux = actions;
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        packages = totalPpointPackages;
        departureDestiny = paquerypoints;
        currentRedux = ppointDepartureActions;
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        packages = totalRefundPackages;
        departureDestiny = marketplaces;
        currentRedux = refundDepartureActions;
        break;
      default:
        break;
    }
    dispatch(currentRedux.dispatchPackages());
    const destination = departureDestiny.find((item) => item.id === destiny);
    const destinationPackages = packages.filter(
      (packet) => packet.destinyID === destiny,
    );
    const dispatchDetails = destinationPackages.map((packet) => {
      return {
        packageId: packet.id,
      };
    });
    const payload = {
      dispatchType: departureType,
      originDetail: 'Detalle Origen', // TODO: BORRAR HARDCODEO
      originType: departureType, // TODO: reemplazar por valor real
      originId: profile.ownerID,
      // el type del destino elegido?
      destinationType: departureType,
      // el id del destino elegido?
      destinationId: destination.id,
      destinationDetail: destination.detail,
      dispatchDetails,
    };
    departurePackagesAction(currentRedux, payload);
  };

  const handleDeparture = async () => {
    if (!destiny) {
      notification.info({
        message: 'No hay paquetes para generar despacho',
        description: `Asegúrese de asignar paquetes al destino antes de despachar`,
      });
      return;
    }
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        if (inlandItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        if (ppointItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        if (refundItems.length < 1) {
          notification.info({
            message: 'No hay paquetes para generar despacho',
            description: `Asegúrese de asignar paquetes al destino antes de despachar`,
          });
          return;
        }
        break;
      default:
        break;
    }
    departurePackages();
  };

  // TODO: limpiar input y hacerle foco para que escaneen uno atrás del otro.

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch, departureType],
    // TODO: quitar departuretype de acá porque actualiza search cuando cambio de departuretype
  );
  const searcher = {
    key: 'searchCodeOrDestinationName',
    onSearching: searchCallback,
    placeholder: 'Nombre',
    ref: searcherInput,
  };
  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
      dispatch(ppointDepartureActions.updatePageable(page));
      dispatch(refundDepartureActions.updatePageable(page));
    },
    [dispatch],
  );

  const getPagination = () => {
    let pagination;
    switch (departureType) {
      case DEPARTURE_TYPE_OPTIONS.INTERIOR:
        pagination = inlandPageable;
        break;
      case DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT:
        pagination = ppointPageable;
        break;
      case DEPARTURE_TYPE_OPTIONS.MARKETPLACE:
        pagination = refundPageable;
        break;
      default:
        break;
    }
    return pagination;
  };

  const currentPageable = getPagination();

  const handleScanQR = (data) => {
    if (data) {
      setPackageSearch(data);
      setShowScanner(false);
    }
  };

  return (
    <>
      <QRScanner
        onScan={handleScanQR}
        showCamera={showScanner}
        setShowCamera={setShowScanner}
      />
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handleCloseModal={handleCloseModal}
        handlePackageSelection={assignPacket}
      />
      <Card
        title="Transferir o devolver paquetes"
        style={{ marginBottom: '10px' }}
      >
        <Row
          align="middle"
          className={classes.headerButtonContainer}
          justify="space-between"
        >
          <Col xs={24} md={24} lg={8} xl={8}>
            <Row
              justify="space-between"
              gutter={[16, 16]}
              className={classes.buttonContainer}
            >
              {/* Consultar si debe estar la opcion INTERIOR */}
              {/* <Col md={8} lg={7} xl={6} xxl={6}>
              <Button
                className={
                  departureType === DEPARTURE_TYPE_OPTIONS.INTERIOR
                    ? classes.buttonActive
                    : ''
                }
                type="primary"
                onClick={() =>
                  dispatch(actions.updateDepartureType(DEPARTURE_TYPE_OPTIONS.INTERIOR))
                }
                style={{ width: '100%' }}
              >
                <span className={classes.buttonText}>Interior</span>
              </Button>
            </Col> */}
              <Col xs={24} md={12} lg={12} xl={12} xxl={11}>
                <Button
                  className={
                    departureType === DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT
                      ? classes.buttonActive
                      : ''
                  }
                  type="primary"
                  onClick={() =>
                    dispatch(
                      actions.updateDepartureType(
                        DEPARTURE_TYPE_OPTIONS.PAQUERYPOINT,
                      ),
                    )
                  }
                  style={{ width: '100%' }}
                  loading={!paquerypointsLoaded}
                >
                  <span className={classes.buttonText}>PaQuery Point</span>
                </Button>
              </Col>
              <Col xs={24} md={12} lg={12} xl={12} xxl={11}>
                <Button
                  className={
                    departureType === DEPARTURE_TYPE_OPTIONS.MARKETPLACE
                      ? classes.buttonActive
                      : ''
                  }
                  type="primary"
                  onClick={() =>
                    dispatch(
                      actions.updateDepartureType(
                        DEPARTURE_TYPE_OPTIONS.MARKETPLACE,
                      ),
                    )
                  }
                  style={{ width: '100%' }}
                  disabled
                >
                  <span className={classes.buttonText}>Devoluciones</span>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={15} xl={14}>
            <Row
              gutter={[16, 16]}
              align="bottom"
              justify="space-around"
              className={classes.inputContainer}
            >
              <Col xs={24} lg={9}>
                <span>Destino:</span>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  value={destiny}
                  placeholder="Seleccione un destino"
                  onChange={(newDestiny) => {
                    dispatch(actions.updateDestiny(newDestiny));
                    dispatch(ppointDepartureActions.resetFilters());
                    dispatch(refundDepartureActions.resetFilters());
                  }}
                  filterOption={(input, option) =>
                    option.label.toUpperCase().includes(input.toUpperCase())
                  }
                  options={destinyOptions}
                />
              </Col>
              <Col xs={24} lg={9}>
                <div>
                  <DownCircleOutlined />
                  Registrar paquete:
                </div>
                <Search
                  ref={externalCodeInput}
                  value={packageSearch}
                  disabled={!destiny}
                  onChange={(e) => setPackageSearch(e.target.value.trim())}
                  loading={suggestionsLoading}
                />
              </Col>
              <Col xs={24} lg={5}>
                <Button
                  disabled={!destiny}
                  style={{ width: '100%' }}
                  icon={<QrcodeOutlined />}
                  type="primary"
                  onClick={() => setShowScanner(true)}
                  loading={suggestionsLoading}
                >
                  Scan QR
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row
        className={classes.pageContainer}
        align="stretch"
        style={{ marginTop: -15 }}
      >
        <Col
          xs={24}
          className={classes.tablePacketContainer}
          style={{ marginTop: 15 }}
        >
          <PaqueryTable
            loading={!loaded}
            header={{
              searcher,
              onExportCsv: { callback: handleExportRemit, disabled },
            }}
            onChangePaginate={updatePaginate}
            dataSource={filteredPackages}
            paginate={currentPageable || DEFAULT_PAGINATE}
            dataColumns={dataColumns}
            colsForSmallDevice={columnsSmallDevice}
            colsForMediumDevice={columnsMediumDevice}
            colsForLargeDevice={columnsLargeDevice}
          />
        </Col>
      </Row>
      <Row align="middle" style={{ textAlign: 'center', margin: '10px 0px' }}>
        <Col xs={24}>
          <Button
            type="primary"
            size="large"
            onClick={handleDeparture}
            loading={loading}
          >
            Transferir
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DeparturePaqueryPointPacket;
