import React, { useCallback } from 'react';
import { Input, Tooltip, Form, Button, theme } from 'antd';
import { useFormikContext, getIn } from 'formik';
import { UndoOutlined } from '@ant-design/icons';
import useStyles from './styles';

interface Props {
  label: string;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  fieldName: string;
  type?: string;
  min?: number;
  max?: number;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: string) => void;
  prefix?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputField = ({
  label,
  labelStyle,
  containerStyle,
  fieldName,
  type,
  min,
  max,
  onBlur,
  prefix,
  disabled = false,
  placeholder,
  onChange,
}: Props) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const { values, initialValues, errors, setFieldValue, setFieldTouched } =
    useFormikContext<any>();
  const value = getIn(values, fieldName);
  const [input, setInput] = React.useState(value);
  const error = getIn(errors, fieldName);
  const initialValue = getIn(initialValues, fieldName);
  const hasChanged = value && value?.toString() !== initialValue?.toString();

  React.useEffect(() => {
    setInput(value);
  }, [value]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, e.target.value);
      if (onBlur) {
        onBlur(e.target.value);
      }
    },
    [fieldName, onBlur, setFieldTouched, setFieldValue],
  );
  const handleRestart = useCallback(() => {
    setFieldValue(fieldName, initialValue);
    setFieldTouched(fieldName, false);
  }, [fieldName, initialValue, setFieldValue, setFieldTouched]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, setInput],
  );

  const getValidateStatus = () => {
    let validateStatus:
      | ''
      | 'error'
      | 'validating'
      | 'warning'
      | 'success'
      | undefined = '';
    if (hasChanged) {
      validateStatus = 'warning';
    }
    if (error) {
      validateStatus = 'error';
    }
    return validateStatus;
  };

  const validateStatus = getValidateStatus();

  const AddonBefore = React.memo(() => {
    return (
      <Tooltip title="Deshacer">
        <Button onClick={() => handleRestart()} size="small" type="text">
          <UndoOutlined />
        </Button>
      </Tooltip>
    );
  });

  return (
    <div style={{ textAlign: 'left', ...containerStyle }}>
      <span style={labelStyle}>{label}</span>
      <div className={classes.topSelectEffect}>
        <Form.Item validateStatus={validateStatus} help={error}>
          <Input
            value={input}
            defaultValue={value}
            onChange={handleChange}
            type={type}
            name={fieldName}
            prefix={prefix}
            onBlur={handleBlur}
            min={min}
            max={max}
            style={{
              width: '100%',
            }}
            placeholder={placeholder || label}
            disabled={disabled}
            addonAfter={hasChanged ? <AddonBefore /> : null}
          />
        </Form.Item>
      </div>
    </div>
  );
};

InputField.defaultProps = {
  labelStyle: {},
  containerStyle: {},
  type: 'text',
  min: null,
  max: null,
  onBlur: null,
  prefix: null,
  disabled: false,
  placeholder: null,
  onChange: undefined,
};

export default React.memo(InputField);
