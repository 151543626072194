/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo } from 'react';
import { Col, Row, Card, Flex, Button, Select, Skeleton, Image } from 'antd';
import {
  CloseCircleFilled,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import usePaquers from 'redux/paquerList';
import { paquersItemsSelector } from 'redux/paquerList/selectors';
import { actions } from 'redux/paquerList/slice';
import PaquerService from 'services/PaquerService';

interface DriverProps {
  driver: any;
  setDriver: (args: any) => void;
  disabled?: boolean;
  handleAssign?: () => void;
}

const Driver = ({ driver, setDriver, disabled, handleAssign }: DriverProps) => {
  usePaquers();
  const dispatch = useDispatch();
  const drivers = useSelector(paquersItemsSelector);

  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);

  const driverOptions = useMemo(
    () =>
      drivers
        ?.filter((d) => d.active)
        .map((d) => ({
          label: `${d.name} ${d.lastName}`,
          value: d.id,
        })) ?? [],
    [drivers],
  );

  const handleSelectDriver = async (value: string | number) => {
    const newDriver = await PaquerService.geyById(value);
    setDriver({
      ...newDriver,
      fullname: `${newDriver.name} ${newDriver.lastName}`,
    });
  };

  function addDefaultSrc(ev: any) {
    ev.target.src = '/driver-placeholder.png';
  }

  let driverAvatar;
  if (driver) {
    if (driver.avatar) {
      driverAvatar = (
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="avatar"
          src={driver.avatar}
          onError={addDefaultSrc}
        />
      );
    } else {
      driverAvatar = (
        <Flex
          justify="center"
          align="center"
          style={{
            height: 210,
            width: '100%',
            background: 'lightgrey',
          }}
        >
          <UserOutlined
            style={{
              margin: 'auto',
              textAlign: 'center',
              color: 'white',
              fontSize: 100,
            }}
          />
        </Flex>
      );
    }
  } else if (handleAssign) {
    driverAvatar = (
      <Flex
        justify="center"
        align="center"
        style={{
          height: 210,
          width: '100%',
          background: 'lightgrey',
        }}
      >
        <p
          style={{
            margin: 'auto',
            textAlign: 'center',
            color: 'white',
            fontSize: 24,
            fontStyle: 'bold',
          }}
        >
          La ruta
          <br />
          no tiene driver <br /> asignado
        </p>
      </Flex>
    );
  } else {
    driverAvatar = (
      <Flex
        justify="center"
        align="center"
        style={{
          height: 210,
          width: '100%',
          background: 'lightgrey',
        }}
      >
        <p
          style={{
            margin: 'auto',
            textAlign: 'center',
            color: 'white',
            fontSize: 24,
            fontStyle: 'bold',
          }}
        >
          La ruta
          <br />
          se generará <br /> sin driver
        </p>
      </Flex>
    );
  }

  return (
    <Card
      style={{
        height: '100%',
        borderStyle: driver ? 'solid' : 'dashed',
        overflow: 'hidden',
      }}
      bodyStyle={{
        padding: 13,
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <div
            style={{
              borderRadius: '8px',
              overflow: 'hidden',
              height: 210,
            }}
          >
            {driverAvatar}
          </div>
        </Col>
        <Col span={16}>
          <Flex
            vertical
            justify="space-between"
            align="center"
            style={{ height: '100%', width: '100%', padding: '10px 0' }}
          >
            <Select
              size="large"
              placeholder="Seleccioná un driver"
              style={{ width: '100%' }}
              options={driverOptions}
              allowClear
              clearIcon={
                <CloseCircleFilled style={{ color: 'red', fontSize: 14 }} />
              }
              onSelect={handleSelectDriver}
              onClear={() => setDriver(null)}
              value={driver && driver.id ? driver.id : undefined}
              disabled={disabled}
            />
            <Flex vertical justify="space-between" style={{ width: '100%' }}>
              {driver ? (
                <>
                  <Flex
                    justify="start"
                    style={{ width: '100%', fontStyle: 'bold', fontSize: 28 }}
                  >
                    <p>{driver.fullname}</p>
                  </Flex>
                  <Flex justify="start" style={{ width: '100%', fontSize: 22 }}>
                    <p>{driver.email}</p>
                  </Flex>
                </>
              ) : (
                <Skeleton paragraph={{ rows: 1 }} />
              )}
              <Flex
                justify="end"
                gap={16}
                style={{ width: '100%', marginTop: '20px' }}
              >
                <Button
                  type="text"
                  style={{ color: driver ? 'green' : 'lightgrey' }}
                  icon={<WhatsAppOutlined />}
                  disabled={!driver}
                  href={
                    driver
                      ? `https://web.whatsapp.com/send?phone=${driver._dialingCode}${driver.mobile}`
                      : ''
                  }
                  target="_blank"
                >
                  Contactar
                </Button>
                {handleAssign && (
                  <Button disabled={disabled} onClick={handleAssign}>
                    Asignar
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

Driver.defaultProps = {
  disabled: false,
  handleAssign: null,
};

export default Driver;
